import { useBoolean } from '@fluentui/react-hooks'
import { Row } from '@tanstack/react-table'
import { IndeterminateProgressIndicator } from 'modules/Advisory/modules/Rdot360/components/shared'
import { SnackBar } from 'modules/Advisory/modules/Rdot360/components/shared/Snackbar'
import { Icon } from 'modules/Advisory/modules/Rdot360/features/Icons/Icon'
import { useCallback, useMemo } from 'react'
import { DateRange } from '../../features/Header/DateRange'
import { DeleteSelected } from '../../features/Header/DeleteSelected'
import { FolderPivot } from '../../features/Header/FolderPivot'
import { MessageSearchBox } from '../../features/Header/MessageSearchBox'
import { RestoreButton } from '../../features/Header/RestoreButton'
import { SecureMessagesHeader } from '../../features/Header/SecureMessagesHeader'
import { DeleteModal } from '../../features/Modal/DeleteModal'
import { SecureMessagesTable } from '../../features/Table/SecureMessagesTable'
import { SecureMessagesTableWrapper } from '../../features/Table/SecureMessagesTableWrapper'
import { ErrorMessage } from '../../shared/components'
import {
  useSecureMessagesList,
  useSecureMessagesState
} from '../../shared/hooks'
import { styles } from '../../shared/styles'
import { IGraphMessage, SecureMessageFolder } from '../../shared/types'
import { getDeletedColumns } from './DeletedColumns'
import { getDraftsColumns } from './DraftsColumns'
import { getInboxColumns } from './InboxColumns'
import { getSentColumns } from './SentColumns'

const empty: IGraphMessage[] = []

export const MessagesContainer: React.FC = () => {
  const { currentView, searchText, setCurrentView, setSelectedMessage } =
    useSecureMessagesState()

  const {
    clientMessages,
    error,
    isLoading,
    deleteSelectedMessages,
    moveSelectedMessages,
    refetchMessages
  } = useSecureMessagesList(currentView as SecureMessageFolder)

  const [
    isDeleteModalOpen,
    { setTrue: showDeleteModal, setFalse: hideDeleteModal }
  ] = useBoolean(false)

  const deleteSelected = useCallback(async () => {
    hideDeleteModal()
    deleteSelectedMessages()
  }, [deleteSelectedMessages, hideDeleteModal])

  const handleDeleteSelectedClick = useCallback(() => {
    if (currentView === 'deleted' || currentView === 'drafts') {
      showDeleteModal()
    } else {
      moveSelectedMessages('deleted')
    }
  }, [currentView, moveSelectedMessages, showDeleteModal])

  const handleRestoreSelectedClick = useCallback(
    (folder: SecureMessageFolder) => moveSelectedMessages(folder),
    [moveSelectedMessages]
  )

  const handleSubjectClick = useCallback(
    (message: IGraphMessage) => {
      setSelectedMessage(message)
      setCurrentView('read')
    },
    [setCurrentView, setSelectedMessage]
  )

  const RightHeader = useCallback(() => {
    return (
      <>
        {currentView !== 'drafts' && <DateRange />}
        {currentView === 'inbox' && <MessageSearchBox />}
        {currentView === 'deleted' && (
          <RestoreButton
            disabled={isLoading}
            onClick={handleRestoreSelectedClick}
          />
        )}
        <DeleteSelected
          disabled={isLoading}
          onClick={handleDeleteSelectedClick}
        />
        <div style={styles.buttonContainer}>
          <Icon
            onClick={refetchMessages}
            title="Refresh messages"
            type="Refresh"
            width={24}
            height={24}
            color="#005CB8"
          />
        </div>
      </>
    )
  }, [
    currentView,
    handleDeleteSelectedClick,
    handleRestoreSelectedClick,
    isLoading,
    refetchMessages
  ])

  const columns = useMemo(() => {
    switch (currentView) {
      case 'deleted':
        return getDeletedColumns(handleSubjectClick)
      case 'drafts':
        return getDraftsColumns(handleSubjectClick)
      case 'inbox':
        return getInboxColumns(handleSubjectClick)
      case 'sent':
        return getSentColumns(handleSubjectClick)
      default:
        return []
    }
  }, [currentView, handleSubjectClick])

  const globalFilter = useCallback(
    (row: Row<IGraphMessage>, _columnId: string, filterValue: string) => {
      if (currentView !== 'inbox') {
        return true
      }

      const lowerCaseFilterValue = filterValue.toLowerCase()
      const lowerCaseFrom = row.original.from?.emailAddress.name?.toLowerCase()
      const lowerCaseSubject = row.original.subject?.toLowerCase()

      if (!lowerCaseFrom || !lowerCaseSubject) {
        return false
      }

      return (
        lowerCaseFrom.includes(lowerCaseFilterValue) ||
        lowerCaseSubject.includes(lowerCaseFilterValue)
      )
    },
    [currentView]
  )

  return (
    <>
      <SecureMessagesHeader left={<FolderPivot />} right={<RightHeader />} />
      <SecureMessagesTableWrapper>
        <ErrorMessage error={error} />
        {isLoading && <IndeterminateProgressIndicator />}
        <SecureMessagesTable
          columns={columns}
          data={clientMessages ?? empty}
          getRowCss={(r) => (r.original.isRead ? undefined : styles.unread)}
          getRowId={(r, i) => r.id ?? i.toString()}
          globalFilter={searchText}
          globalFilterFn={globalFilter}
        />
        {!error &&
          !isLoading &&
          (!clientMessages || clientMessages?.length === 0) && (
            <div style={{ marginTop: 5 }}>
              <SnackBar type="Info" message="No messages available" />
            </div>
          )}
      </SecureMessagesTableWrapper>
      <DeleteModal
        isModalOpen={isDeleteModalOpen}
        onCancel={hideDeleteModal}
        onDelete={deleteSelected}
      />
    </>
  )
}
