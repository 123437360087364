import { IAccount } from 'api/account.types'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { accountContextUpdateActions } from 'store/context/account'
import { RdotUserRoleEnum } from 'store/user/rdotUser'
import { getRdotUserRoles } from 'store/user/selectors'
import { usePilotFeatures } from './usePilotFeatures'

export type TransferType = 'check' | 'eft' | 'journal' | 'wire'

export const usePaymentHub = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isInPilot } = usePilotFeatures()
  const userRoles = useSelector(getRdotUserRoles)

  const showCreate = useMemo(() => {
    return !!(
      userRoles?.includes(RdotUserRoleEnum.Advisory_FinancialAdvisor) ||
      userRoles?.includes(RdotUserRoleEnum.Advisory_ClientAssociate) ||
      userRoles?.includes(RdotUserRoleEnum.Operations_Cash) ||
      userRoles?.includes(RdotUserRoleEnum.Payments_Creator) ||
      userRoles?.includes(RdotUserRoleEnum.Payments_Approver_L1) ||
      userRoles?.includes(RdotUserRoleEnum.Payments_Approver_L2)
    )
  }, [userRoles])

  const showChecks = useMemo(() => isInPilot('CHECKS'), [isInPilot])
  const showEFT = useMemo(() => isInPilot('TRANSFERSEFT'), [isInPilot])
  const showJNL = useMemo(() => isInPilot('TRANSFERSJNL'), [isInPilot])
  const showRecurring = useMemo(
    () => isInPilot('TRANSFERSRECURRING'),
    [isInPilot]
  )

  const navigateToTransfers = useCallback(
    (account: IAccount, type: TransferType) => {
      dispatch(accountContextUpdateActions.success([account]))
      navigate(`/transfers/${type}/new`, {
        state: { useAcctContext: true }
      })
    },
    [dispatch, navigate]
  )

  return {
    showCreate,
    showChecks,
    showEFT,
    showJNL,
    showRecurring,
    navigateToTransfers
  }
}
