import { Text } from '@fluentui/react'
import { IHurdle } from 'api/datahub'
import React from 'react'
import { createEntitySelector } from './EntitySelector'
import { useHurdleSearchFetch } from './store/hurdleSearchFetch'

const HurdleAutoCompleteItem: React.FC<{
  item?: IHurdle
}> = ({ item }) => {
  return (
    <div style={{ padding: '6px 10px', minWidth: 0 }}>
      <Text>{item?.name}</Text> - <Text>{item?.entityName}</Text>
    </div>
  )
}

export const HurdleSelector = createEntitySelector(
  useHurdleSearchFetch,
  HurdleAutoCompleteItem
)
