import { createColumnHelper } from '@tanstack/react-table'
import { USDCell } from 'modules/Advisory/modules/Rdot360/features/WidgetTable/WidgetTable'
import { IBalanceDetailResponseValueItem } from 'modules/Advisory/modules/Rdot360/store/balancesApi/IBalanceDetailResponse'
import {
  textAlignLeft,
  textAlignRight
} from '../../../../features/Loans/styles'
import {
  AccountCellExtended,
  USDCellTotalAmount
} from '../../../../features/Loans/TableCellsGeneric'

const columnHelper = createColumnHelper<IBalanceDetailResponseValueItem>()

export const getColumnDefinition = () => [
  columnHelper.accessor(({ key }) => key, {
    id: 'accountKey',
    cell: AccountCellExtended,
    header: () => <div css={textAlignLeft}>Account</div>,
    footer: () => <>Total</>,
    size: 60
  }),
  columnHelper.accessor(({ totalaccountvalue }) => totalaccountvalue, {
    id: 'loanBalance',
    cell: ({ getValue }) => <USDCell value={getValue<number>()} />,
    header: () => (
      <div css={textAlignRight}>
        Loan
        <br />
        Balance
      </div>
    ),
    footer: (props) => (
      <div css={textAlignRight}>
        <USDCellTotalAmount {...props} fractionDigits={0} inColor />
      </div>
    ),
    size: 60
  }),
  columnHelper.accessor(({ marginaccruedinterest }) => marginaccruedinterest, {
    id: 'marginAccruedInterest',
    cell: ({ getValue }) => <USDCell value={getValue<number>()} />,
    header: () => (
      <div css={textAlignRight}>
        Accrued
        <br />
        Interest
      </div>
    ),
    size: 60
  }),
  columnHelper.accessor(
    ({ cashmarginavailabletowithdraw }) => cashmarginavailabletowithdraw,
    {
      id: 'availableToBorrow',
      cell: ({ getValue }) => <USDCell value={getValue<number>()} />,
      header: () => (
        <div css={textAlignRight}>
          Available to
          <br />
          Borrow &amp; Withdraw
        </div>
      ),
      footer: (props) => (
        <div css={textAlignRight}>
          <USDCellTotalAmount {...props} fractionDigits={0} />
        </div>
      ),
      size: 60
    }
  ),
  columnHelper.accessor(({ minimumequitycall }) => minimumequitycall, {
    id: 'callAmount',
    cell: ({ getValue }) => <USDCell value={getValue<number>()} />,
    header: () => (
      <div css={textAlignRight}>
        Call
        <br />
        Amount
      </div>
    ),
    footer: (props) => (
      <div css={textAlignRight}>
        <USDCellTotalAmount {...props} fractionDigits={0} />
      </div>
    ),
    size: 60
  })
]
