import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ExpandedState, SortingState, Updater } from '@tanstack/react-table'
import { flow, isFunction } from 'lodash'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'
import { rdot360ContextActions } from '../../../store/rdot360Context'

const { actions, reducer } = createSlice({
  name: 'marginLoansUiState',
  initialState: {
    searchText: '',
    expanded: {} as ExpandedState,
    sorting: [] as SortingState
  },
  reducers: {
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload
    },
    setExpanded: (state, action: PayloadAction<ExpandedState | undefined>) => {
      state.expanded = action.payload || {}
    },
    setSorting: (state, action: PayloadAction<SortingState>) => {
      state.sorting = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(rdot360ContextActions.setSelectedHouseholdId, (state) => {
      state.searchText = ''
    })
  }
})

export { reducer as rdot360MarginLoansReducer }

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.rdot360.modules.loans.marginLoansUiState

const getSearchText = flow(rootSelector, (x) => x.searchText)
const getExpanded = flow(rootSelector, (x) => x.expanded)
const getSorting = flow(rootSelector, (x) => x.sorting)

export const useMarginLoansUiState = () => {
  const dispatch = useDispatch()
  const searchText = useSelector(getSearchText)
  const expanded = useSelector(getExpanded)
  const sorting = useSelector(getSorting)

  const setSearchText = useCallback(
    (search?: string) => {
      dispatch(actions.setSearchText(search || ''))
    },
    [dispatch]
  )

  const setExpanded = useCallback(
    (updater: Updater<ExpandedState>) => {
      const value = isFunction(updater) ? updater(expanded || {}) : updater
      dispatch(actions.setExpanded(value))
    },
    [dispatch, expanded]
  )

  const setSorting = useCallback(
    (updater: Updater<SortingState>) => {
      const value = isFunction(updater) ? updater(sorting ?? []) : updater
      dispatch(actions.setSorting(value))
    },
    [dispatch, sorting]
  )

  return {
    searchText,
    setSearchText,
    expanded,
    setExpanded,
    sorting,
    setSorting
  }
}
