import { useMemo } from 'react'
import { isNotNullOrUndefined } from 'shared/guards'
import { useGetEmoneyAccountConnectionsQuery } from '../../../store/assetagApi'
import { useRdot360AccountContext } from '../../../store/rdot360Context'

export interface IEmoneyAccountConnection {
  accountKeys?: string[]
  id?: string
  sourceId: string
  status: string
  statusDescription: string
  statusLevel: string
  lastSuccessfulUpdate: string
  lastUpdateAttempt: string
  name: string
  permissions: string
  ownerUserId: string
}

export const useGetEmoneyAccountConnections = () => {
  const { accounts } = useRdot360AccountContext()

  const externalAccountKeys = useMemo(
    () =>
      accounts
        ?.map((x) =>
          x.CustodianType === 'External' ? x?.accountkey : undefined
        )
        .filter(isNotNullOrUndefined) || [],
    [accounts]
  )

  const { data: emoneyAccountConnections } =
    useGetEmoneyAccountConnectionsQuery({
      externalAccountKeys
    })

  return emoneyAccountConnections || []
}
