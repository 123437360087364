import { css } from '@emotion/react'
import { memo, useCallback, useMemo } from 'react'
import { USD } from 'shared/components/Formatting'
import { TodaysChange } from '../../components/Balances/TodaysChange'
import { detailTable } from '../../components/shared/DataTable/DetailTable'
import { AccountNumberCell } from '../../components/shared/DetailTables/AccountNumberCell'
import { IBalanceTodaysChange } from '../../store/balancesApi/IBalanceDetailResponse'
import { useRdot360AccountContext } from '../../store/rdot360Context'
import { useAccountSelectorTableStore } from './store'
import { accountSelectorStyles } from './styles'

const styles = {
  valueContainer: css([
    accountSelectorStyles.flexColumn,
    {
      flexGrow: 1,
      '& > div': { marginLeft: 'auto' },
      paddingLeft: 5
    }
  ]),
  noValue: css({ minWidth: 16, textAlign: 'right' }),
  todaysChangeContainer: css([
    accountSelectorStyles.itemBalanceChange,
    { display: 'flex' }
  ])
}

export const AccountSelectorAccount: React.FC<{
  checked?: boolean
  onChange: () => void
  accountNumber?: string
  balance?: number
  change?: number
  todaysChange?: IBalanceTodaysChange
}> = memo(
  ({ checked, onChange, accountNumber, balance, change, todaysChange }) => {
    const { accountLookupByAccountIdOrKey, setSelectedAccountIds } =
      useRdot360AccountContext()
    const account = useMemo(
      () =>
        accountNumber ? accountLookupByAccountIdOrKey[accountNumber] || {} : {},
      [accountLookupByAccountIdOrKey, accountNumber]
    )
    const { searchText } = useAccountSelectorTableStore()

    const accountId = useMemo(() => account?.accountId || '', [account])

    const onAccountNumberClicked = useCallback(() => {
      setSelectedAccountIds([accountId])
    }, [accountId, setSelectedAccountIds])
    return (
      <div css={[accountSelectorStyles.flexRow]}>
        <div style={{ marginLeft: 2, marginRight: 8 }}>
          <input
            type="checkbox"
            css={[accountSelectorStyles.checkbox]}
            checked={checked}
            onChange={onChange}
          />
        </div>
        <div
          css={[
            accountSelectorStyles.flexColumn,
            accountSelectorStyles.itemTitle,
            detailTable.ellipsis
          ]}
        >
          <AccountNumberCell
            accountIdOrKey={accountId}
            search={searchText}
            fallbackValue={accountId}
            wrapNickname={true}
            onAccountNumberClick={onAccountNumberClicked}
            displayFirmName={true}
          />
        </div>
        <div css={styles.valueContainer}>
          <div css={[accountSelectorStyles.itemBalance]}>
            {balance != null ? (
              <USD value={balance} fractionDigits={0} currencySign="standard" />
            ) : (
              <div css={styles.noValue}>--</div>
            )}
          </div>
          <div css={styles.todaysChangeContainer}>
            <TodaysChange
              change={change}
              changeDetails={todaysChange?.RCM}
              accountNumber={accountNumber}
            />
          </div>
        </div>
      </div>
    )
  }
)
