import { tokenizeQuery } from 'api/search'
import { escapeRegExp } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { Masked, MaskedText } from '../../../shared/components/MaskedText'
import { getEnableDataMaskingPreference } from '../../../store/user/selectors'

export interface IHighlightProps {
  text?: string
  highlight?: string
}

export const Highlight: React.FC<IHighlightProps> = ({
  text = '',
  highlight = ''
}) => {
  if (!highlight.trim()) {
    return <span>{text}</span>
  }

  const regex = new RegExp(
    `(${tokenizeQuery(highlight).map(escapeRegExp).join('|')})`,
    'i'
  )
  const parts = (text || '').split(regex)
  return (
    <span>
      {parts
        .filter((part) => part)
        .map((part, i) =>
          regex.test(part) ? (
            <mark className="highlight" style={{ fontWeight: 700 }} key={i}>
              {part}
            </mark>
          ) : (
            <span key={i}>{part}</span>
          )
        )}
    </span>
  )
}

export const ConnectedMaskedHighlight: React.FC<IHighlightProps> = (props) => {
  const shouldMask = useSelector(getEnableDataMaskingPreference)
  return shouldMask ? (
    <MaskedText text={props.text} />
  ) : (
    <Highlight {...props} />
  )
}

export const ConnectedMaskedHighlightPlain: React.FC<IHighlightProps> = (
  props
) => {
  const shouldMask = useSelector(getEnableDataMaskingPreference)
  return shouldMask ? <Masked text={props.text} /> : <Highlight {...props} />
}
