import { css } from '@emotion/react'

export const styles = {
  buttonContainer: {
    alignItems: 'center',
    display: 'flex',
    height: '34px'
  },
  icon: {
    color: '#005CB8',
    fontSize: '24px'
  },
  unread: css({
    '& .cell': {
      fontWeight: 'bold'
    }
  })
}
