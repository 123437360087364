import { createColumnHelper } from '@tanstack/react-table'
import { IBalanceDetailResponseValueItem } from 'modules/Advisory/modules/Rdot360/store/balancesApi/IBalanceDetailResponse'
import { textAlignLeft } from '../../../features/Loans/styles'
import {
  ColumnHeaderWithSortIndicator,
  DisplayCell,
  PercentageCell,
  USDCellTotalAmount,
  USDCell,
  AccountCellExtended,
  MarginTypeCell
} from '../../../features/Loans/TableCellsGeneric'

const columnHelper = createColumnHelper<IBalanceDetailResponseValueItem>()

export const getColumnDefinitions = () => [
  columnHelper.display({
    id: 'display',
    aggregatedCell: DisplayCell,
    size: 26
  }),
  columnHelper.accessor(({ key }) => key, {
    id: 'accountKey',
    header: (props) => (
      <ColumnHeaderWithSortIndicator {...props} leftAligned>
        Account
      </ColumnHeaderWithSortIndicator>
    ),
    cell: AccountCellExtended,
    aggregatedCell: AccountCellExtended,
    footer: () => <div css={textAlignLeft}>Totals</div>
  }),
  columnHelper.accessor(
    ({ ismultimargin, isprimary }) => [ismultimargin, isprimary],
    {
      id: 'marginType',
      header: (props) => (
        <ColumnHeaderWithSortIndicator {...props}>
          Margin Type
        </ColumnHeaderWithSortIndicator>
      ),
      cell: MarginTypeCell,
      aggregatedCell: MarginTypeCell
    }
  ),
  columnHelper.accessor(
    ({ totalassetaccountvalue }) => totalassetaccountvalue,
    {
      id: 'investments',
      header: (props) => (
        <ColumnHeaderWithSortIndicator {...props}>
          Investments
        </ColumnHeaderWithSortIndicator>
      ),
      cell: USDCell,
      aggregatedCell: USDCell
    }
  ),
  columnHelper.accessor(({ cashbalance }) => cashbalance, {
    id: 'cash',
    header: (props) => (
      <ColumnHeaderWithSortIndicator {...props}>
        Cash
      </ColumnHeaderWithSortIndicator>
    ),
    cell: USDCell,
    aggregatedCell: USDCell,
    footer: (props) => <USDCellTotalAmount {...props} inColor />
  }),
  columnHelper.accessor(({ marginbalance }) => marginbalance, {
    id: 'marginbalance',
    header: (props) => (
      <ColumnHeaderWithSortIndicator {...props}>
        Loan Balance
      </ColumnHeaderWithSortIndicator>
    ),
    cell: USDCell,
    aggregatedCell: USDCell,
    footer: (props) => <USDCellTotalAmount {...props} inColor />
  }),
  columnHelper.accessor(({ totalaccountvalue }) => totalaccountvalue, {
    id: 'totalAccountvalue',
    header: (props) => (
      <ColumnHeaderWithSortIndicator {...props}>
        Total Account Value
      </ColumnHeaderWithSortIndicator>
    ),
    cell: USDCell,
    aggregatedCell: USDCell,
    footer: (props) => <USDCellTotalAmount {...props} inColor />
  }),
  columnHelper.accessor(() => null, {
    id: 'percentToHouseCall',
    header: (props) => (
      <ColumnHeaderWithSortIndicator {...props}>
        % to house Call
      </ColumnHeaderWithSortIndicator>
    ),
    cell: PercentageCell,
    aggregatedCell: PercentageCell
  }),
  columnHelper.accessor(({ availablewithdraw }) => availablewithdraw, {
    id: 'availableToWithdrawCash',
    header: (props) => (
      <ColumnHeaderWithSortIndicator {...props}>
        Cash
      </ColumnHeaderWithSortIndicator>
    ),
    cell: USDCell,
    aggregatedCell: USDCell
  }),
  columnHelper.accessor(
    ({ cashmarginavailabletowithdraw = 0, availablewithdraw = 0 }) =>
      cashmarginavailabletowithdraw - availablewithdraw,
    {
      id: 'availableToWithdrawMargin',
      header: (props) => (
        <ColumnHeaderWithSortIndicator {...props}>
          Margin
        </ColumnHeaderWithSortIndicator>
      ),
      cell: USDCell,
      aggregatedCell: USDCell
    }
  ),
  columnHelper.accessor(
    ({ cashmarginavailabletowithdraw }) => cashmarginavailabletowithdraw,
    {
      id: 'availableToWithdrawTotal',
      header: (props) => (
        <ColumnHeaderWithSortIndicator {...props}>
          Total
        </ColumnHeaderWithSortIndicator>
      ),
      cell: USDCell,
      aggregatedCell: USDCell
    }
  ),
  columnHelper.accessor(({ cashbuyingpower }) => cashbuyingpower, {
    id: 'availableToInvestCash',
    header: (props) => (
      <ColumnHeaderWithSortIndicator {...props}>
        Cash
      </ColumnHeaderWithSortIndicator>
    ),
    cell: USDCell,
    aggregatedCell: USDCell
  }),
  columnHelper.accessor(
    ({ nonmarginbuyingpower = 0, cashbuyingpower = 0 }) =>
      nonmarginbuyingpower - cashbuyingpower,
    {
      id: 'availableToInvestMargin',
      header: (props) => (
        <ColumnHeaderWithSortIndicator {...props}>
          Margin
        </ColumnHeaderWithSortIndicator>
      ),
      cell: USDCell,
      aggregatedCell: USDCell
    }
  ),
  columnHelper.accessor(({ marginintrate }) => marginintrate, {
    id: 'marginInterestRate',
    header: (props) => (
      <ColumnHeaderWithSortIndicator {...props}>
        Interest Rate
      </ColumnHeaderWithSortIndicator>
    ),
    cell: PercentageCell,
    aggregatedCell: PercentageCell
  }),
  columnHelper.accessor(({ marginaccruedinterest }) => marginaccruedinterest, {
    id: 'marginAccruedInterest',
    header: (props) => (
      <ColumnHeaderWithSortIndicator {...props}>
        Pending Interest Charge
      </ColumnHeaderWithSortIndicator>
    ),
    cell: USDCell,
    aggregatedCell: USDCell
  })
]
