import { isFunction, RowSelectionState, Updater } from '@tanstack/react-table'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  ISecureMessagesDateRange,
  secureMessagesActions,
  selectCurrentView,
  selectDateRange,
  selectEndDate,
  selectPreviousView,
  selectRowSelection,
  selectSearchText,
  selectSelectedMessage,
  selectShowAttach,
  selectShowReply,
  selectStartDate
} from '../store/secureMessagesSlice'
import { IGraphMessage, SecureMessageView } from '../types'

export const useSecureMessagesState = () => {
  const dispatch = useDispatch()

  const currentView = useSelector(selectCurrentView)
  const dateRange = useSelector(selectDateRange)
  const endDate = useSelector(selectEndDate)
  const previousView = useSelector(selectPreviousView)
  const rowSelection = useSelector(selectRowSelection)
  const searchText = useSelector(selectSearchText)
  const selectedMessage = useSelector(selectSelectedMessage)
  const showAttach = useSelector(selectShowAttach)
  const showReply = useSelector(selectShowReply)
  const startDate = useSelector(selectStartDate)

  const setCurrentView = useCallback(
    (view: SecureMessageView) => {
      dispatch(secureMessagesActions.updateCurrentView(view))
    },
    [dispatch]
  )

  const setDateRange = useCallback(
    (dateRange: ISecureMessagesDateRange) => {
      dispatch(secureMessagesActions.updateDateRange(dateRange))
    },
    [dispatch]
  )

  const setEndDate = useCallback(
    (date?: Date | null) => {
      dispatch(secureMessagesActions.updateEndDate(date || undefined))
    },
    [dispatch]
  )

  const setRowSelection = useCallback(
    (updater: Updater<RowSelectionState>) => {
      const updatedRowSelection = isFunction(updater)
        ? updater(rowSelection)
        : updater
      dispatch(secureMessagesActions.updateRowSelection(updatedRowSelection))
    },
    [dispatch, rowSelection]
  )

  const setSearchText = useCallback(
    (text: string) => {
      dispatch(secureMessagesActions.updateSearchText(text))
    },
    [dispatch]
  )

  const setSelectedMessage = useCallback(
    (message: IGraphMessage) => {
      dispatch(secureMessagesActions.updateSelectedMessage(message))
    },
    [dispatch]
  )

  const setShowAttach = useCallback(
    (show: boolean) => {
      dispatch(secureMessagesActions.updateShowAttach(show))
    },
    [dispatch]
  )

  const setShowReply = useCallback(
    (show: boolean) => {
      dispatch(secureMessagesActions.updateShowReply(show))
    },
    [dispatch]
  )

  const setStartDate = useCallback(
    (date?: Date | null) => {
      dispatch(secureMessagesActions.updateStartDate(date || undefined))
    },
    [dispatch]
  )

  return {
    currentView,
    dateRange,
    endDate,
    previousView,
    rowSelection,
    searchText,
    selectedMessage,
    showAttach,
    showReply,
    startDate,
    setCurrentView,
    setDateRange,
    setEndDate,
    setRowSelection,
    setSearchText,
    setSelectedMessage,
    setShowAttach,
    setShowReply,
    setStartDate
  }
}
