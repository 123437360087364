export type SecureMessageFolder = 'deleted' | 'drafts' | 'inbox' | 'sent'

export type SecureMessageView = SecureMessageFolder | 'new' | 'read' | 'edit'

export interface ISecureMessageEdit {
  body?: string
  subject?: string
}

export interface ISecureMessageMailbox {
  oid: string
  mailboxPrincipalName: string
}

export interface ISecureMessageRecipient {
  email?: string
  id?: string
  name?: string
}

export interface ISecureMessageRequest {
  body?: string
  cc?: ISecureMessageRecipient[]
  isDraft?: boolean
  subject: string
  to: ISecureMessageRecipient[]
}

export const secureMessageFolders: {
  name: string
  key: SecureMessageFolder
}[] = [
  { name: 'Inbox', key: 'inbox' },
  { name: 'Sent Items', key: 'sent' },
  { name: 'Deleted Items', key: 'deleted' },
  { name: 'Drafts', key: 'drafts' }
]
