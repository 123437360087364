import { SearchBox } from '@fluentui/react'
import { useCallback, useState } from 'react'
import { useDebounce } from 'react-use'
import { useSecureMessagesState } from '../../shared/hooks'

export const MessageSearchBox: React.FC = () => {
  const { searchText, setSearchText } = useSecureMessagesState()
  const [searchBoxText, setSearchBoxText] = useState(searchText)

  const handleSearchTextChanged = useCallback(
    (_e: unknown, newValue?: string) => {
      setSearchBoxText(newValue || '')
    },
    []
  )

  useDebounce(() => setSearchText(searchBoxText), 500, [searchBoxText])

  return (
    <SearchBox
      placeholder="Search by Subject or From"
      onChange={handleSearchTextChanged}
      autoComplete="off"
      value={searchBoxText}
      styles={{
        root: {
          width: 250,
          backgroundColor: searchText ? '#fbfb7366' : undefined,
          borderColor: '#AFB1B6',
          borderRadius: '4px',
          height: '34px'
        }
      }}
    />
  )
}
