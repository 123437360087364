import { skipToken } from '@reduxjs/toolkit/query'
import { useRdot360Context } from 'modules/Advisory/modules/Rdot360/store/rdot360Context'
import { useMemo } from 'react'
import { useGetRelatedPartiesByHouseholdIdQuery } from 'store/api/datahub'
import {
  useGetBusinessUnitQuery,
  useGetCurrentSystemuserQuery
} from 'store/api/dynamics'

export const useSecureMessagesMailbox = () => {
  const { selectedHouseholdId } = useRdot360Context()
  const { currentData, isFetching, error } =
    useGetRelatedPartiesByHouseholdIdQuery(selectedHouseholdId || skipToken)

  const { data: systemuser } = useGetCurrentSystemuserQuery()
  const businessUnitId = useMemo(
    () => systemuser?.businessunitid?.businessunitid,
    [systemuser]
  )

  const { data: businessUnit } = useGetBusinessUnitQuery(
    businessUnitId || skipToken
  )

  const filteredParties = useMemo(
    () =>
      currentData?.filter(
        (x) =>
          !!x.aadUserOId &&
          !!x.partyName &&
          (!x.partyType || x.partyType === 'Individual')
      ),
    [currentData]
  )

  const mailboxPrincipalNames = useMemo(() => {
    const set = new Set<string>()

    if (filteredParties) {
      filteredParties.forEach((x) => {
        if (x.mailboxPrincipalName) {
          set.add(x.mailboxPrincipalName)
        }
      })
    }

    return [...set]
  }, [filteredParties])

  return {
    businessUnit,
    filteredParties,
    isMailboxesFetching: isFetching,
    mailboxesError: error,
    mailboxPrincipalNames
  }
}
