import { IconButton } from '@fluentui/react'
import { useSecureMessagesState } from '../../shared/hooks'
import { styles } from '../../shared/styles'

export const AttachIconButton: React.FC = () => {
  const { setShowAttach } = useSecureMessagesState()

  return (
    <IconButton
      iconProps={{
        iconName: 'Attach',
        style: styles.icon
      }}
      onClick={() => setShowAttach(true)}
      title="Attach a file"
    />
  )
}
