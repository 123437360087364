import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import {
  createApi,
  FetchBaseQueryError
} from '@reduxjs/toolkit/dist/query/react'
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers'
import {
  IBusinessUnit,
  ICdsBatchRequestItem,
  IContact,
  IDynamicsApiResult,
  IOrganization,
  ISystemUser,
  IWhoAmIResponse
} from 'api/dynamics'
import { escapeFilterString } from 'api/odata'
import { escapeAndEncodeQuery, tokenizeQuery } from 'api/search'
import { chunk, flow, partition, trim } from 'lodash'
import { stringify } from 'query-string'
import { isNotNullOrEmpty, isNotNullOrUndefined } from 'shared/guards'
import { tryAcquireAccessToken } from 'shared/services/auth'
import { AppState } from 'store/shared'
import { getDynamicsCrmApiConfig } from 'store/system'
import { v4 as uuidv4 } from 'uuid'
import { AxiosBaseArgs, axiosBaseQuery } from './shared'

export const DynamicsApiSliceKey = 'api.dynamics'
export type DynamicsApiReducerState = {
  [DynamicsApiSliceKey]: ReturnType<typeof dynamicsApi.reducer>
}

export interface IDynamicsApiOdataRequest {
  $select?: string[]
  $expand?: string[]
}

const getDynamicsApiBaseUrl = (
  state: AppState,
  version: 'v9.1' | 'v9.0' | 'v9.2' = 'v9.2'
) => {
  const base = flow(getDynamicsCrmApiConfig, (x) => x?.root)(state)
  const url = new URL(`/api/data/${version}`, base)
  return url.href
}

const paramsSerializer = (params: IDynamicsApiOdataRequest) => {
  return stringify(params, { arrayFormat: 'comma' })
}

const getDynamicsApiAuthToken = (state: AppState) => {
  const scopes = flow(getDynamicsCrmApiConfig, (x) => x?.scopes)(state)

  if (!scopes) {
    throw new Error('No scopes provided in configuration for graph api')
  }

  return tryAcquireAccessToken(scopes)
}

const fullNameTokenSearchFilter = (search: string): string =>
  tokenizeQuery(search)
    .map(escapeFilterString)
    .map(escapeAndEncodeQuery)
    .map(trim)
    .filter(isNotNullOrEmpty)
    .map((token) => `contains(fullname, '${token}')`)
    .join(' and ')

export const dynamicsApi = createApi({
  reducerPath: DynamicsApiSliceKey,
  baseQuery: axiosBaseQuery({
    getBaseUrl: (state) => getDynamicsApiBaseUrl(state),
    getAuthToken: (state) => getDynamicsApiAuthToken(state)
  }),
  endpoints: (builder) => ({
    getBusinessUnit: builder.query<IBusinessUnit, string>({
      query: (businessunitid) => ({
        url: `/businessunits(${businessunitid})`,
        params: {
          $select: '_parentbusinessunitid_value,name,websiteurl,emailaddress'
        },
        paramsSerializer
      })
    }),
    getSystemUser: builder.query<
      ISystemUser,
      { systemuserId: string; params: IDynamicsApiOdataRequest }
    >({
      query: ({ systemuserId, params }) => ({
        url: `/systemusers(${systemuserId})`,
        params,
        paramsSerializer
      })
    }),
    findContactsByPartyId: builder.query<IContact[] | undefined, string>({
      query: (id?: string) => ({
        url: `/contacts?$filter=rcm_rockcodbpartyid eq '${id}'`,
        headers: {
          Accept: 'application/json, text/plain, */*',
          Prefer:
            'odata.include-annotations="OData.Community.Display.V1.FormattedValue"'
        }
      }),
      transformResponse: (x: IDynamicsApiResult<IContact>) => x.value,
      keepUnusedDataFor: 60 * 20
    }),
    findOrgByPartyId: builder.query<any[] | undefined, string>({
      query: (id?: string) => ({
        url: `/accounts?$filter=rcm_rockcodbpartyid eq '${id}'`,
        headers: {
          Accept: 'application/json, text/plain, */*',
          Prefer:
            'odata.include-annotations="OData.Community.Display.V1.FormattedValue"'
        }
      }),
      transformResponse: (x: IDynamicsApiResult<any>) => x.value,
      keepUnusedDataFor: 60 * 20
    }),
    getSystemUsers: builder.query<
      ISystemUser[] | undefined,
      string | undefined
    >({
      query: (search) => ({
        url: '/systemusers',
        params: {
          $select: 'systemuserid, fullname',
          $filter: [
            search && fullNameTokenSearchFilter(search),
            'systemuserid ne null',
            'fullname ne null'
          ]
            .filter(Boolean)
            .join(' and '),
          $top: 10
        },
        paramsSerializer,
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`
        }
      }),
      transformResponse: (x: IDynamicsApiResult<IContact>) => x.value,
      keepUnusedDataFor: 60 * 20
    }),
    getWhoAmI: builder.query<IWhoAmIResponse, void>({
      queryFn: async function queryFn(arg, api, extraOptions, baseQuery) {
        return (await baseQuery('WhoAmI')) as QueryReturnValue<
          IWhoAmIResponse,
          unknown
        >
      },
      keepUnusedDataFor: 60 * 60 * 24
    }),
    getCurrentSystemuser: builder.query<ISystemUser, void>({
      queryFn: async function queryFn(arg, api, extraOptions, baseQuery) {
        const whoAmIResponse = await baseQuery('WhoAmI')

        if (whoAmIResponse.error) {
          return {
            error: whoAmIResponse.error as FetchBaseQueryError
          }
        }

        const systemuserId = (
          whoAmIResponse.data as IWhoAmIResponse | undefined
        )?.UserId

        if (!systemuserId) {
          return { error: new Error('Current user not found') }
        }

        const systemuserResponse = await baseQuery({
          url: `/systemusers(${systemuserId})`,
          params: {
            $select: [
              'systemuserid',
              'title',
              'fullname',
              'azureactivedirectoryobjectid',
              'domainname',
              'employeeid',
              'isdisabled',
              'lastname',
              'jobtitle',
              'organizationid',
              '_businessunitid_value'
            ],
            $expand: [
              'rcm_AdvisorRep_SystemUser($select=rcm_name,rcm_repid,_rcm_owningteam_value,_rcm_personalrepfor_value)',
              'rcm_AdvisorRep_PersonalRepFor_SystemUser($select=rcm_name,rcm_repid,_rcm_owningteam_value,_rcm_personalrepfor_value)',
              'rcm_AdvisorManager_Manager_SystemUser($select=rcm_isprimarysigner,rcm_isprincipal,rcm_advisormanagerid,statuscode,_rcm_businessunit_value)',
              'rcm_AdvisorAttributes_Advisor_SystemUser($select=rcm_wealthscapeinvestorid,rcm_crdnumber,rcm_advisorattributesid,_rcm_primaryrepcode_value)',
              'businessunitid($select=name,_parentbusinessunitid_value;$expand=parentbusinessunitid($select=name;$expand=parentbusinessunitid($select=name)))',
              'systemuserroles_association($select=name)'
            ]
          } as IDynamicsApiOdataRequest,
          paramsSerializer
        })

        if (systemuserResponse.error) {
          return {
            error: systemuserResponse.error as FetchBaseQueryError
          }
        }

        const systemuser = systemuserResponse.data as ISystemUser

        return { data: systemuser }
      },
      keepUnusedDataFor: 60 * 60 * 24
    }),
    getContactsAndOrgsByPartyIds: builder.query<
      { contacts?: IContact[]; orgs?: IOrganization[] },
      { individialPartyIds: string[]; partyIds: string[] }
    >({
      queryFn: async (
        { individialPartyIds, partyIds },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        const chunkSize = 100
        const contactRequests = chunk(individialPartyIds, chunkSize).map(
          (ids): ICdsBatchRequestItem => ({
            method: 'GET',
            url: `/api/data/v9.2${[
              `/contacts?$filter=statecode eq 0 and ${`Microsoft.Dynamics.CRM.In(PropertyName='rcm_rockcodbpartyid',PropertyValues=[${ids
                .map((x) => `'${x}'`)
                .join(',')}])`}`,
              `&$select=${[
                'firstname',
                'lastname',
                'nickname',
                'birthdate',
                'mobilephone',
                'telephone1',
                'telephone2',
                'emailaddress1',
                'contactid',
                'fullname',
                'middlename',
                'suffix',
                'address1_line1',
                'address1_line2',
                'address1_line3',
                'address1_city',
                'address1_stateorprovince',
                'address1_postalcode',
                'address1_country',
                'rpm_headofhousehold',
                'modifiedon',
                'rpm_dateofbirth',
                'address1_composite',
                'rcm_contacttype',
                'jobtitle',
                'rcm_secureid',
                'rcm_taxidtype',
                'rpm_headofhousehold',
                'familystatuscode',
                'gendercode',
                'rcm_rockcodbpartyid',
                '_aka_householdid_value',
                'rpm_employer',
                '_rcm_primaryadvisor_value',
                '_rcm_primaryclientassociate_value',
                'rcm_accreditedinvestor',
                'rcm_clientsegment',
                'rcm_qualifiedpurchaser',
                'rpm_networth'
              ].join(',')}`
            ].join('')}`,
            headers: {
              Accept: 'application/json, text/plain, */*',
              Prefer:
                'odata.include-annotations="OData.Community.Display.V1.FormattedValue"'
            }
          })
        )

        const orgRequests = chunk(partyIds, chunkSize).map(
          (ids): ICdsBatchRequestItem => ({
            method: 'GET',
            url: `/api/data/v9.2${[
              `/accounts?$filter=statecode eq 0 and ${`Microsoft.Dynamics.CRM.In(PropertyName='rcm_rockcodbpartyid',PropertyValues=[${ids
                .map((x) => `'${x}'`)
                .join(',')}])`}`,
              `&$select=${[
                'name',
                'ram_investortype',
                'rcm_taxidtype',
                'rcm_taxid',
                'address1_composite',
                'accountid',
                'rcm_rockcodbpartyid',
                'telephone1',
                'emailaddress1',
                'ram_dba',
                'industrycode',
                '_primarycontactid_value'
              ].join(',')}`
            ].join('')}`,
            headers: {
              Accept: 'application/json, text/plain, */*',
              Prefer:
                'odata.include-annotations="OData.Community.Display.V1.FormattedValue"'
            }
          })
        )

        const requests = [...contactRequests, ...orgRequests]
        const responses = await executeBatchRequest<
          IDynamicsApiResult<IContact | IOrganization>[]
        >(requests, baseQuery)
        const error = responses?.find((x) => !!x.error)
        if (error) {
          return { error }
        }

        const dataResponses = responses
          .flatMap((x) => x?.data)
          .flatMap((x) => x?.value)
          .filter(isNotNullOrUndefined)

        const [contacts, orgs] = partition(
          dataResponses,
          (x: unknown): x is IContact => !!(x as IContact)?.contactid
        ) as [IContact[], IOrganization[]]

        return {
          data: {
            contacts,
            orgs
          }
        }
      }
    })
  })
})

export const {
  useGetWhoAmIQuery,
  useGetCurrentSystemuserQuery,
  usePrefetch: useDynamicsApiPrefetch,
  useFindContactsByPartyIdQuery,
  useFindOrgByPartyIdQuery,
  useGetBusinessUnitQuery,
  useGetSystemUsersQuery,
  useGetContactsAndOrgsByPartyIdsQuery
} = dynamicsApi

export const useDynamicsUser = () => {
  return
}

export const createCdsBatchPayload = (requests: ICdsBatchRequestItem[]) => {
  const newline = '\r\n'
  const boundary = `batch_${uuidv4()}`

  return {
    batchRequest: [
      ...requests.map((x) =>
        [
          `--${boundary}`,
          'Content-Type: application/http',
          'Content-Transfer-Encoding:binary',
          '',
          [
            `${x.method} ${x.url} HTTP/1.1`,
            ...Object.entries(x.headers || {}).map(
              ([key, value]) => `${key}: ${value}`
            ),
            'Content-Type: application/json',
            '',
            x.payload && `${JSON.stringify(x.payload)}`
          ]
            .filter((x) => x != null)
            .join(newline)
        ].join(newline)
      ),
      '',
      `--${boundary}--`,
      ''
    ].join(newline),
    boundary
  }
}

export type BatchResponse<T> = QueryReturnValue<IDynamicsApiResult<T>[], Error>

export const executeBatchRequest = async <T>(
  requests: ICdsBatchRequestItem[],
  baseQuery: (
    arg: string | AxiosBaseArgs
  ) => MaybePromise<QueryReturnValue<unknown, unknown, unknown>>
) => {
  const newline = '\r\n'
  const boundary = `batch_${uuidv4()}`
  const chunks = chunk(requests, 1000)

  const parts = chunks.map((chunk) =>
    chunk.map((x) =>
      [
        `${x.method} ${encodeURI(x.url)} HTTP/1.1`,
        ...Object.entries(x.headers || {}).map(
          ([key, value]) => `${key}: ${value}`
        ),
        x.payload && 'Content-Type: application/json',
        x.payload && '',
        x.payload && `${JSON.stringify(x.payload)}`
      ]
        .filter((x) => x != null)
        .join(newline)
    )
  )

  const payloads = parts.map((part) => {
    return [
      `--${boundary}`,
      'Content-Type: application/http',
      'Content-Transfer-Encoding:binary',
      '',
      ...[
        part.join(
          [
            '',
            '',
            `--${boundary}`,
            'Content-Type: application/http',
            'Content-Transfer-Encoding:binary',
            '',
            ''
          ].join(newline)
        )
      ],
      '',
      `--${boundary}--`,
      ''
    ].join(newline)
  })

  type Response = QueryReturnValue<string, Error>

  const responses = await Promise.all(
    payloads.map(async (payload) => {
      return baseQuery({
        url: '/$batch',
        method: 'POST',
        headers: {
          'Content-Type': `multipart/mixed;boundary=${boundary}`,
          Accept: 'application/json',
          'OData-MaxVersion': '4.0',
          'OData-Version': '4.0',
          Prefer: 'odata.continue-on-error'
        },
        data: payload
      }) as Promise<Response>
    })
  )

  const parsedResponses = responses.map((response) => {
    if (response.data) {
      const parts = response.data.split(`--batchresponse_`).slice(1, -1)
      return {
        data: parts?.map((x) => {
          const startJson = x.indexOf('{')
          const endJson = x.lastIndexOf('}')
          if (startJson < 0 || endJson < 0) {
            return { data: undefined }
          }
          return JSON.parse(x.substring(startJson, endJson + 1))
        })
      }
    } else if (response.error) {
      return { error: response.error }
    } else {
      return { data: undefined }
    }
  }) as QueryReturnValue<T, Error>[]

  return parsedResponses
}
