import { CellContext } from '@tanstack/react-table'
import { IActivity } from 'modules/Advisory/modules/Rdot360/store/types'
import { PrintDescriptionCell } from './PrintDescriptionCell'

export const PrintDescription: React.FC<CellContext<IActivity, unknown>> = (
  props
) => {
  const {
    original: { trndesc: description }
  } = props.row
  return <PrintDescriptionCell description={description || ''} />
}
