import { css, Theme, useTheme } from '@emotion/react'
import { ITooltipProps, TooltipHost } from '@fluentui/react'
import {
  AccessorFnColumnDef,
  CellContext,
  HeaderContext
} from '@tanstack/react-table'
import { IAccount } from 'api/account.types'
import { sum } from 'lodash'
import React, { useMemo } from 'react'
import { USD } from 'shared/components/Formatting'
import { rdotCategoryToClientDashboardCategoryMap } from '../../features/AccountSelector/types'
import { useBalanceSummaryDetailsUiState } from '../../modules/Balances/balanceSummaryDetailsUIState'
import { convertTaxStatus } from '../../shared/taxStatus'
import { isMultiMargin } from '../../store/rdot360Context'
import {
  EnhancedBalanceDetailResponseValue,
  getTodaysChange
} from '../../store/rdot360Context/useRdot360BalancesContext'
import { AccruedIncome } from '../AccruedIncome/AccruedIncome'
import { AccountNumberCell } from '../shared/DetailTables/AccountNumberCell'
import { USDCellWithColor } from '../USDCellWithColor'
import { balancesDetailTableColumnNames } from './shared'
import { TodaysChange } from './TodaysChange'

export type BalanceItem = {
  account?: IAccount
  cag?: string
} & EnhancedBalanceDetailResponseValue

const AmountCell: React.FC<CellContext<BalanceItem, unknown>> = ({
  getValue
}) => {
  const value = getValue<number>()
  return value ? (
    <USD value={value} fractionDigits={2} currencySign={'standard'} />
  ) : (
    <>--</>
  )
}
const MarginAmountCell: React.FC<CellContext<BalanceItem, unknown>> = ({
  getValue,
  row
}) => {
  const value = getValue<number>()
  return value ? (
    <>
      {isMultiMargin(row.original.account) ? (
        <div>Multi Margin</div>
      ) : (
        <USD value={value} fractionDigits={2} currencySign={'standard'} />
      )}
    </>
  ) : (
    <>--</>
  )
}

const MarginAggregatedCell: React.FC<CellContext<BalanceItem, unknown>> = ({
  getValue,
  row
}) => {
  const leafRows = row.getLeafRows()
  const hasMultiMargin = leafRows.some((x) => isMultiMargin(x.original.account))
  const value = getValue<number>()
  return (
    !!value &&
    !hasMultiMargin && (
      <USD value={value} fractionDigits={2} currencySign={'standard'} />
    )
  )
}

const SumFooter: React.FC<HeaderContext<BalanceItem, unknown>> = ({
  table,
  column
}) => {
  const total = sum(
    table
      .getPreExpandedRowModel()
      .rows.map(({ getValue }) => getValue<number>(column.id) || 0)
  )
  return <USD value={total} fractionDigits={2} currencySign={'standard'} />
}

const SumFooterMargin: React.FC<HeaderContext<BalanceItem, unknown>> = ({
  table,
  column
}) => {
  const hasMultiMargin = table
    .getRowModel()
    .rows.flatMap((x) => x.getLeafRows())
    .some((x) => isMultiMargin(x.original.account))

  const total = sum(
    table
      .getPreExpandedRowModel()
      .rows.map(({ getValue }) => getValue<number>(column.id) || 0)
  )
  return (
    !hasMultiMargin &&
    !!total && (
      <USD value={total} fractionDigits={2} currencySign={'standard'} />
    )
  )
}

const SumFooterColor: React.FC<HeaderContext<BalanceItem, unknown>> = ({
  table,
  column
}) => {
  const total = sum(
    table
      .getPreExpandedRowModel()
      .rows.map(({ getValue }) => getValue<number>(column.id) || 0)
  )
  return <USDCellWithColor value={total} fractionDigit={2} />
}

export const getBalanceSummaryDetailsClasses = (theme: Theme) => ({
  container: css({
    padding: theme.size.sm,
    fontSize: theme.size.md
  }),
  toolTipContainer: css({
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.size.xs,
    ':nth-child(odd)': {
      background: theme.colors.tertiaryGray5
    }
  }),
  extraGreen1: css({
    color: theme.colors.extraGreen1
  })
})

const AccountNumberColumnCell: React.FC<CellContext<BalanceItem, unknown>> = ({
  row
}) => {
  const key = row.original.key || row.original.accountaumber
  const { searchText } = useBalanceSummaryDetailsUiState()

  return <AccountNumberCell accountIdOrKey={key} search={searchText} />
}

const AccruedIncomeCell: React.FC<CellContext<BalanceItem, unknown>> = (
  context
) => {
  const holdingAccruIncome = context.getValue<number>()
  const { holdingAccruDividends, holdingAccruInterest } = useMemo(() => {
    let holdingAccruDividends = context.row.original.holdingAccruDividends
    let holdingAccruInterest = context.row.original.holdingAccruInterest

    if (context.row.subRows.length) {
      const originalItems = context.row.subRows.map((item) => item.original)
      holdingAccruDividends = sum(
        originalItems.map((item) => item.holdingAccruDividends)
      )
      holdingAccruInterest = sum(
        originalItems.map((item) => item.holdingAccruInterest)
      )
    }
    return {
      holdingAccruDividends,
      holdingAccruInterest
    }
  }, [
    context.row.original.holdingAccruDividends,
    context.row.original.holdingAccruInterest,
    context.row.subRows
  ])

  return (
    <AccruedIncome
      dividends={holdingAccruDividends}
      income={holdingAccruIncome}
      interest={holdingAccruInterest}
    />
  )
}

const AccruedIncomeFooter: React.FC<HeaderContext<BalanceItem, unknown>> = ({
  table
}) => {
  const balanceItems = table
    .getRowModel()
    .rows.flatMap((x) => x.getLeafRows())
    .map((x) => x.original)
  const holdingAccruIncome = sum(balanceItems.map((x) => x.holdingAccruIncome))
  const holdingAccruInterest = sum(
    balanceItems.map((x) => x.holdingAccruInterest)
  )
  const holdingAccruDividends = sum(
    balanceItems.map((x) => x.holdingAccruDividends)
  )

  return (
    <AccruedIncome
      dividends={holdingAccruDividends}
      income={holdingAccruIncome}
      interest={holdingAccruInterest}
      isTotalRow={true}
    />
  )
}

const TodaysAmountChange: React.FC<CellContext<BalanceItem, unknown>> = (
  props
) => {
  const value = props.getValue<number>()
  const todaysChangeData = useMemo(() => {
    let balancesTodaysChange = props.row.original.todayschange?.RCM

    if (props.row.subRows.length) {
      const subRowsBalanceData = props.row.subRows.map((item) => item.original)
      balancesTodaysChange = getTodaysChange(subRowsBalanceData)
    }

    return balancesTodaysChange
  }, [props.row.original.todayschange?.RCM, props.row.subRows])

  return (
    <TodaysChange
      change={value}
      changeDetails={todaysChangeData}
      accountNumber={props.row.original.accountaumber}
    />
  )
}

const CashCellClasses = (theme: Theme) => ({
  hoverRow: css({ display: 'flex', columnGap: 10, padding: 5 }),
  greyRow: css({ backgroundColor: theme.colors.extraGray }),
  rightAlign: css({ textAlign: 'right', flexGrow: 1 }),
  container: css({
    display: 'flex',
    flexDirection: 'column'
  }),
  label: css({ fontWeight: theme.fontWeights.demi, width: 150 })
})

const CashCell: React.FC<CellContext<BalanceItem, unknown>> = (props) => {
  const value = props.getValue<number>()
  const theme = useTheme()
  const classes = useMemo(() => CashCellClasses(theme), [theme])
  const tooltipProps: ITooltipProps = useMemo(
    () => ({
      onRenderContent: () => (
        <div css={classes.container}>
          <div css={[classes.hoverRow, classes.greyRow]}>
            <div css={classes.label}>Bank Deposit</div>
            <div css={classes.rightAlign}>
              <USD
                value={props?.row.original?.moneyaccountvalue || 0}
                fractionDigits={2}
                currencySign={'standard'}
              />
            </div>
          </div>
          <div css={classes.hoverRow}>
            <div css={classes.label}>Pending and Other Cash</div>
            <div css={classes.rightAlign}>
              <USD
                value={props?.row.original?.cashbalance || 0}
                fractionDigits={2}
                currencySign={'standard'}
              />
            </div>
          </div>
          <div css={[classes.hoverRow, classes.greyRow]}>
            <div css={classes.label}>Accumulated Income</div>
            <div css={classes.rightAlign}>
              <USD
                value={props?.row.original?.dividendinterest_type9 || 0}
                fractionDigits={2}
                currencySign={'standard'}
              />
            </div>
          </div>
        </div>
      )
    }),
    [
      classes.container,
      classes.greyRow,
      classes.hoverRow,
      classes.label,
      classes.rightAlign,
      props?.row.original?.cashbalance,
      props?.row.original?.dividendinterest_type9,
      props?.row.original?.moneyaccountvalue
    ]
  )

  return (
    <TooltipHost tooltipProps={tooltipProps} closeDelay={800}>
      <div css={{ cursor: 'pointer' }}>
        <USD value={value} fractionDigits={2} currencySign={'standard'} />
      </div>
    </TooltipHost>
  )
}

export const getBalancesDetailTableColumns =
  (): AccessorFnColumnDef<BalanceItem>[] => [
    {
      header: balancesDetailTableColumnNames.taxable,
      aggregationFn: 'unique',
      accessorFn: ({ account }) => convertTaxStatus(account?.taxstatus)
    },
    {
      header: balancesDetailTableColumnNames.legalEntityId,
      aggregationFn: 'unique',
      accessorFn: ({ account }) => account?.LegalEntityName
    },
    {
      header: balancesDetailTableColumnNames.accountRegistration,
      aggregationFn: 'unique',
      accessorFn: ({ account }) =>
        account?.registrationDesc || account?.registrationtype
    },
    {
      header: balancesDetailTableColumnNames.ausClass,
      aggregationFn: 'unique',
      accessorFn: ({ account }) => account?.accounttype
    },
    {
      id: balancesDetailTableColumnNames.custodianName,
      aggregationFn: 'unique',
      accessorFn: ({ account }) =>
        account?.CustodianName === 'nfs' ? 'NFS' : account?.CustodianName
    },
    {
      id: balancesDetailTableColumnNames.clientDashboardCategory,
      aggregationFn: 'unique',
      accessorFn: ({ account }) =>
        rdotCategoryToClientDashboardCategoryMap[
          account?.RDOTAccountCategoryCode || '04'
        ]
    },
    {
      id: balancesDetailTableColumnNames.clientDashboardCategoryCode,
      aggregationFn: 'max',
      accessorFn: ({ account }) => account?.RDOTAccountCategoryCode
    },
    {
      id: balancesDetailTableColumnNames.cag,
      aggregationFn: 'unique',
      accessorFn: ({ cag }) => cag
    },
    {
      id: balancesDetailTableColumnNames.accountNumber,
      header: balancesDetailTableColumnNames.accountNumber,
      accessorFn: ({ accountaumber }) => accountaumber,
      cell: AccountNumberColumnCell,
      aggregatedCell: AccountNumberColumnCell,
      footer: 'Total',
      minSize: 240,
      enableGlobalFilter: true
    },
    {
      id: balancesDetailTableColumnNames.cashmoneyaccounts,
      header: balancesDetailTableColumnNames.cashmoneyaccounts,
      accessorFn: ({ cash }) => cash,
      enableGlobalFilter: false,
      cell: CashCell,
      aggregatedCell: AmountCell,
      footer: SumFooter,
      minSize: 120
    },
    {
      id: balancesDetailTableColumnNames.pricedinvestments,
      header: balancesDetailTableColumnNames.pricedinvestments,
      accessorFn: ({ pricedinvestments }) => pricedinvestments,
      enableGlobalFilter: false,
      cell: AmountCell,
      aggregatedCell: AmountCell,
      footer: SumFooter,
      minSize: 140
    },
    {
      id: balancesDetailTableColumnNames.annuity,
      header: balancesDetailTableColumnNames.annuity,
      accessorFn: ({ annuity }) => annuity,
      aggregationFn: 'sum',
      enableGlobalFilter: false,
      cell: AmountCell,
      aggregatedCell: AmountCell,
      footer: SumFooter,
      minSize: 160
    },
    {
      id: balancesDetailTableColumnNames.other,
      header: balancesDetailTableColumnNames.other,
      accessorFn: ({ other }) => other,
      enableGlobalFilter: false,
      cell: AmountCell,
      aggregatedCell: AmountCell,
      footer: SumFooter,
      minSize: 120
    },
    {
      id: balancesDetailTableColumnNames.accruedIncome,
      header: balancesDetailTableColumnNames.accruedIncome,
      accessorFn: ({ holdingAccruIncome }) => holdingAccruIncome,
      enableGlobalFilter: false,
      cell: AccruedIncomeCell,
      aggregatedCell: AccruedIncomeCell,
      footer: AccruedIncomeFooter,
      minSize: 120
    },
    {
      id: balancesDetailTableColumnNames.loans,
      header: balancesDetailTableColumnNames.loans,
      accessorFn: ({ totalLiabilities }) => totalLiabilities,
      enableGlobalFilter: false,
      cell: AmountCell,
      aggregatedCell: AmountCell,
      footer: SumFooter,
      minSize: 120
    },
    {
      id: balancesDetailTableColumnNames.netBalance,
      header: balancesDetailTableColumnNames.netBalance,
      accessorFn: ({ netWorth }) => netWorth,
      enableGlobalFilter: false,
      cell: AmountCell,
      aggregatedCell: AmountCell,
      footer: SumFooter,
      minSize: 120
    },
    {
      id: balancesDetailTableColumnNames.todaysChange,
      header: balancesDetailTableColumnNames.todaysChange,
      accessorFn: ({ netWorthChange }) => netWorthChange,
      enableGlobalFilter: false,
      cell: TodaysAmountChange,
      aggregatedCell: TodaysAmountChange,
      footer: SumFooterColor,
      minSize: 120
    },
    {
      id: balancesDetailTableColumnNames.availableToWithdraw,
      header: balancesDetailTableColumnNames.availableToWithdraw,
      accessorFn: ({ availableToWithdraw }) => availableToWithdraw,
      enableGlobalFilter: false,
      cell: MarginAmountCell,
      aggregatedCell: MarginAggregatedCell,
      footer: SumFooterMargin,
      minSize: 160
    },
    {
      id: balancesDetailTableColumnNames.availableToInvest,
      header: balancesDetailTableColumnNames.availableToInvest,
      accessorFn: ({ availableToInvest }) => availableToInvest,
      enableGlobalFilter: false,
      cell: MarginAmountCell,
      aggregatedCell: MarginAggregatedCell,
      footer: SumFooterMargin,
      minSize: 150
    }
  ]
