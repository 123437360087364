import { IconButton } from '@fluentui/react'
import { isEmpty } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useSecureMessagesState } from '../../shared/hooks'
import { styles } from '../../shared/styles'

export interface IDeleteSelectedProps {
  disabled?: boolean
  onClick: () => void
}

export const DeleteSelected: React.FC<IDeleteSelectedProps> = ({
  disabled,
  onClick
}) => {
  const { rowSelection } = useSecureMessagesState()

  const buttonDisabled = useMemo(
    () => disabled || isEmpty(rowSelection),
    [disabled, rowSelection]
  )

  const handleClick = useCallback(() => {
    if (buttonDisabled) {
      return
    }
    onClick()
  }, [buttonDisabled, onClick])

  return (
    <div style={styles.buttonContainer}>
      <IconButton
        disabled={buttonDisabled}
        iconProps={{
          iconName: 'Trash',
          style: {
            color: buttonDisabled ? undefined : '#005CB8',
            fontSize: '24px'
          }
        }}
        onClick={handleClick}
        title="Delete selected"
      />
    </div>
  )
}
