import { Link, Text } from '@fluentui/react'
import { noSubjectText } from '../../shared/constants'

export interface ISubjectCellProps {
  isDraft?: boolean
  onClick: () => void
  value?: string
}

export const SubjectCell: React.FC<ISubjectCellProps> = ({
  isDraft,
  onClick,
  value
}) => {
  return (
    <div style={{ display: 'flex', gap: 4 }}>
      {isDraft && <Text style={{ color: '#a4262c' }}>[Draft]</Text>}
      <Link onClick={onClick}>
        <Text className="cell">{value || noSubjectText}</Text>
      </Link>
    </div>
  )
}
