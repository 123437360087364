import { Link, Stack } from '@fluentui/react'
import { memo, useState } from 'react'
import DetailsNavigator from '../../components/shared/DetailsNavigator/DetailsNavigator'
import { getHeaderStyles } from '../shared/getHeaderStyles'
import PerformanceDetailTable from './PerformanceDetailTable'
import PerformanceFilters from './PerformanceFilters'
import ReportFilters from './Reports/ReportFilters'
import { ReportsDetailTable } from './Reports/ReportsDetailTable/ReportsDetailTable'

const classes = getHeaderStyles()

const PerformanceDetailView: React.FC = memo(() => {
  const [reportsView, setReportsView] = useState(false)

  return (
    <div css={classes.container}>
      <div css={classes.headerContainer}>
        <div css={classes.leftContainer}>
          <DetailsNavigator />
          <Stack horizontal tokens={{ childrenGap: 20 }} css={classes.tabs}>
            <div
              css={classes.tab}
              className={!reportsView ? 'selected' : undefined}
            >
              <Link onClick={() => setReportsView(false)}>Performance</Link>
            </div>
            <div
              css={classes.tab}
              className={reportsView ? 'selected' : undefined}
            >
              <Link onClick={() => setReportsView(true)}>Reports</Link>
            </div>
          </Stack>
        </div>
        <div css={classes.filterContainer}>
          {reportsView ? <ReportFilters /> : <PerformanceFilters />}
        </div>
      </div>
      {reportsView ? <ReportsDetailTable /> : <PerformanceDetailTable />}
    </div>
  )
})

export default PerformanceDetailView
