import { IToggleProps } from '@fluentui/react'
import { FC } from 'react'
import Toggle from '../Toggle'

interface IExtendedToggleProps extends IToggleProps {
  className?: string
}

export const ToggleDoubleSided: FC<IExtendedToggleProps> = ({ ...props }) => {
  const { className, defaultChecked, onText, offText } = props
  return (
    <div
      className={className}
      css={{
        display: 'flex',
        columnGap: 5,
        alignItems: 'center'
      }}
    >
      <div css={{ opacity: defaultChecked ? 0.5 : undefined }}>{onText}</div>
      <Toggle {...props} onText={undefined} offText={undefined} />
      <div css={{ opacity: defaultChecked ? undefined : 0.5 }}>{offText}</div>
    </div>
  )
}
