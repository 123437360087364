import { buttonStyles } from 'modules/Advisory/modules/Rdot360/components/shared/Buttons'
import { Icon } from 'modules/Advisory/modules/Rdot360/features/Icons/Icon'
import { useCallback } from 'react'
import { useSecureMessagesState } from '../../shared/hooks'

export const NewMessageButton: React.FC = () => {
  const { setCurrentView } = useSecureMessagesState()

  const handleButtonClick = useCallback(() => {
    setCurrentView('new')
  }, [setCurrentView])

  return (
    <button
      onClick={handleButtonClick}
      css={buttonStyles.secondary}
      title="Create a new message"
    >
      <div style={{ display: 'flex', gap: '6px' }}>
        <Icon type="Add" width={14} height={14} color="black" />
        <span style={{ whiteSpace: 'nowrap' }}>Create a New Message</span>
      </div>
    </button>
  )
}
