import { getHurdles, IHurdle } from 'api/datahub'
import axios from 'axios'
import { call, cancelled, delay, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import { AppState } from '../../../../../../../../../store'
import {
  createAsyncHook,
  createAsyncReducer,
  createAsyncSelectors
} from '../../../../../../../../../store/shared/asyncStore'
import { getRockefellerApiOptions } from '../../../../../../../../../store/shared/sagas'

export const hurdleSearchFetchActions = createAsyncAction(
  '@modules/@teams/@hurdleSearchFetch/REQUEST',
  '@modules/@teams/@hurdleSearchFetch/SUCCESS',
  '@modules/@teams/@hurdleSearchFetch/FAILURE'
)<string | undefined, IHurdle[], Error>()

export const hurdleSearchFetchReducer = createAsyncReducer(
  hurdleSearchFetchActions
)

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.teams.modules.hurdles.features.entitySearch
    .hurdleSearchFetch

const selectors = createAsyncSelectors(rootSelector)

const onRequest = function* (
  action: ReturnType<typeof hurdleSearchFetchActions.request>
) {
  if (!action.payload || !action.payload.length) {
    yield put(hurdleSearchFetchActions.success([]))
    return
  }
  yield delay(500)

  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()

  try {
    const apiOptions = yield* call(getRockefellerApiOptions)
    const result = yield* call(getHurdles, apiOptions, {
      filters: [`contains(name, '${action.payload}')`],
      select: ['originalId', 'name', 'entityName', 'hurdleId']
    })

    if (!result) {
      throw new Error('An error occurred while fetching teams')
    }
    yield put(hurdleSearchFetchActions.success(result?.value || []))
  } catch (e: any) {
    yield put(hurdleSearchFetchActions.failure(e))
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

export const useHurdleSearchFetch = createAsyncHook(
  hurdleSearchFetchActions.request,
  selectors
)

export const hurdleSearchFetchSagas = [
  () => takeLatest(hurdleSearchFetchActions.request, onRequest)
]
