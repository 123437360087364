import { css } from '@emotion/react'
import {
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  Row,
  useReactTable
} from '@tanstack/react-table'
import { map, sum } from 'lodash'
import { PrintFontStyle } from 'modules/Advisory/modules/Rdot360/shared/PrintFontStyle'
import { PrintHeaderGlobal } from 'modules/Advisory/modules/Rdot360/shared/PrintHeaderGlobal'
import { ICategoryPosition } from 'modules/Advisory/modules/Rdot360/store/holdingsApi/ICategoryPositionResponse'
import {
  useGetPositionByCategoryQueryForSelectedAccounts,
  useRdot360AccountContext
} from 'modules/Advisory/modules/Rdot360/store/rdot360Context'
import { useMemo, useState } from 'react'
import { useMount } from 'react-use'
import { isNotNullOrUndefined } from 'shared/guards'
import { useInvestmentsDetailsUIState } from '../../InvestmentsDetailsUIState'
import { InvestmentsChart } from '../InvestmentsChart/InvestmentsChart'
import InvestmentsChartLegendDetails from '../InvestmentsChart/InvestmentsChartLegendDetails'
import {
  createDescriptionCell,
  getInvestmentsTableColumnDefs
} from '../InvestmentsTable/InvestmentsTableColumns'
import { investmentsTableColumnNames as colNames } from '../InvestmentsTable/shared'
import { useInvestmentsTableStore } from '../InvestmentsTable/store'
import { PrintDisclaimer } from './PrintDisclaimer'
import { PrintHeaderClient } from './PrintHeaderClient'
import { PrintTable } from './PrintTable'

const getClasses = () => ({
  body: css({
    fontFamily: '"Inter Variable", sans-serif',
    color: 'rgb(50, 49, 48)',
    WebkitFontSmoothing: 'antialiased',
    fontWeight: 400,
    '& mark': {
      backgroundColor: 'transparent!important',
      fontWeight: 'normal!important'
    },
    '@media screen': {
      maxWidth: '1600px',
      margin: '2rem auto',
      '& .header-global': {
        maxWidth: '1600px'
      },
      '& .small-logo-header': {
        display: 'none'
      }
    },
    '@media print': {
      margin: 0,
      '@page': {
        size: 'letter landscape'
      }
    }
  }),
  headerGlobal: css({
    borderBottom: '2px solid #676767',
    paddingBottom: '20px',
    width: '100%',
    backgroundColor: '#FFF'
  }),
  headerColumns: css({
    display: 'flex',
    justifyContent: 'space-between'
  }),
  headerClient: css({
    borderBottom: '1px solid #CDCDCD',
    marginBottom: '-70px'
  }),
  chartType: css({
    fontSize: '16px',
    padding: '50px 0 0 40px'
  }),
  chartAndLegendContainer: css({
    display: 'flex',
    border: 0,
    backgroundColor: '#FBFCFC',
    margin: '90px 0 -60px 0',
    width: '100%',
    maxHeight: 'fit-container'
  }),
  chartContainer: css({
    padding: '10px 100px 0 0'
  }),
  legendContainer: css({
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    alignContent: 'flex-start',
    columnGap: '100px',
    rowGap: '25px',
    flexWrap: 'wrap',
    maxHeight: 'fit-container',
    margin: '50px 0 0 0',
    minWidth: 0
  })
})

const empty: ICategoryPosition[] = []

interface IPrintHTMLProps {
  masked: boolean
  hideHousehold: boolean
  includeAltInvestmentDetails: boolean
  maxDepth: number
}

export const PrintHTML: React.FC<IPrintHTMLProps> = ({
  masked,
  hideHousehold,
  includeAltInvestmentDetails,
  maxDepth
}) => {
  const [expanded, setExpanded] = useState<ExpandedState>({})
  const classes = useMemo(() => getClasses(), [])
  const { accountLookupByAccountIdOrKey } = useRdot360AccountContext()

  const columns = useMemo(() => {
    const originalColumns = getInvestmentsTableColumnDefs(
      accountLookupByAccountIdOrKey
    )
    const PrintDescriptionCell = createDescriptionCell({
      forceMaskAccountNumber: masked,
      isPrint: true
    })

    const descriptionCell = originalColumns.find(
      ({ id }) => id === colNames.description
    )
    if (descriptionCell) {
      descriptionCell.cell = PrintDescriptionCell
      descriptionCell.aggregatedCell = PrintDescriptionCell
    }

    return originalColumns
  }, [accountLookupByAccountIdOrKey, masked])

  const { searchText } = useInvestmentsDetailsUIState()
  const { category, sorting, grouping, visibility } = useInvestmentsTableStore()
  const firstGroup = useMemo(() => {
    const [firstGroup] = grouping || []
    return firstGroup
  }, [grouping])
  const { accountNumber, productType, secid, sectorClass, assetClass } =
    colNames

  const currentTable: Record<string, boolean> = {}
  currentTable[assetClass] = firstGroup === assetClass
  currentTable[sectorClass] = firstGroup === sectorClass
  currentTable[productType] = firstGroup === productType
  currentTable[secid] = firstGroup === secid
  currentTable[accountNumber] = firstGroup === accountNumber

  const { data } = useGetPositionByCategoryQueryForSelectedAccounts(category)
  const positions = useMemo(
    () =>
      data
        ?.flatMap((x) => x.invposacct)
        .flatMap((x) => x?.invposlist)
        .filter(isNotNullOrUndefined) || empty,
    [data]
  )

  const expandRows: Record<string, boolean> = {}

  const expandRowsToDepth = (
    rows: Row<ICategoryPosition>[],
    depth: number,
    expandRows: Record<string, boolean>
  ) => {
    if (depth === 0) {
      return
    }
    rows.forEach((row) => {
      expandRows[row.id] = true
      const canExpand = row.getCanExpand()
      row.subRows &&
        canExpand &&
        expandRowsToDepth(row.subRows, depth - 1, expandRows)
    })
  }

  useMount(() => {
    expandRowsToDepth(rows, maxDepth, expandRows)
    setExpanded(expandRows)
  })

  const table = useReactTable({
    data: positions,
    columns,
    state: {
      sorting,
      grouping,
      expanded,
      columnVisibility: visibility,
      globalFilter: searchText
    },
    autoResetExpanded: false,
    manualPagination: true,
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel()
  })

  const sortedData = useMemo(() => {
    return data?.map((x) => x).sort((a: any, b: any) => b?.mktval - a?.mktval)
  }, [data])

  const showChart =
    grouping &&
    (currentTable[assetClass] ||
      currentTable[sectorClass] ||
      currentTable[productType])

  const getTotal = (id: string) => {
    const rows = table.getPreExpandedRowModel().rows
    const values = map(rows, (row) => row.getValue(id) as number)
    const total = sum(values)
    return total
  }
  const total = getTotal(colNames.value)
  const daysChange = getTotal(colNames.dayChange)

  const headers = table.getFlatHeaders()
  const rows = table.getPreExpandedRowModel().rows

  return (
    <html>
      <head>
        <meta charSet="UTF-8" />
        <title>&nbsp;</title>
        <script>
          {`
              window.onload = function() {
                setTimeout(() => {
                  window.print();
                }, 100)
              }
          `}
        </script>
        <PrintFontStyle />
      </head>
      <body css={classes.body}>
        <header css={classes.headerGlobal}>
          <PrintHeaderGlobal name="Investments" />
        </header>
        <header css={[classes.headerClient, classes.headerColumns]}>
          <PrintHeaderClient
            masked={masked}
            hideHousehold={hideHousehold}
            total={total}
            daysChange={daysChange}
            searchText={searchText}
          />
        </header>
        {showChart && (
          <div css={classes.chartAndLegendContainer}>
            <div css={classes.chartType}>{grouping[0]}</div>
            <div css={classes.chartContainer}>
              <InvestmentsChart data={data} selectedType={category} />
            </div>
            {sortedData && (
              <div css={classes.legendContainer}>
                {sortedData.map((item, index) => (
                  <InvestmentsChartLegendDetails
                    data={item}
                    key={index}
                    selectedType={category}
                  />
                ))}
              </div>
            )}
          </div>
        )}
        <PrintTable
          currentTable={currentTable}
          headers={headers}
          rows={rows}
          includeAltInvestmentDetails={includeAltInvestmentDetails}
        />
        <PrintDisclaimer />
      </body>
    </html>
  )
}
