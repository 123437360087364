import { createOdataListWithFacetsStore } from 'features/OdataList/store/odataListWithFacetsStore'
import { AppState } from '../../../../store'
import {
  ActivityColumnDefinitions,
  ActivityFilters
} from '../ColumnDefinitions'
import { fetchActivity } from './api'

const activityListStore = createOdataListWithFacetsStore({
  getOdataResults: fetchActivity,
  prefix: '@features/@lists/@activities',
  rootSelector: (state: AppState) => state.features.lists.activity.list,
  initialState: {
    data: {},
    facets: {},
    ui: {
      columns: ActivityColumnDefinitions,
      filters: ActivityFilters
    }
  }
})

export const { reducer, sagas, actions, selectors } = activityListStore
