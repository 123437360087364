import {
  DefaultButton,
  DirectionalHint,
  IContextualMenuItem,
  IContextualMenuProps
} from '@fluentui/react'
import { isEmpty } from 'lodash'
import { buttonStyles } from 'modules/Advisory/modules/Rdot360/components/shared/Buttons'
import { useMemo } from 'react'
import { useSecureMessagesState } from '../../shared/hooks'
import { SecureMessageFolder } from '../../shared/types'

export interface IRestoreButtonProps {
  disabled?: boolean
  onClick: (key: SecureMessageFolder) => void
}

export const RestoreButton: React.FC<IRestoreButtonProps> = ({
  disabled,
  onClick
}) => {
  const { rowSelection } = useSecureMessagesState()

  const menuProps: IContextualMenuProps = useMemo(
    () => ({
      directionalHint: DirectionalHint.bottomCenter,
      items: [
        { key: 'inbox', text: 'Inbox' },
        { key: 'sent', text: 'Sent Items' }
      ],
      onItemClick: (_: any, item?: IContextualMenuItem) => {
        if (!item) {
          return
        }
        onClick(item.key as SecureMessageFolder)
      },
      styles: {
        root: { minWidth: 100 }
      }
    }),
    [onClick]
  )

  return (
    <DefaultButton
      css={buttonStyles.secondary}
      disabled={disabled || isEmpty(rowSelection)}
      menuProps={menuProps}
      text="Restore"
      title="Restore selected"
    />
  )
}
