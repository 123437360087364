import { css } from '@emotion/react'
import { TableOptions, useReactTable } from '@tanstack/react-table'
import { PrintFontStyle } from 'modules/Advisory/modules/Rdot360/shared/PrintFontStyle'
import { PrintHeaderGlobal } from 'modules/Advisory/modules/Rdot360/shared/PrintHeaderGlobal'
import { IClosedLotExtended } from 'modules/Advisory/modules/Rdot360/store/types'
import { useMemo } from 'react'
import { GainLossDetailColumnNames } from '../../shared'
import { getColumnDefinition } from '../GainLossTable/getColumnDefinition'
import { PrintDisclaimer } from './PrintDisclaimer'
import { PrintHeaderClient } from './PrintHeaderClient'
import { PrintTable } from './PrintTable'

const getClasses = () => ({
  headerGlobal: css({
    borderBottom: '2px solid #676767',
    paddingBottom: '20px'
  }),
  headerClient: css({
    borderBottom: '1px solid #CDCDCD',
    marginBottom: '-70px',
    display: 'flex',
    justifyContent: 'space-between'
  }),
  body: css({
    fontFamily: '"Inter Variable", sans-serif',
    color: 'rgb(50, 49, 48)',
    WebkitFontSmoothing: 'antialiased',
    fontWeight: 400,
    '@media screen': {
      maxWidth: '1600px',
      margin: '2rem auto',
      '& .header-global': {
        maxWidth: '1600px'
      },
      '& .small-logo-header': {
        display: 'none'
      }
    },
    '@media print': {
      margin: 0,
      zoom: '60%',
      '@page': {
        size: 'letter landscape'
      }
    }
  })
})

interface IPrintHTMLProps {
  tableOptions: TableOptions<IClosedLotExtended>
  masked: boolean
  hideHousehold: boolean
  searchText: string
  netAmount: number
}

export const PrintHTML: React.FC<IPrintHTMLProps> = ({
  tableOptions,
  masked,
  hideHousehold,
  searchText,
  netAmount
}) => {
  const classes = useMemo(() => getClasses(), [])
  const grouping = useMemo(
    () => tableOptions?.state?.grouping || [],
    [tableOptions?.state?.grouping]
  )
  const isAccountL1 = useMemo(
    () => grouping[0] === GainLossDetailColumnNames.account,
    [grouping]
  )

  const columns = useMemo(
    () => getColumnDefinition(isAccountL1, masked),
    [isAccountL1, masked]
  )
  const table = useReactTable({ ...tableOptions, columns })
  const { rows } = table.getRowModel()
  const headers = table.getFlatHeaders()
  return (
    <html>
      <head>
        <meta charSet="UTF-8" />
        <title>&nbsp;</title>
        <script>
          {`
              window.onload = function() {
                setTimeout(() => {
                  window.print();
                }, 100)
              }
          `}
        </script>
        <PrintFontStyle />
      </head>
      <body css={classes.body}>
        <header css={classes.headerGlobal}>
          <PrintHeaderGlobal name="Realized Gains/Losses" />
        </header>
        <header css={classes.headerClient}>
          <PrintHeaderClient
            masked={masked}
            hideHousehold={hideHousehold}
            searchText={searchText}
            netAmount={netAmount}
          />
        </header>
        <PrintTable headers={headers} rows={rows} />
        <PrintDisclaimer />
      </body>
    </html>
  )
}
