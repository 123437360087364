import { css } from '@emotion/react'
import { USDCell } from 'features/Lists/core/components/USDCell'
import { useGainLossesStore } from 'modules/Advisory/modules/Rdot360/features/GainLosses/gainLossesStore'
import { getCroppedAccountList } from 'modules/Advisory/modules/Rdot360/shared/utilities'
import { useMemo } from 'react'
import { FormattedDate } from 'react-intl'
import {
  useRdot360AccountContext,
  useRdot360Context,
  useRdot360HouseholdContext
} from '../../../../store/rdot360Context'

const getClasses = () => ({
  headerLeft: css({
    textAlign: 'left',
    width: '60%'
  }),
  headerRight: css({
    textAlign: 'right',
    width: '40%'
  }),
  headerColumn: css({
    div: css({
      padding: '5px 0'
    }),
    label: css({
      color: '#676767'
    })
  })
})

interface IPrintHeaderClientProps {
  masked: boolean
  hideHousehold: boolean
  netAmount: number
  searchText: string
}

export const PrintHeaderClient: React.FC<IPrintHeaderClientProps> = ({
  masked,
  hideHousehold,
  netAmount,
  searchText
}) => {
  const context = useRdot360HouseholdContext()
  const { selectedAccounts } = useRdot360AccountContext()
  const accountNumbers = selectedAccounts.map(
    (account) => account.CustodyAccount || ''
  )
  const accounts = getCroppedAccountList(accountNumbers, masked, 270, 4)
  const classes = useMemo(() => getClasses(), [])
  const { asOfDate } = useRdot360Context()
  const { selectedFinancialYear, isTaxableAccounts } = useGainLossesStore()
  return (
    <>
      <div css={[classes.headerColumn, classes.headerLeft]}>
        {!hideHousehold && (
          <div>
            <strong>{context.household?.householdName}</strong>
          </div>
        )}
        <div>
          <label>Selected Account(s):</label> {accounts}
        </div>
      </div>
      <div css={[classes.headerColumn, classes.headerRight]}>
        <div>
          <label>Account Type:</label>{' '}
          <span>{isTaxableAccounts ? 'Taxable Account' : 'All'}</span>
          {' | '}
          <label>Year:</label> <span>{selectedFinancialYear}</span>
          {' | '}
          <label>Report As Of:</label>{' '}
          <FormattedDate
            value={asOfDate}
            day="numeric"
            month="numeric"
            year="numeric"
          />
        </div>
        <div>
          <label>Total Gain/Loss:</label>{' '}
          <USDCell currencySign="standard" value={netAmount} />
        </div>
        {searchText && (
          <div>
            <label>Filtered by:</label> {searchText}
          </div>
        )}
      </div>
    </>
  )
}
