import {
  flexRender,
  getCoreRowModel,
  getGroupedRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  Row,
  GroupingState,
  VisibilityState,
  ExpandedState
} from '@tanstack/react-table'
import { sum } from 'lodash'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { HorizontalScrollContainer } from 'shared/components/HorizontalScrollContainer'
import { useDebounce } from 'shared/hooks/useDebounce'
import { useWidthObserver } from 'shared/hooks/useResizeObserver'
import { Icon } from '../../features/Icons/Icon'
import { useBalanceSummaryDetailsUiState } from '../../modules/Balances/balanceSummaryDetailsUIState'
import { SortIndicator } from '../../shared/SortIndicator'
import {
  rdot360TableStyles,
  useRdot360ThemedTableStyles
} from '../../shared/tableStyles'
import { useRdot360CagsContext } from '../../store/rdot360Context/useRdot360CagsContext'
import { IndeterminateProgressIndicator } from '../shared/ProgressIndicator/IndeterminateProgressIndicator'
import {
  BalanceItem,
  getBalancesDetailTableColumns
} from './BalanceSummaryTableColumns'
import { balancesDetailTableColumnNames } from './shared'
import { cellStyles } from './styles'
import { useBalancesData } from './useBalancesData'

const visibility: VisibilityState = {
  [balancesDetailTableColumnNames.clientDashboardCategory]: false,
  [balancesDetailTableColumnNames.clientDashboardCategoryCode]: false,
  [balancesDetailTableColumnNames.custodianName]: false,
  [balancesDetailTableColumnNames.taxable]: false,
  [balancesDetailTableColumnNames.legalEntityId]: false,
  [balancesDetailTableColumnNames.accountRegistration]: false,
  [balancesDetailTableColumnNames.ausClass]: false,
  [balancesDetailTableColumnNames.cag]: false
}

export const BalanceSummaryTable: FC<{
  viewType?: string
  dataWithCustodian: BalanceItem[]
  isFetching: boolean
}> = ({ viewType, dataWithCustodian, isFetching }) => {
  const { searchText, setSearchText, sorting, setSorting } =
    useBalanceSummaryDetailsUiState()
  const debouncedSearchText = useDebounce(searchText, 100)
  const columns = useMemo(getBalancesDetailTableColumns, [])
  const [expanded, setExpanded] = useState<ExpandedState>({})
  const [grouping, setGrouping] = useState<GroupingState>([
    balancesDetailTableColumnNames.clientDashboardCategory
  ])
  const skipCag = viewType !== balancesDetailTableColumnNames.cag
  const { isFetching: isCagFetching } = useRdot360CagsContext(!skipCag)
  const { cagData } = useBalancesData(skipCag)
  useEffect(() => {
    if (!viewType) {
      return
    }

    setGrouping([viewType])
  }, [viewType])

  const table = useReactTable({
    data: skipCag ? dataWithCustodian : cagData,
    columns,
    state: {
      sorting,
      grouping,
      expanded,
      columnVisibility: visibility,
      globalFilter: debouncedSearchText
    },
    autoResetExpanded: false,
    onGlobalFilterChange: setSearchText,
    onSortingChange: setSorting,
    onExpandedChange: setExpanded,
    onGroupingChange: setGrouping,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel()
  })

  useEffect(() => {
    if (!dataWithCustodian.length) {
      return
    }
    table.toggleAllRowsExpanded(true)
  }, [table, dataWithCustodian])

  const headers = table.getFlatHeaders()
  const tableRefContainer = useRef<HTMLDivElement>(null)
  const containerWidth = useWidthObserver(tableRefContainer)
  const tableWidth = Math.max(1500, containerWidth || 0)

  const getHeaderSizes = useCallback(
    (depth?: number) => {
      const sizes = getAdjustedHeaderSizes(
        headers.map((x) => ({ id: x.id || '', width: x.getSize() || 0 })),
        // -2 for the border around the table
        tableWidth - 2,
        depth
      )
      return sizes
    },
    [headers, tableWidth]
  )

  const lvl0Sizes = useMemo(() => getHeaderSizes(), [getHeaderSizes])
  const rows = table.getPreExpandedRowModel().rows
  const themedStyles = useRdot360ThemedTableStyles()

  return (
    <>
      <a
        onClick={(e) => {
          table.toggleAllRowsExpanded()
          e.preventDefault()
        }}
        href="#"
        css={{
          color: '#4C9DA8',
          cursor: 'pointer',
          marginBottom: 16
        }}
      >
        {table.getIsAllRowsExpanded() ? 'Collapse All' : 'Expand All'}
      </a>
      <div ref={tableRefContainer}>
        {(isFetching || isCagFetching) && <IndeterminateProgressIndicator />}
        <HorizontalScrollContainer>
          <div
            css={[
              rdot360TableStyles.headerContainer,
              themedStyles.headerContainer
            ]}
          >
            <table css={[rdot360TableStyles.table]}>
              <thead>
                <SizesRow sizes={lvl0Sizes} />
                <tr css={[rdot360TableStyles.headerRow]}>
                  {headers.map((header) => {
                    return (
                      <th
                        key={header.id}
                        css={[cellStyles[header.column.id]]}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        <div
                          css={[{ display: 'inline-flex', cursor: 'pointer' }]}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          <SortIndicator
                            direction={header.column.getIsSorted()}
                          />
                        </div>
                      </th>
                    )
                  })}
                </tr>
              </thead>
              {skipCag && (
                <tfoot>
                  <tr
                    css={[
                      rdot360TableStyles.bodyRow,
                      rdot360TableStyles.l2GroupRow,
                      themedStyles.totalRow
                    ]}
                  >
                    {headers.map((header) => (
                      <td key={header.id} css={[cellStyles[header.column.id]]}>
                        {header.column.columnDef.footer
                          ? flexRender(
                              header.column.columnDef.footer,
                              header.getContext()
                            )
                          : null}
                      </td>
                    ))}
                  </tr>
                </tfoot>
              )}
            </table>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: 3 }}>
            {rows.map((row) => {
              const subRows = row.subRows
              return (
                <div key={row.id}>
                  <table css={[rdot360TableStyles.table]}>
                    <thead>
                      <SizesRow sizes={lvl0Sizes} />
                    </thead>
                    <tbody css={{ marginBottom: 20 }}>
                      <BalancesTableGroupRow row={row} />
                      {row.getIsExpanded() &&
                        subRows.map((subRow) => (
                          <BalancesTableRow key={subRow.id} row={subRow} />
                        ))}
                    </tbody>
                  </table>
                </div>
              )
            })}
          </div>
        </HorizontalScrollContainer>
      </div>
    </>
  )
}

const BalancesTableRow: React.FC<{ row: Row<BalanceItem> }> = ({ row }) => {
  const cells = row.getVisibleCells()
  const themedStyles = useRdot360ThemedTableStyles()

  return (
    <tr
      css={[
        rdot360TableStyles.bodyRow,
        themedStyles.bodyRow,
        themedStyles.securityRow
      ]}
    >
      {cells.map((cell) => {
        return (
          <td key={cell.id} css={[cellStyles[cell.column.id]]}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        )
      })}
    </tr>
  )
}

const BalancesTableGroupRow: React.FC<{ row: Row<BalanceItem> }> = ({
  row
}) => {
  const cells = row.getVisibleCells()
  const themedStyles = useRdot360ThemedTableStyles()
  const isL1GroupRow = row.depth === 0
  const isL2GroupRow = row.depth > 0
  const isGroupRow = isL1GroupRow || isL2GroupRow
  const isExpanded = row.getIsExpanded()

  return (
    <tr
      css={[
        rdot360TableStyles.bodyRow,
        themedStyles.bodyRow,
        isGroupRow && themedStyles.groupRow,
        isL1GroupRow && themedStyles.l1GroupRow,
        isL1GroupRow && rdot360TableStyles.l1GroupRow,
        isL2GroupRow && themedStyles.l2GroupRow,
        isL2GroupRow && rdot360TableStyles.l2GroupRow,
        isExpanded && themedStyles.groupRowExpanded
      ]}
    >
      {cells.map((cell, i) => {
        return (
          <td key={cell.id} css={[cellStyles[cell.column.id]]}>
            {!!row.groupingValue && i === 0 && (
              <div
                key={row.id}
                css={{
                  cursor: 'pointer',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  display: 'flex',
                  minWidth: 0
                }}
                onClick={row.getToggleExpandedHandler()}
              >
                <div css={{ width: 12, height: 12 }}>
                  <Icon
                    type={row.getIsExpanded() ? 'Subtract' : 'Add'}
                    width={12}
                    height={12}
                  />
                </div>

                <span
                  style={{
                    marginLeft: 10,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                  }}
                  title={row.groupingValue as string}
                >
                  {row.groupingValue as string}
                </span>
              </div>
            )}
            {i > 0 &&
              flexRender(
                cell.column.columnDef.aggregatedCell,
                cell.getContext()
              )}
          </td>
        )
      })}
    </tr>
  )
}

const getAdjustedHeaderSizes = (
  sizes: ITableHeaderColumnSize[],
  tableWidth: number,
  depth = 0,
  firstColumnOffset = 10,
  lastColumnOffset = 10
) => {
  const totalSize = sum(sizes.map(({ width }) => width))
  const ratio = tableWidth ? tableWidth / totalSize : 1

  return sizes.map(({ width, id }, i) => {
    const isFirst = i === 0
    const isLast = i === sizes.length - 1
    const depthAdjustment =
      isFirst || isLast
        ? depth * (isFirst ? firstColumnOffset : lastColumnOffset)
        : 0

    return { id, width: width * ratio - depthAdjustment }
  })
}
export interface ITableHeaderColumnSize {
  id: string
  width: number
}

const SizesRow: React.FC<{
  sizes: ITableHeaderColumnSize[]
}> = ({ sizes }) => {
  return (
    <tr css={[rdot360TableStyles.sizesRow]}>
      {sizes.map(({ width, id }) => {
        return (
          <th
            key={id}
            style={{
              width,
              maxWidth: width
            }}
          />
        )
      })}
    </tr>
  )
}
