import { IModuleDefinition } from 'modules/types'
import { combineReducers } from 'redux'
import { LoansContainer } from './LoansContainer'
import { rdot360MarginLoansReducer } from './MarginLoans/store'
import { rdot360ThirdPartyLoansReducer } from './ThirdPartyLoans/store'

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/loans/*',
    private: true,
    component: LoansContainer
  },
  name: 'Loans'
}

export const rootRdot360LoansReducer = combineReducers({
  thirdPartyLoansUiState: rdot360ThirdPartyLoansReducer,
  marginLoansUiState: rdot360MarginLoansReducer
})

export default moduleDefinition
