import { cloneDeep, flow } from 'lodash'
import { put, race, take, takeLatest } from 'typed-redux-saga'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { IHurdle } from '../../../../../../../../../api/datahub'
import { AppState } from '../../../../../../../../../store'
import { hurdleEditPanelActions } from './hurdleEditPanel'
import { hurdleFetchActions } from './hurdleFetch'

const UPDATE_HURDLE = '@modules/@teams/@hurdleEditForm/UPDATE_HURDLE'
const RESET = '@modules/@teams/@hurdleEditForm/RESET'
const SET_HURDLE = '@modules/@teams/@hurdleEditForm/SET_HURDLE'

export const hurdleEditFormActions = {
  updateHurdle: createAction(UPDATE_HURDLE)<IHurdle>(),
  setHurdle: createAction(SET_HURDLE)<IHurdle>(),
  reset: createAction(RESET)()
}

export type HurdleEditFormActionTypes = ActionType<typeof hurdleEditFormActions>

export interface IHurdleEditFormState {
  hurdle: IHurdle
}

const defaultHurdle: IHurdle = {
  measurements: [
    {
      intervalOfMeasurement: 'Annual',
      metrics: [
        {
          metricType: 'T-12 From Hurdle',
          payouts: []
        }
      ]
    }
  ],
  entityType: 'Team',
  completionType: 'OneAndDone'
}

const initialState: IHurdleEditFormState = {
  hurdle: cloneDeep(defaultHurdle)
}

export const hurdleEditFormReducer = createReducer<
  IHurdleEditFormState,
  HurdleEditFormActionTypes
>(initialState)
  .handleAction(hurdleEditFormActions.setHurdle, (state, action) => ({
    ...state,
    hurdle: action.payload
  }))
  .handleAction(hurdleEditFormActions.reset, () => ({
    ...initialState,
    hurdle: cloneDeep(defaultHurdle)
  }))

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.teams.modules.hurdles.features.hurdleEdit
    .hurdleEditForm

export const getHurdleGroup = flow(rootSelector, ({ hurdle }) => hurdle)

const onUpdateHurdle = function* (
  action: ReturnType<typeof hurdleEditFormActions.updateHurdle>
) {
  if (!action.payload?.hurdleId) {
    yield put(hurdleEditFormActions.setHurdle(cloneDeep(action.payload)))
    return
  }
  yield put(hurdleFetchActions.request(action.payload.hurdleId))
  const { fetchSuccess, fetchFailure } = yield* race({
    fetchSuccess: take(hurdleFetchActions.success),
    fetchFailure: take(hurdleFetchActions.failure)
  })
  if (fetchFailure) {
    yield put(hurdleEditPanelActions.failure(fetchFailure.payload))
    return
  }
  yield put(
    hurdleEditFormActions.setHurdle(
      cloneDeep(fetchSuccess?.payload?.[0]) || cloneDeep(defaultHurdle)
    )
  )
}

export const hurdleEditFormSagas = [
  () => takeLatest(hurdleEditFormActions.updateHurdle, onUpdateHurdle)
]
