import { IconButton } from '@fluentui/react'
import { styles } from '../../shared/styles'

export interface IDeleteButtonProps {
  onClick: () => void
}

export const DeleteButton: React.FC<IDeleteButtonProps> = ({ onClick }) => {
  return (
    <div style={styles.buttonContainer}>
      <IconButton
        iconProps={{
          iconName: 'Trash',
          style: styles.icon
        }}
        onClick={onClick}
        title="Delete message"
      />
    </div>
  )
}
