import { css } from '@emotion/react'

export const getHeaderStyles = () => ({
  container: css({
    display: 'flex',
    flexDirection: 'column'
  }),
  headerContainer: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    flexWrap: 'wrap'
  }),

  tabs: css({
    paddingLeft: 50,
    paddingBottom: 5,
    width: 300,
    'span:first-of-type': {
      marginRight: 25
    },
    button: {
      color: 'black!important',
      textDecoration: 'none!important'
    },
    '& .selected': {
      fontWeight: 'bold',
      borderBottom: '3px solid black'
    }
  }),
  tab: css({
    color: 'black',
    padding: '0 6px 6px 6px',
    borderBottom: '3px solid transparent',
    fontSize: 17
  }),
  leftContainer: css({
    display: 'flex',
    alignItems: 'flex-end'
  }),
  filterContainer: css({
    display: 'flex',
    flexGrow: 1,
    columnGap: 16,
    justifyContent: 'space-between',
    alignItems: 'flex-end'
  })
})
