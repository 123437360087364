import { Pivot, PivotItem } from '@fluentui/react'
import { useCallback } from 'react'
import {
  useSecureMessagesCount,
  useSecureMessagesState
} from '../../shared/hooks'
import { secureMessageFolders, SecureMessageView } from '../../shared/types'

export const FolderPivot: React.FC = () => {
  const { getFolderCount } = useSecureMessagesCount()
  const { currentView, setCurrentView } = useSecureMessagesState()

  const handleSelectedFolderChanged = useCallback(
    (item?: PivotItem) => {
      const newSelectedFolder = item?.props?.itemKey
      if (!newSelectedFolder) {
        return
      }
      setCurrentView(newSelectedFolder as SecureMessageView)
    },
    [setCurrentView]
  )

  return (
    <Pivot selectedKey={currentView} onLinkClick={handleSelectedFolderChanged}>
      {secureMessageFolders.map(({ name, key }) => (
        <PivotItem
          key={key}
          headerText={name}
          itemKey={key}
          itemCount={getFolderCount(name)}
        />
      ))}
    </Pivot>
  )
}
