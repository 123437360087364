export type CKEditorImport = typeof import('ckeditor4-react')
let ckEditorPromise: Promise<CKEditorImport> | undefined
let ckEditor: CKEditorImport | undefined

const loadCKEditor = async () => {
  ckEditor = await import('ckeditor4-react')
  return ckEditor
}

export const getCKEditor = async () => {
  if (!ckEditorPromise) {
    ckEditorPromise = loadCKEditor()
  }

  return ckEditorPromise
}

export { ckEditor }
