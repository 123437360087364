import { css } from '@emotion/react'
import { useCallback } from 'react'
import { EditMessageContainer } from './modules/EditMessage/EditMessageContainer'
import { MessagesContainer } from './modules/Messages/MessagesContainer'
import { NewMessageContainer } from './modules/NewMessage/NewMessageContainer'
import { ReadMessageContainer } from './modules/ReadMessage/ReadMessageContainer'
import { useSecureMessagesState } from './shared/hooks'

const styles = {
  container: css({
    paddingTop: '10px'
  })
}

export const SecureMessageContainer: React.FC = () => {
  const { currentView } = useSecureMessagesState()

  const CurrentViewContainer = useCallback(() => {
    switch (currentView) {
      case 'edit':
        return <EditMessageContainer />
      case 'new':
        return <NewMessageContainer />
      case 'read':
        return <ReadMessageContainer />
      default:
        return <MessagesContainer />
    }
  }, [currentView])

  return (
    <div css={styles.container}>
      <CurrentViewContainer />
    </div>
  )
}
