import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RowSelectionState } from '@tanstack/react-table'
import { rdot360ContextActions } from 'modules/Advisory/modules/Rdot360/store/rdot360Context'
import { AppState } from 'store'
import { IGraphMessage, SecureMessageView } from '../types'

export interface ISecureMessagesDateRange {
  dateRange: string
  endDate?: Date
  startDate?: Date
}

export interface ISecureMessagesState extends ISecureMessagesDateRange {
  currentView: SecureMessageView
  previousView?: SecureMessageView
  rowSelection: RowSelectionState
  searchText: string
  selectedMessage?: IGraphMessage
  showAttach: boolean
  showReply: boolean
}

const initialState: ISecureMessagesState = {
  currentView: 'inbox',
  dateRange: 'all',
  rowSelection: {},
  searchText: '',
  showAttach: false,
  showReply: false
}

export const {
  actions: secureMessagesActions,
  reducer: secureMessagesReducer
} = createSlice({
  name: '@modules/@advisory/@modules/@rdot360/@modules/@secureMessages',
  initialState,
  reducers: {
    updateCurrentView: (state, action: PayloadAction<SecureMessageView>) => {
      if (
        state.currentView !== 'edit' &&
        state.currentView !== 'new' &&
        state.currentView !== 'read'
      ) {
        state.previousView = state.currentView
      }
      state.currentView = action.payload
      state.rowSelection = {}
    },
    updateDateRange: (
      state,
      action: PayloadAction<ISecureMessagesDateRange>
    ) => {
      state.dateRange = action.payload.dateRange
      state.endDate = action.payload.endDate
      state.startDate = action.payload.startDate
      state.rowSelection = {}
    },
    updateEndDate: (state, action: PayloadAction<Date | undefined>) => {
      state.endDate = action.payload
      state.rowSelection = {}
    },
    updateRowSelection: (state, action: PayloadAction<RowSelectionState>) => {
      state.rowSelection = action.payload
    },
    updateSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload
    },
    updateSelectedMessage: (state, action: PayloadAction<IGraphMessage>) => {
      state.selectedMessage = action.payload
    },
    updateShowAttach: (state, action: PayloadAction<boolean>) => {
      state.showAttach = action.payload
    },
    updateShowReply: (state, action: PayloadAction<boolean>) => {
      state.showReply = action.payload
    },
    updateStartDate: (state, action: PayloadAction<Date | undefined>) => {
      state.startDate = action.payload
      state.rowSelection = {}
    }
  },
  extraReducers: (builder) => {
    builder.addCase(rdot360ContextActions.setSelectedHouseholdId, (state) => {
      state.currentView = 'inbox'
      state.dateRange = 'all'
      state.endDate = undefined
      state.rowSelection = {}
      state.searchText = ''
      state.selectedMessage = undefined
      state.showAttach = false
      state.showReply = false
      state.startDate = undefined
    })
  }
})

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.rdot360.modules.communications.secureMessages

export const selectCurrentView = createSelector(
  rootSelector,
  ({ currentView }) => currentView
)

export const selectDateRange = createSelector(
  rootSelector,
  ({ dateRange }) => dateRange
)

export const selectEndDate = createSelector(
  rootSelector,
  ({ endDate }) => endDate
)

export const selectPreviousView = createSelector(
  rootSelector,
  ({ previousView }) => previousView
)

export const selectRowSelection = createSelector(
  rootSelector,
  ({ rowSelection }) => rowSelection
)

export const selectSearchText = createSelector(
  rootSelector,
  ({ searchText }) => searchText
)

export const selectSelectedMessage = createSelector(
  rootSelector,
  ({ selectedMessage }) => selectedMessage
)

export const selectShowAttach = createSelector(
  rootSelector,
  ({ showAttach }) => showAttach
)

export const selectShowReply = createSelector(
  rootSelector,
  ({ showReply }) => showReply
)

export const selectStartDate = createSelector(
  rootSelector,
  ({ startDate }) => startDate
)
