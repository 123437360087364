import {
  MessageBar,
  MessageBarType,
  Panel,
  PanelType,
  Text
} from '@fluentui/react'
import { buttonStyles } from 'modules/Advisory/modules/Rdot360/components/shared/Buttons'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDebounce } from 'react-use'
import { CKEditorComponent } from 'shared/ckEditor'
import { LoadingComponent } from 'shared/components/Loading'
import { ckEditorConfig } from '../../shared/constants'
import {
  useSecureMessagesSession,
  useSecureMessagesState
} from '../../shared/hooks'
import {
  useReplyEmailMessageAllMutation,
  useSecureMessagesGraphApiUtil
} from '../../shared/store'
import { IGraphMessage } from '../../shared/types'

export const ReplyAllPanel: React.FC<{ message?: IGraphMessage }> = ({
  message
}) => {
  const { invalidateTags } = useSecureMessagesGraphApiUtil()
  const { replies, updateReplies } = useSecureMessagesSession()
  const { showReply, setShowReply } = useSecureMessagesState()
  const [replyAll, { error, isLoading, isSuccess }] =
    useReplyEmailMessageAllMutation()

  const messageId = useMemo(() => message?.id, [message])
  const messageBody = useMemo(() => {
    if (!messageId || !replies) {
      return ''
    }

    return replies[messageId]
  }, [messageId, replies])

  const [body, setBody] = useState<string>(messageBody)

  const handleDismiss = useCallback(() => setShowReply(false), [setShowReply])
  const handleSend = useCallback(() => {
    if (!messageId || !body) {
      return
    }
    replyAll({ comment: body, messageId })
  }, [body, messageId, replyAll])

  useDebounce(
    () => messageId && updateReplies({ [messageId]: body ?? '' }),
    1000,
    [body]
  )

  useEffect(() => {
    if (isSuccess) {
      invalidateTags([{ type: 'messages', id: 'sentitems' }])
      setBody('')
      setShowReply(false)
    }
  }, [invalidateTags, isSuccess, setShowReply])

  if (!showReply || !message) {
    return null
  }

  const replyAllError = error as Error | undefined

  return (
    <Panel
      headerText="Reply to All"
      isLightDismiss={false}
      isOpen={true}
      onDismiss={handleDismiss}
      type={PanelType.custom}
      customWidth="780px"
    >
      <Text variant="small">{message.subject}</Text>
      <div style={{ marginTop: 10 }}>
        <CKEditorComponent
          initData={body}
          config={ckEditorConfig}
          disabled={isLoading}
          onChange={(payload: any) => setBody(payload.editor.getData())}
        />
      </div>
      {replyAllError && (
        <div style={{ marginTop: 10 }}>
          <MessageBar messageBarType={MessageBarType.error}>
            An error occurred while submitting the request
            {replyAllError.message ? `: ${replyAllError.message}` : ''}
          </MessageBar>
        </div>
      )}
      <div
        css={{
          display: 'flex',
          gridColumnGap: 15,
          justifyContent: 'flex-end',
          marginTop: 10
        }}
      >
        <button
          css={buttonStyles.secondary}
          onClick={handleDismiss}
          type="button"
        >
          Cancel
        </button>
        <button
          css={buttonStyles.primary}
          disabled={!body || isLoading}
          onClick={handleSend}
          type="button"
        >
          {isLoading ? <LoadingComponent /> : 'Send'}
        </button>
      </div>
    </Panel>
  )
}
