import {
  DirectionalHint,
  HoverCard,
  HoverCardType,
  IHoverCard
} from '@fluentui/react'
import { detailTable } from 'modules/Advisory/modules/Rdot360/components/shared/DataTable/DetailTable'
import { HighlightSearchText } from 'modules/Advisory/modules/Rdot360/components/shared/DetailTables/HighlightSearchText'
import { IActivity } from 'modules/Advisory/modules/Rdot360/store/types'
import { Icon } from 'modules/ExternalManagers/features/Icons/Icon'
import { useCallback, useMemo, useRef, useState } from 'react'
import { useRdotUser } from 'store/user/useRdotUser'
import { ActivityEditTooltipContent } from './ActivityEditTooltip'

interface DescriptionCellProps {
  row: IActivity
  description?: string
  searchText?: string
}

const validEditTypes = [
  'Withdrawals',
  'Deposits / Contributions',
  'Exchanges / Transfers',
  'Fees & Interest Charged',
  'Fees & Interest'
]
export const DescriptionCell: React.FC<DescriptionCellProps> = ({
  description,
  row,
  searchText
}) => {
  const hoverCard = useRef<IHoverCard>(null)

  const [iconClicked, setIconClicked] = useState(false)

  const { isOLTUser, isPAUser, isCAUser } = useRdotUser()
  const instantDismissCard = useCallback(() => {
    if (hoverCard.current) {
      hoverCard.current.dismiss()
    }
    setIconClicked(false)
  }, [])
  const showEdit = useMemo(() => {
    return (
      (isOLTUser || isPAUser || isCAUser) &&
      row.SequenceNo != null &&
      validEditTypes.some((x) => x === row.trntype)
    )
  }, [row.trntype, row.SequenceNo, isOLTUser, isPAUser, isCAUser])

  return (
    <div css={{ position: 'relative' }}>
      <div
        css={[
          detailTable.ellipsis,
          {
            display: '-webkit-box',
            whiteSpace: 'normal',
            '-webkit-line-clamp': '2',
            '-webkit-box-orient': 'vertical'
          }
        ]}
        title={description}
      >
        <HighlightSearchText text={description || ''} search={searchText} />
      </div>

      {showEdit && (
        <div
          css={{
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            right: 0,
            zIndex: 1
          }}
        >
          <HoverCard
            plainCardProps={{
              onRenderPlainCard: () => (
                <ActivityEditTooltipContent
                  accountNumber={row.acct}
                  sequenceNo={row.SequenceNo}
                  dismissCard={instantDismissCard}
                  origDescription={description || ''}
                />
              ),
              directionalHint: DirectionalHint.bottomRightEdge,
              calloutProps: {
                isBeakVisible: true
              }
            }}
            instantOpenOnClick={true}
            shouldBlockHoverCard={() => true}
            type={HoverCardType.plain}
            componentRef={hoverCard}
            sticky={true}
            onCardHide={instantDismissCard}
          >
            <div
              className={iconClicked ? 'clicked' : 'onlyOnHover'}
              css={{
                cursor: 'pointer',
                padding: '5px',
                background: 'white',
                border: '1px solid #0073e633'
              }}
              onClick={() => setIconClicked(true)}
            >
              <Icon
                type="Edit"
                height={20}
                width={20}
                color="#0073e6"
                title="Edit Description"
              />
            </div>
          </HoverCard>
        </div>
      )}
    </div>
  )
}
