import { Theme, css, useTheme } from '@emotion/react'
import {
  DirectionalHint,
  HoverCard,
  HoverCardType,
  IPlainCardProps
} from '@fluentui/react'
import { fromPairs, sum } from 'lodash'
import React, { FC, memo, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useInvestmentsTableStore } from '../../modules/Investments/features/InvestmentsTable'
import { useInvestmentsDetailsUIState } from '../../modules/Investments/InvestmentsDetailsUIState'
import {
  IBalanceTodaysChangeItem,
  todaysChangeFields,
  todaysChangeFieldsDisplay
} from '../../store/balancesApi/IBalanceDetailResponse'
import { USDCellWithColor } from '../USDCellWithColor'
import { useNavigateTo } from './useTodaysChangeRedirections'

const getDayChangeTooltipClasses = (theme: Theme) => ({
  tooltipContainer: css({
    padding: 10,
    minWidth: 340
  }),
  headerLabel: css({ fontSize: 14, fontWeight: 700, marginBottom: 4 }),
  changeRowContainer: css({
    '&:nth-of-type(even) div': {
      backgroundColor: '#F9FBFC'
    },
    '&:nth-of-type(odd) div': {
      backgroundColor: theme.colors.primaryWhite
    },
    '&:last-of-type div': {
      backgroundColor: '#CDE2F2'
    }
  }),
  changeRow: css({
    display: 'flex',
    padding: '8px 8px',
    justifyContent: 'space-between'
  }),
  changeContainer: css({
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  }),
  activityContainer: css({
    fontWeight: 'bold'
  }),
  activitySubRow: css({
    paddingLeft: 15
  }),
  activityRowContainer: css({
    '&:nth-of-type(odd) div': {
      backgroundColor: '#F9FBFC'
    },
    '&:nth-of-type(even) div': {
      backgroundColor: theme.colors.primaryWhite
    }
  })
})

const ChangeAmountField: FC<{ value?: number; onClick?: () => void }> = ({
  value,
  onClick
}) => {
  return (
    <div
      css={[
        !!onClick && {
          '& *:hover': {
            textDecoration: 'underline',
            cursor: 'pointer'
          }
        }
      ]}
      onClick={onClick}
    >
      {value !== undefined && value !== null ? (
        <USDCellWithColor value={value} fractionDigit={2} />
      ) : (
        '0'
      )}
    </div>
  )
}

const DayChanges: FC<{
  data?: IBalanceTodaysChangeItem[]
  accountNumber?: string
  totalNonNfsValueChange?: number
  totalaccountvaluechange?: number
  totalotherassetsvaluechange?: number
  totalexternalaccountvaluechange?: number
  displayOtherChanges?: boolean
}> = memo(
  ({
    data = [],
    accountNumber,
    totalNonNfsValueChange,
    totalaccountvaluechange,
    totalotherassetsvaluechange,
    totalexternalaccountvaluechange,
    displayOtherChanges
  }) => {
    const theme = useTheme()
    const classes = useMemo(() => getDayChangeTooltipClasses(theme), [theme])

    const todaysChangeDataPairs = useMemo(() => {
      return data.map((item) => [item.category, item.amount])
    }, [data])

    const todaysChangeData = fromPairs(todaysChangeDataPairs)

    const activityCategories = useMemo(
      () => [
        todaysChangeFields.deposits,
        todaysChangeFields.withdrawals,
        todaysChangeFields.interestOrDividends,
        todaysChangeFields.other
      ],
      []
    )

    const activityTotal = useMemo(() => {
      return sum(
        activityCategories.map((category) => todaysChangeData[category] || 0)
      )
    }, [activityCategories, todaysChangeData])

    const total = useMemo(() => {
      return sum(data.map((item) => item.amount || 0))
    }, [data])

    const navigate = useNavigate()
    const { setSearchText } = useInvestmentsDetailsUIState()
    const { setInvestmentsViewByKey } = useInvestmentsTableStore()

    const navigateToInvestment = () => {
      setSearchText('')
      setInvestmentsViewByKey()
      navigate('./investments')
    }

    const showNav = !accountNumber ? true : undefined
    const { handleActivityNavigation } = useNavigateTo()

    return (
      <div css={classes.tooltipContainer}>
        <div css={classes.headerLabel}>{"Day's Change"}</div>
        <div css={classes.changeContainer}>
          <div css={[classes.changeRowContainer, classes.activityContainer]}>
            <div css={classes.changeRow}>
              <div>Gain/Losses</div>
              <ChangeAmountField
                value={todaysChangeData[todaysChangeFields.gainOrLoss]}
                onClick={showNav && navigateToInvestment}
              />
            </div>
          </div>
          <div css={[classes.changeRowContainer, classes.activityContainer]}>
            <div css={classes.changeRow}>
              <div>Accrued Income (Div/Int)</div>
              <ChangeAmountField
                value={todaysChangeData[todaysChangeFields.accruedIncome]}
                onClick={showNav && navigateToInvestment}
              />
            </div>
          </div>
          <div css={classes.activityContainer}>
            <div css={classes.changeRow}>
              <div>Activity</div>
              <ChangeAmountField
                value={activityTotal}
                onClick={
                  showNav ? () => handleActivityNavigation('') : undefined
                }
              />
            </div>
          </div>
          <div>
            {activityCategories.map((category) => (
              <div key={category} css={[classes.activityRowContainer]}>
                <div css={[classes.changeRow, classes.activitySubRow]}>
                  <div>{todaysChangeFieldsDisplay[category]}</div>
                  <ChangeAmountField
                    value={todaysChangeData[category]}
                    onClick={
                      showNav
                        ? () => handleActivityNavigation(category)
                        : undefined
                    }
                  />
                </div>
              </div>
            ))}
          </div>
          <div css={[classes.changeRowContainer, classes.activityContainer]}>
            <div css={classes.changeRow}>
              <div css={{ marginRight: displayOtherChanges ? 6 : '' }}>
                {displayOtherChanges
                  ? 'Subtotal of Rockefeller Accounts at NFS'
                  : 'Total'}
              </div>
              <ChangeAmountField
                value={total}
                onClick={showNav && (() => navigate('./balances'))}
              />
            </div>
          </div>
        </div>
        {displayOtherChanges && (
          <>
            <div css={[classes.changeRowContainer]}>
              <div css={classes.changeRow}>
                <div>Accounts at Other Custodians</div>
                <ChangeAmountField value={totalNonNfsValueChange} />
              </div>
            </div>
            <div css={[classes.changeRowContainer]}>
              <div css={classes.changeRow}>
                <div>External Accounts</div>
                <ChangeAmountField value={totalexternalaccountvaluechange} />
              </div>
            </div>
            <div css={[classes.changeRowContainer]}>
              <div css={classes.changeRow}>
                <div>Other Assets/Liabilities</div>
                <ChangeAmountField value={totalotherassetsvaluechange} />
              </div>
            </div>
            <div css={[classes.changeRowContainer, classes.activityContainer]}>
              <div css={classes.changeRow}>
                <div>Total</div>
                <ChangeAmountField value={totalaccountvaluechange} />
              </div>
            </div>
          </>
        )}
      </div>
    )
  }
)

const textDecorationLink = css({
  cursor: 'pointer',
  textDecoration: 'underline'
})

export const TodaysChange: React.FC<{
  change?: number
  changeDetails?: IBalanceTodaysChangeItem[]
  accountNumber?: string
  totalNonNfsValueChange?: number
  totalaccountvaluechange?: number
  totalotherassetsvaluechange?: number
  totalexternalaccountvaluechange?: number
  onChangeClick?: () => void
  displayOtherChanges?: boolean
}> = memo(
  ({
    change,
    changeDetails,
    accountNumber,
    onChangeClick,
    totalNonNfsValueChange,
    totalaccountvaluechange,
    totalotherassetsvaluechange,
    totalexternalaccountvaluechange,
    displayOtherChanges
  }) => {
    const hasData = useMemo(() => {
      return (
        changeDetails?.some((x) => !!x.amount) ||
        totalNonNfsValueChange ||
        totalaccountvaluechange ||
        totalotherassetsvaluechange ||
        totalexternalaccountvaluechange
      )
    }, [
      changeDetails,
      totalNonNfsValueChange,
      totalaccountvaluechange,
      totalexternalaccountvaluechange,
      totalotherassetsvaluechange
    ])

    const plainCardProps = useMemo(
      (): IPlainCardProps => ({
        onRenderPlainCard: () => (
          <DayChanges
            accountNumber={accountNumber}
            data={changeDetails}
            totalNonNfsValueChange={totalNonNfsValueChange}
            totalaccountvaluechange={totalaccountvaluechange}
            totalotherassetsvaluechange={totalotherassetsvaluechange}
            totalexternalaccountvaluechange={totalexternalaccountvaluechange}
            displayOtherChanges={displayOtherChanges}
          />
        ),
        directionalHint: DirectionalHint.bottomCenter,
        directionalHintFixed: false,
        calloutProps: {
          isBeakVisible: true
        }
      }),
      [
        accountNumber,
        changeDetails,
        displayOtherChanges,
        totalNonNfsValueChange,
        totalaccountvaluechange,
        totalexternalaccountvaluechange,
        totalotherassetsvaluechange
      ]
    )

    return change != null ? (
      hasData ? (
        <HoverCard
          styles={{ host: { display: 'inline-flex' } }}
          plainCardProps={plainCardProps}
          type={HoverCardType.plain}
          cardDismissDelay={400}
          instantOpenOnClick={true}
        >
          <div onClick={onChangeClick}>
            <USDCellWithColor value={change} css={textDecorationLink} />
          </div>
        </HoverCard>
      ) : (
        <div onClick={onChangeClick}>
          <USDCellWithColor value={change} />
        </div>
      )
    ) : (
      '--'
    )
  }
)
