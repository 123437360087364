import { SerializedStyles } from '@emotion/react'
import { TooltipHost } from '@fluentui/react'
import {
  Cell,
  CellContext,
  Column,
  flexRender,
  Table
} from '@tanstack/react-table'
import { ConnectedMaskedHighlightPlain } from 'features/Search/components/Highlight'
import { sum } from 'lodash'
import { PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'
import { parseDateISOStringInLocalTimezone } from 'shared'
import { isNotNullOrUndefined } from 'shared/guards'
import { Icon } from 'shared/uiSharedComponents/Icon'
import { getEnableDataMaskingPreference } from 'store/user/selectors'
import { AccountNumberCell } from '../../components/shared/DetailTables/AccountNumberCell'
import { HighlightSearchText } from '../../components/shared/DetailTables/HighlightSearchText'
import { USDCellWithColor } from '../../components/USDCellWithColor'
import { useThirdPartyLoansUiState } from '../../modules/Loans/ThirdPartyLoans/store'
import { SortIndicator } from '../../shared/SortIndicator'
import { useRdot360AccountContext } from '../../store/rdot360Context'
import { DateTime, Percentage, USD } from './formatting'
import { ellipsis, textAlignLeft } from './styles'

type TCell<T> = {
  tableCell: Cell<T, unknown>
}

interface IAccountCellExtendedProps {
  key?: string
  accountaumber?: string
  loanaccount?: string
}

const TableCell = <T,>({
  tableCell,
  borderStyles
}: TCell<T> & { borderStyles?: SerializedStyles }) => {
  const {
    column: {
      columnDef: { cell, size, aggregatedCell }
    }
  } = tableCell

  const isAggregated = tableCell.getIsAggregated()
  const cellToRender = isAggregated ? aggregatedCell : cell

  return (
    <td css={[{ width: size }, borderStyles]}>
      {flexRender(cellToRender, tableCell.getContext())}
    </td>
  )
}

const DisplayCell = <T,>({ row }: CellContext<T, unknown>) => {
  const clickExpand = row.getToggleExpandedHandler()
  const canRowExpand = row.getCanExpand()
  const isExpanded = row.getIsExpanded()
  const iconName = isExpanded ? 'Subtract' : 'Add'

  return canRowExpand ? (
    <Icon
      type={iconName}
      width={12}
      height={12}
      color="black"
      onClick={clickExpand}
    />
  ) : null
}

const HighlightedSearchCell = <T,>({
  getValue,
  leftAligned
}: {
  getValue: CellContext<T, unknown>['getValue']
  leftAligned?: true
}) => {
  const { searchText } = useThirdPartyLoansUiState()
  return (
    <div css={[leftAligned ? textAlignLeft : undefined, ellipsis]}>
      <HighlightSearchText text={getValue<string>()} search={searchText} />
    </div>
  )
}

const AccountCellExtended = <T extends IAccountCellExtendedProps>({
  cell,
  row,
  getValue
}: CellContext<T, unknown>) => {
  const shouldMask = useSelector(getEnableDataMaskingPreference)
  const isPlaceholder = cell.getIsPlaceholder()
  const { searchText } = useThirdPartyLoansUiState()

  if (isPlaceholder) {
    return (
      <div css={textAlignLeft}>
        <ConnectedMaskedHighlightPlain
          text={row.original.loanaccount}
          highlight={searchText}
        />
      </div>
    )
  }

  return (
    <div css={textAlignLeft}>
      <AccountNumberCell
        accountIdOrKey={getValue<string>()}
        forceMaskAccountNumber={shouldMask}
        fallbackValue="My Account"
        displayFirmName={true}
        search={searchText}
      />
    </div>
  )
}

const ServiceProviderCell = <T,>({ getValue }: CellContext<T, unknown>) => {
  const { accountLookupByAccountIdOrKey } = useRdot360AccountContext()
  const accountKey = getValue<string>()
  const serviceProvider =
    accountLookupByAccountIdOrKey[accountKey]?.Accountsource || 'Unknown'

  return <div css={textAlignLeft}>{serviceProvider}</div>
}

const PledgedAccountsCellAggregated = <T,>({
  getValue
}: CellContext<T, unknown>) => {
  const { searchText } = useThirdPartyLoansUiState()
  const pledgedAccounts = getValue<string[]>()
  const [uniqueFirst] = pledgedAccounts || []

  if (pledgedAccounts == null) {
    return <>--</>
  }

  const uniqueFirstSplit = uniqueFirst?.split(';')
  const isMultipleAccounts = uniqueFirstSplit?.length > 1

  return isMultipleAccounts ? (
    <TooltipHost
      content={
        <HighlightSearchText
          text={uniqueFirstSplit.join(', ')}
          search={searchText}
        />
      }
    >
      <div css={{ display: 'flex', alignContent: 'start' }}>
        <span
          css={[
            {
              cursor: 'pointer',
              textDecoration: 'underline'
            },
            ellipsis
          ]}
        >
          <HighlightSearchText
            text={uniqueFirstSplit.join(', ')}
            search={searchText}
          />
        </span>
        <span>({uniqueFirstSplit?.length})</span>
      </div>
    </TooltipHost>
  ) : (
    <div css={textAlignLeft}>
      <HighlightSearchText
        text={uniqueFirstSplit.join(', ')}
        search={searchText}
      />
    </div>
  )
}

const ColumnHeaderWithSortIndicator = <T,>({
  column,
  children,
  leftAligned
}: PropsWithChildren<{
  column: Column<T, unknown>
  leftAligned?: true
}>) => {
  const { getToggleSortingHandler } = column
  const sorted = column.getIsSorted()
  const justifyContent = leftAligned ? 'flex-start' : 'flex-end'
  const textAlign = leftAligned ? 'left' : undefined

  return (
    <div
      onClick={getToggleSortingHandler()}
      css={{
        display: 'flex',
        alignItems: 'baseline',
        cursor: 'pointer',
        justifyContent,
        gap: 2
      }}
    >
      <div css={{ textAlign }}>{children}</div>
      <span className={sorted ? undefined : 'column-not-sorted'}>
        <SortIndicator direction={sorted} />
      </span>
    </div>
  )
}

const PercentageCell = <T,>({ getValue }: CellContext<T, unknown>) => (
  <Percentage value={getValue<number>()} />
)

const USDCell = <T,>({ getValue }: CellContext<T, unknown>) => (
  <USD value={getValue<number>()} />
)

const USDCellTotalAmount = <T,>({
  table,
  column,
  inColor,
  fractionDigits
}: {
  table: Table<T>
  column: Column<T, unknown>
  inColor?: true
  fractionDigits?: number
}) => {
  const { rows: preExpandedRows } = table.getPreExpandedRowModel()
  const total = sum(
    preExpandedRows.map(({ getValue }) => getValue<number>(column.id) || 0)
  )
  if (total == null) {
    return <>--</>
  }

  return inColor ? (
    <USDCellWithColor value={total} fractionDigit={fractionDigits} />
  ) : (
    <USD value={total} {...{ fractionDigits }} />
  )
}

const ISODateCell = <T,>({ getValue }: CellContext<T, unknown>) => {
  const dateString = getValue<string>()
  const ISODate = dateString
    ? parseDateISOStringInLocalTimezone(dateString)
    : undefined

  return <DateTime value={ISODate} />
}

const ISODateCellAggregated = <T,>({ getValue }: CellContext<T, unknown>) => {
  const dateString = getValue<string[]>()
  const dateStringFiltered = dateString.filter(isNotNullOrUndefined)
  const isMultiple = dateStringFiltered.length > 1
  const [uniqueDateString] = dateStringFiltered
  const uniqueISODate = uniqueDateString
    ? parseDateISOStringInLocalTimezone(uniqueDateString)
    : undefined

  return isMultiple ? <>Multiple</> : <DateTime value={uniqueISODate} />
}

const MarginTypeCell = <T,>({ getValue, row }: CellContext<T, unknown>) => {
  const [isMultiMargin, isPrimary] = getValue<boolean[]>() || []

  if (!row.depth) {
    return <>{isMultiMargin ? 'Multi Margin' : 'Single Margin'}</>
  }

  return <>{isPrimary ? 'Primary' : 'Secondary'}</>
}

export {
  TableCell,
  DisplayCell,
  HighlightedSearchCell,
  AccountCellExtended,
  ServiceProviderCell,
  PledgedAccountsCellAggregated,
  ColumnHeaderWithSortIndicator,
  PercentageCell,
  USDCell,
  USDCellTotalAmount,
  ISODateCell,
  ISODateCellAggregated,
  MarginTypeCell
}
