import { createSelector } from 'reselect'
import { AppState } from 'store'
import { getRootState, useSelector } from 'store/shared'
import { RdotUserRoleEnum } from './rdotUser'

const selectUserState = createSelector(getRootState, ({ user }) => user)
const selectRdotUserRoles = createSelector(
  selectUserState,
  ({ rdot }) => rdot?.item?.roles
)
const selectIsInRole = createSelector(
  selectRdotUserRoles,
  (_: AppState, role: RdotUserRoleEnum) => role,
  (roles, role) => roles?.includes(role)
)

const createIsInRoleSelector = (role: RdotUserRoleEnum) => (state: AppState) =>
  selectIsInRole(state, role)

export const useRdotUser = () => {
  const roles = useSelector(selectRdotUserRoles)
  const isPilotUser = useSelector(
    createIsInRoleSelector(RdotUserRoleEnum.Advisory_Pilot)
  )
  const isRdot360User = useSelector(
    createIsInRoleSelector(RdotUserRoleEnum.Advisory_Feature_Rdot360)
  )
  const isRelationshipViewUser = useSelector(
    createIsInRoleSelector(RdotUserRoleEnum.Advisory_Feature_RelationhipView)
  )
  const isAIDashboardPilotUser = useSelector(
    createIsInRoleSelector(RdotUserRoleEnum.Advisory_Feature_AIDashboard)
  )
  const isAIOpsUser = useSelector(
    createIsInRoleSelector(RdotUserRoleEnum.Operations_AltInvestments)
  )
  const isAdvisorLoansDashboardUser = useSelector(
    createIsInRoleSelector(RdotUserRoleEnum.Advisory_Feature_LoansDashboard)
  )
  const isBookDashboardActivityUser = useSelector(
    createIsInRoleSelector(
      RdotUserRoleEnum.Advisory_Feature_BookDashboardActivity
    )
  )
  const isClientDashboardCommunicationsCenterUser = useSelector(
    createIsInRoleSelector(
      RdotUserRoleEnum.Advisory_Feature_CommunicationsCenter
    )
  )
  const isClientDashboardInsightsPilotUser = useSelector(
    createIsInRoleSelector(
      RdotUserRoleEnum.Advisory_Feature_ClientDashboardInsights
    )
  )
  const isClientDashboardRevenuePilotUser = useSelector(
    createIsInRoleSelector(
      RdotUserRoleEnum.Advisory_Feature_ClientDashboardRevenue
    )
  )
  const isClientDashboardLoansPilotUser = useSelector(
    createIsInRoleSelector(
      RdotUserRoleEnum.Advisory_Feature_ClientDashboardLoans
    )
  )
  const isAdvisorAIDashboardTransfersUser = useSelector(
    createIsInRoleSelector(
      RdotUserRoleEnum.Advisory_Feature_AIDashboardTransfers
    )
  )
  const isOLTUser = useSelector(
    createIsInRoleSelector(RdotUserRoleEnum.Advisory_OLT)
  )
  const isPAUser = useSelector(
    createIsInRoleSelector(RdotUserRoleEnum.Advisory_FinancialAdvisor)
  )
  const isCAUser = useSelector(
    createIsInRoleSelector(RdotUserRoleEnum.Advisory_ClientAssociate)
  )
  return {
    roles,
    isPilotUser,
    isRdot360User,
    isRelationshipViewUser,
    isAIDashboardPilotUser,
    isAIOpsUser,
    isAdvisorLoansDashboardUser,
    isBookDashboardActivityUser,
    isClientDashboardCommunicationsCenterUser,
    isClientDashboardInsightsPilotUser,
    isClientDashboardRevenuePilotUser,
    isClientDashboardLoansPilotUser,
    isAdvisorAIDashboardTransfersUser,
    isOLTUser,
    isPAUser,
    isCAUser
  }
}
