import { groupBy, sumBy, uniqBy } from 'lodash'
import { TileLoadingOverlay } from 'modules/Advisory/modules/Rdot360/components/TileLoadingOverlay'
import { WidgetTable } from 'modules/Advisory/modules/Rdot360/features/WidgetTable/WidgetTable'
import { IBalanceDetailResponseValueItem } from 'modules/Advisory/modules/Rdot360/store/balancesApi/IBalanceDetailResponse'
import { useRdot360BalancesContext } from 'modules/Advisory/modules/Rdot360/store/rdot360Context/useRdot360BalancesContext'
import { FC, useMemo } from 'react'
import { isNotNullOrUndefined } from 'shared/guards'
import { getColumnDefinition } from './ColumnDefinitions'

const columns = getColumnDefinition()

export const ThirdPartyLoans: FC = () => {
  const { balancesResponse, isFetching, isUninitialized } =
    useRdot360BalancesContext()
  const tableData: IBalanceDetailResponseValueItem[] = useMemo(() => {
    const { accountbalances = [] } = balancesResponse || {}
    return accountbalances
  }, [balancesResponse])

  const aggregatedData = useMemo(() => {
    const flatTableData = tableData
      .flatMap((item) => {
        if (item.notes?.length) {
          const { notes } = item
          return [...notes.map((subItem) => ({ ...subItem }))]
        }
        if (
          item['@odata.type'] &&
          item['@odata.type'].indexOf('LoanNote') !== -1
        ) {
          return [item]
        }
        return [null]
      })
      .filter(isNotNullOrUndefined)

    const dataGrouped = groupBy(flatTableData, ({ key }) => key)
    const keys = Object.keys(dataGrouped)

    return keys.map((key) => {
      const dataGroupedByKey = dataGrouped[key]
      const [firstItem] = dataGroupedByKey
      const isMultiplePayments =
        uniqBy(dataGroupedByKey, ({ nextpaymentdate }) => nextpaymentdate)
          .length > 1

      return {
        key,
        availablecredit: firstItem.availablecredit,
        nextpayment: isMultiplePayments
          ? 'Multiple'
          : sumBy(dataGroupedByKey, (x) => x.nextpayment ?? 0),
        totalaccountvalue: sumBy(
          dataGroupedByKey,
          (x) => x.totalaccountvalue ?? 0
        )
      }
    })
  }, [tableData])

  return (
    <>
      <WidgetTable
        data={aggregatedData}
        columns={columns}
        isLoading={isFetching}
        isFooter={true}
        isUninitialized={isUninitialized}
      />
      <div css={{ marginTop: 10, fontSize: 10 }}>
        Note: This report includes all third-party loans for the selected client
        and is not driven by the accounts selected within the Account Selector
      </div>
      {isFetching && <TileLoadingOverlay />}
    </>
  )
}
