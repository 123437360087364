import { css, Theme, useTheme } from '@emotion/react'
import { Dropdown, IDropdownOption } from '@fluentui/react'
import { endOfYear, format, startOfYear, subDays, subYears } from 'date-fns'
import DatePicker from 'modules/Advisory/modules/Rdot360/components/shared/DatePicker'
import { useCallback, useMemo } from 'react'
import { useSecureMessagesState } from '../../shared/hooks'

const datePickerPlaceholder = 'YYYY-MM-DD'

const formatDate = (date?: Date) => (date ? format(date, 'yyyy-MM-dd') : '')

const getClasses = (theme: Theme) => ({
  dateRangeContainer: css({
    display: 'flex',
    flexDirection: 'column',
    color: theme.colors.primaryBlack
  }),
  dateRangeDropdown: css({
    width: '174px',
    marginTop: '4px',
    '& .ms-Dropdown-title': {
      borderColor: '#AFB1B6',
      borderRadius: '4px',
      height: '34px'
    }
  }),
  dateRangePicker: css({
    width: '140px',
    marginTop: '4px'
  })
})

const dateRangeOptions: IDropdownOption[] = [
  { key: 'all', text: 'All' },
  { key: 'last30days', text: 'Last 30 Days' },
  { key: 'lastYear', text: 'Last Year' },
  { key: 'previousYear', text: 'Previous Year' },
  { key: 'custom', text: 'Custom Date Range' }
]

export const DateRange: React.FC = () => {
  const theme = useTheme()
  const classes = useMemo(() => getClasses(theme), [theme])
  const {
    dateRange,
    endDate,
    startDate,
    setDateRange,
    setEndDate,
    setStartDate
  } = useSecureMessagesState()

  const handleDateRangeChange = useCallback(
    (ev: any, option?: IDropdownOption) => {
      if (!option?.key) {
        return
      }

      let endDate: Date | undefined
      let startDate: Date | undefined

      const currentDate = new Date()
      const dateRange = option.key as string
      switch (dateRange) {
        case 'all':
        case 'custom':
          endDate = undefined
          startDate = undefined
          break
        case 'last30days':
          endDate = currentDate
          startDate = subDays(currentDate, 30)
          break
        case 'lastYear':
          endDate = currentDate
          startDate = subDays(currentDate, 365)
          break
        case 'previousYear':
          endDate = endOfYear(subYears(currentDate, 1))
          startDate = startOfYear(subYears(currentDate, 1))
          break
      }

      setDateRange({ dateRange, endDate, startDate })
    },
    [setDateRange]
  )

  return (
    <>
      <div css={classes.dateRangeContainer}>
        <div>Date Range</div>
        <div css={classes.dateRangeDropdown}>
          <Dropdown
            options={dateRangeOptions}
            selectedKey={dateRange}
            onChange={handleDateRangeChange}
          />
        </div>
      </div>
      {dateRange === 'custom' && (
        <>
          <div css={classes.dateRangeContainer}>
            <div>Start Date</div>
            <div css={classes.dateRangePicker}>
              <DatePicker
                formatDate={formatDate}
                maxDate={endDate}
                onSelectDate={setStartDate}
                placeholder={datePickerPlaceholder}
                value={startDate}
              />
            </div>
          </div>
          <div css={classes.dateRangeContainer}>
            <div>End Date</div>
            <div css={classes.dateRangePicker}>
              <DatePicker
                formatDate={formatDate}
                maxDate={new Date()}
                minDate={startDate}
                onSelectDate={setEndDate}
                placeholder={datePickerPlaceholder}
                value={endDate}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
