import { ActionButton } from '@fluentui/react'
import { useCallback, useMemo } from 'react'
import { useSecureMessagesState } from '../../shared/hooks'
import { styles } from '../../shared/styles'
import { secureMessageFolders } from '../../shared/types'

export const BackToFolder: React.FC = () => {
  const { previousView, setCurrentView } = useSecureMessagesState()

  const name = useMemo(
    () =>
      secureMessageFolders.find((x) => x.key === previousView)?.name || 'Inbox',
    [previousView]
  )

  const handleBackClick = useCallback(() => {
    setCurrentView(previousView || 'inbox')
  }, [setCurrentView, previousView])

  return (
    <ActionButton
      iconProps={{ iconName: 'Back', style: styles.icon }}
      onClick={handleBackClick}
      title={`Back to ${name}`}
    >
      Back to {name}
    </ActionButton>
  )
}
