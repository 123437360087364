import { detailTable } from 'modules/Advisory/modules/Rdot360/components/shared/DataTable/DetailTable'

export const PrintDescriptionCell: React.FC<{ description: string }> = ({
  description
}) => {
  return (
    <div css={{ position: 'relative' }}>
      <div
        css={[
          detailTable.ellipsis,
          {
            display: '-webkit-box',
            whiteSpace: 'normal',
            '-webkit-line-clamp': '2',
            '-webkit-box-orient': 'vertical'
          }
        ]}
      >
        {description}
      </div>
    </div>
  )
}
