import { css } from '@emotion/react'

export const ellipsis = css({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  display: 'block'
})

export const textAlignLeft = css({
  textAlign: 'left'
})

export const textAlignRight = css({
  textAlign: 'right'
})

export const textAlignCenter = css({
  textAlign: 'center'
})
