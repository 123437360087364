import { Row } from '@tanstack/react-table'
import { IBalanceDetailResponseValueItem } from '../../../store/balancesApi/IBalanceDetailResponse'

export const globalFilterFn = (
  row: Row<IBalanceDetailResponseValueItem>,
  id: string,
  filterValue: string
): boolean => {
  const searchString = (filterValue || '').toLowerCase()

  if (id === 'accountKey') {
    const { key = '', loanaccount = '' } = row.original
    return [key, loanaccount].some((value) =>
      value?.toLowerCase().includes(searchString)
    )
  }

  const { customername = '', collateralaccounts = '' } = row.original

  return [customername, collateralaccounts].some((value) =>
    value?.toLowerCase().includes(searchString)
  )
}
