import { css } from '@emotion/react'
import { useMemo } from 'react'

const getClasses = () => ({
  disclaimer: css({
    paddingTop: '20px',
    fontSize: '9pt',
    marginTop: '70px'
  })
})

export const PrintDisclaimer: React.FC = () => {
  const classes = useMemo(() => getClasses(), [])
  return (
    <section role="article" css={classes.disclaimer}>
      <p>
        Investing involves risk, including risk of loss. Past performance is no
        guarantee of future results. These materials do not constitute an offer
        to sell or a solicitation of an offer to buy interests in any
        Rockefeller Capital Management investment vehicle or product.
      </p>
      <p>
        The information contained herein is provided for informational purposes
        only and is not intended, and should not be construed, as investment,
        accounting, tax or legal advice. Please consult your legal and tax
        advisors when considering this information.
      </p>
      <p>
        Third Party data is provided for your convenience and is not an
        endorsement or recommendation of any related content, products, or
        services for investments held outside of Rockefeller Financial LLC
        (&ldquo;RFLLC&rdquo;). RFLLC has not prepared this material and is not
        responsible for its accuracy. In some instances, system limitations may
        necessitate adjustments to transaction, pricing, balance and position
        data in order to perform the aggregation, and data may vary from your
        account statements. In all instances, this information is provided for
        informational purposes only; it is not your official statement. Such
        information is based on information provided by you and is as of the
        date provided to RFLLC. RFLLC assumes no responsibility for the accuracy
        of the source information and such assets may not be included in RFLLC
        books and records.
      </p>
      <p>
        National Financial Services LLC (&ldquo;NFS&rdquo;) carries your RFLLC
        accounts and acts as your custodian for funds and securities that are
        deposited with NFS by you or your broker-dealer. Any information shown
        here is for informational purposes only and is not your official
        statement.
      </p>
      <p>
        Fidelity Charitable&reg; Giving Account&reg; balances are owned and held
        by Fidelity Charitable&reg;, an independent public charity with a
        donor-advised fund program. Giving Account&reg; balances are displayed
        as a convenience for Fidelity Charitable&apos;s donors.
      </p>
      <p>
        Rockefeller Capital Management is the marketing name of Rockefeller
        Capital Management L.P. and its affiliates. Investment advisory, asset
        management and fiduciary activities are performed by the following
        affiliates of Rockefeller Capital Management: Rockefeller & Co. LLC,
        Rockefeller Trust Company, N.A., The Rockefeller Trust Company
        (Delaware) and Rockefeller Financial LLC, as the case may be.
        Rockefeller Financial LLC is a broker-dealer and investment adviser
        dually registered with the U.S. Securities and Exchange Commission
        (SEC); Member Financial Industry Regulatory Authority (FINRA),
        Securities Investor Protection Corporation (SIPC). These registrations
        and memberships in no way imply that the SEC has endorsed the entities,
        products or services discussed herein. Additional information is
        available upon request.
      </p>
      <p>&copy; 2023 Rockefeller Capital Management. All rights reserved.</p>
    </section>
  )
}
