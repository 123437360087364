import { IconButton } from '@fluentui/react'
import { useSecureMessagesState } from '../../shared/hooks/useSecureMessagesState'
import { styles } from '../../shared/styles'

export const EditIconButton: React.FC = () => {
  const { setCurrentView } = useSecureMessagesState()

  return (
    <div style={styles.buttonContainer}>
      <IconButton
        iconProps={{
          iconName: 'Edit',
          style: styles.icon
        }}
        onClick={() => setCurrentView('edit')}
        title="Edit message"
      />
    </div>
  )
}
