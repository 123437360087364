import { createSlice } from '@reduxjs/toolkit'
import { flow } from 'lodash'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getRootState } from 'store/shared'
import { ActionType } from 'typesafe-actions'

export type HistoricalValueDateRangeType =
  | 'mostRecent'
  | 'priorMonth'
  | 'prior6Months'
  | 'prior12Months'
  | 'currentYear'
  | 'priorYear'
  | 'inceptionToDate'
  | 'customRange'
export interface IBalanceFilterState {
  isCustomRange: boolean
  customFromDate?: Date
  customToDate?: Date
}

const initialState: IBalanceFilterState = {
  isCustomRange: false,
  customFromDate: undefined,
  customToDate: new Date()
}

export const balanceFilterSlice = createSlice({
  name: '@features/@rdot360/@BalanceFilter',
  initialState,
  reducers: {
    setIsCustomRange: (state, action) => {
      state.isCustomRange = action.payload
    },
    setCustomFromDate: (state, action) => {
      state.customFromDate = action.payload
    },
    setCustomToDate: (state, action) => {
      state.customToDate = action.payload
    }
  }
})

export const getFilterState = flow(getRootState, (x) => {
  return x.modules.advisory.modules.rdot360.balanceFilter
})

const getIsCustomDate = flow(getFilterState, (x) => x.isCustomRange)
const getCustomFromDate = flow(getFilterState, (x) => x.customFromDate)
const getCustomToDate = flow(getFilterState, (x) => x.customToDate)

export const balanceFilterActions = balanceFilterSlice.actions
export const balanceFilterReducer = balanceFilterSlice.reducer

export type BalanceFilterActionTypes = ActionType<typeof balanceFilterActions>

export const useBalanceModuleStore = () => {
  const dispatch = useDispatch()
  const isCustomRange = useSelector(getIsCustomDate)
  const customFromDate = useSelector(getCustomFromDate)
  const customToDate = useSelector(getCustomToDate)

  const setIsCustomRange = useCallback(
    (isCustomRange?: boolean) => {
      dispatch(balanceFilterActions.setIsCustomRange(isCustomRange))
    },
    [dispatch]
  )

  const setCustomFromDate = useCallback(
    (customFromDate?: Date) => {
      dispatch(balanceFilterActions.setCustomFromDate(customFromDate))
    },
    [dispatch]
  )

  const setCustomToDate = useCallback(
    (customToDate?: Date) => {
      dispatch(balanceFilterActions.setCustomToDate(customToDate))
    },
    [dispatch]
  )

  return {
    isCustomRange,
    setIsCustomRange,
    customFromDate,
    setCustomFromDate,
    customToDate,
    setCustomToDate
  }
}
