import { IDropdownOption } from '@fluentui/react'
import { format } from 'date-fns'
import { useCallback, useMemo } from 'react'
import { HighchartsComponent } from 'shared/highcharts'
import { useBalanceSummaryChart } from '../../../components/Balances/useBalanceSummaryChart'
import {
  Dropdown,
  IndeterminateProgressIndicator
} from '../../../components/shared'
import DatePicker from '../../../components/shared/DatePicker'
import { SnackBar } from '../../../components/shared/Snackbar'
import { TileLoadingOverlay } from '../../../components/TileLoadingOverlay'
import { useClientDashboardTilePreferences } from '../../../hooks/useClientDashboardPreferences'
import { ClientDashboardTiles } from '../../../shared/types'
import { useBalanceSummaryDetailsUiState } from '../../Balances/balanceSummaryDetailsUIState'
import { HistoricalValueDateRangeType, useBalanceModuleStore } from './store'

type DateOrNull = Date | undefined | null

const dateRangeOptions: IDropdownOption[] = [
  { key: 'mostRecent', text: 'Last 30 Days' },
  {
    key: 'priorMonth',
    text: 'Prior Month'
  },
  {
    key: 'prior6Months',
    text: 'Prior 6 Months'
  },
  {
    key: 'prior12Months',
    text: 'Prior 12 Months'
  },
  {
    key: 'currentYear',
    text: 'Current Year'
  },
  {
    key: 'priorYear',
    text: 'Prior Year'
  },
  {
    key: 'inceptionToDate',
    text: 'Inception To Date'
  },
  {
    key: 'customRange',
    text: 'Custom Range'
  }
]

const containerProps: React.HTMLAttributes<unknown> = {
  style: { height: '100%', width: '100%' }
}

const tileName = ClientDashboardTiles.historicalValueTile

export const HistoricalBalancesChart: React.FC<{
  loader: 'overlay' | 'progress'
}> = ({ loader }) => {
  const { setIsExpanded } = useBalanceSummaryDetailsUiState()

  const {
    isCustomRange,
    setIsCustomRange,
    customFromDate,
    setCustomFromDate,
    customToDate,
    setCustomToDate
  } = useBalanceModuleStore()

  const { setTilePreferences, tilePreferences } =
    useClientDashboardTilePreferences(tileName)

  const selectedDateRangeType = useMemo(() => {
    return isCustomRange
      ? 'customRange'
      : tilePreferences?.historicalValueDateRangeType || 'prior12Months'
  }, [isCustomRange, tilePreferences?.historicalValueDateRangeType])

  const {
    balanceSummaryChartData,
    isBalanceSummarychartFetching,
    isBalanceSummaryChartError,
    error: balanceSummaryChartError
  } = useBalanceSummaryChart()

  const errorMessage = useMemo(() => {
    return (
      (balanceSummaryChartError as Error)?.message ||
      'An unknown Error Occurred'
    )
  }, [balanceSummaryChartError])

  const historicalBalancesOptions = useMemo(() => {
    const { categories = [], totalAmounts = [] } = balanceSummaryChartData || {}

    const historicalBalancesOptions: Highcharts.Options = {
      chart: {
        type: 'area',
        events: {
          click: (ev: any) => {
            ev?.preventDefault && ev.preventDefault()
            setIsExpanded(true)
          }
        }
      },
      tooltip: {
        pointFormat: '<p>{point.isNegative}${point.y:,.0f}</p>'
      },
      accessibility: {
        description: 'Historical Balances Chart'
      },
      title: {
        text: ''
      },
      xAxis: {
        categories,
        tickWidth: 1,
        tickmarkPlacement: 'on',
        labels: {
          rotation: 45
        }
      },
      yAxis: {
        tickWidth: 0,
        gridLineColor: '#d6d6d6',
        title: {
          text: ''
        },
        opposite: true
      },
      plotOptions: {
        area: {
          connectNulls: true,
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [
              [0, '#2A769D'],
              [1, 'rgba(255, 255, 255, 0)']
            ]
          },
          lineWidth: 2,
          lineColor: '#2A769D',
          marker: {
            enabled: false,
            symbol: 'circle',
            radius: 0,
            states: {
              hover: {
                enabled: true
              }
            }
          }
        }
      },
      series: [
        {
          showInLegend: false,
          name: 'Account Value',
          data: totalAmounts
        }
      ] as Highcharts.SeriesOptionsType[]
    }

    return historicalBalancesOptions
  }, [balanceSummaryChartData, setIsExpanded])

  const handleDateRangeChange = useCallback(
    (ev: any, option?: IDropdownOption) => {
      const dateRange = option?.key as HistoricalValueDateRangeType
      if (dateRange === 'customRange') {
        setIsCustomRange(true)
      } else {
        setIsCustomRange(false)
        setTilePreferences({ historicalValueDateRangeType: dateRange })
      }
    },
    [setIsCustomRange, setTilePreferences]
  )

  const formatDate = useCallback(
    (date?: Date) => (date ? format(date, 'yyyy-MM-dd') : ''),
    []
  )

  const handleFromDate = useCallback(
    (date: DateOrNull) => {
      if (date) {
        setCustomFromDate(date)
      }
    },
    [setCustomFromDate]
  )

  const handleToDate = useCallback(
    (date: DateOrNull) => {
      if (date) {
        setCustomToDate(date)
      }
    },
    [setCustomToDate]
  )

  return (
    <>
      {isBalanceSummaryChartError && (
        <div css={{ paddingBottom: 5 }}>
          <SnackBar message={errorMessage} type="Failure" />
        </div>
      )}
      <div
        css={{
          display: 'flex',
          columnGap: 15,
          alignItems: 'end',
          margin: '5px 0',
          flexDirection: 'row'
        }}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Dropdown
            options={dateRangeOptions}
            selectedKey={selectedDateRangeType}
            onChange={handleDateRangeChange}
            css={{ width: 150 }}
          />
        </div>
        {selectedDateRangeType === 'customRange' && (
          <div css={{ display: 'flex', columnGap: 5, alignItems: 'center' }}>
            <DatePicker
              value={customFromDate}
              onSelectDate={handleFromDate}
              minDate={new Date(2019, 0, 1)}
              maxDate={customToDate}
              placeholder="YYYY-MM-DD"
              formatDate={formatDate}
              style={{ width: 135 }}
            />
            <div>-</div>
            <DatePicker
              value={customToDate}
              onSelectDate={handleToDate}
              minDate={customFromDate}
              maxDate={new Date()}
              placeholder="YYYY-MM-DD"
              formatDate={formatDate}
              style={{ width: 135 }}
            />
          </div>
        )}
      </div>

      <HighchartsComponent
        options={historicalBalancesOptions}
        containerProps={containerProps}
      />
      <div
        css={{
          fontSize: '10px',
          fontWeight: 300
        }}
      >
        Note: Rockefeller accounts only.
      </div>
      {isBalanceSummarychartFetching && loader === 'progress' && (
        <div
          css={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%'
          }}
        >
          <IndeterminateProgressIndicator />
        </div>
      )}
      {isBalanceSummarychartFetching && loader === 'overlay' && (
        <TileLoadingOverlay />
      )}
    </>
  )
}
