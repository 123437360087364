import { TileLoadingOverlay } from 'modules/Advisory/modules/Rdot360/components/TileLoadingOverlay'
import { WidgetTable } from 'modules/Advisory/modules/Rdot360/features/WidgetTable/WidgetTable'
import { IBalanceDetailResponseValueItem } from 'modules/Advisory/modules/Rdot360/store/balancesApi/IBalanceDetailResponse'
import { useRdot360BalancesContext } from 'modules/Advisory/modules/Rdot360/store/rdot360Context/useRdot360BalancesContext'
import { FC, useMemo } from 'react'
import { getColumnDefinition } from './ColumnDefinitions'

const columns = getColumnDefinition()

export const MarginLoans: FC = () => {
  const { balancesResponse, isFetching, isUninitialized } =
    useRdot360BalancesContext()
  const tableData: IBalanceDetailResponseValueItem[] = useMemo(() => {
    const { marginbalances = [] } = balancesResponse || {}
    return marginbalances
  }, [balancesResponse])

  return (
    <>
      <WidgetTable
        data={tableData}
        columns={columns}
        isLoading={isFetching}
        isUninitialized={isUninitialized}
      />
      <div css={{ marginTop: 10, fontSize: 10 }}>
        Note: This report includes all margin loans for the selected client and
        is not driven by the accounts selected within the Account Selector
      </div>
      {isFetching && <TileLoadingOverlay />}
    </>
  )
}
