import { Link, Stack } from '@fluentui/react'
import { useCallback, useMemo } from 'react'
import { useClientDashboardTilePreferences } from '../../hooks/useClientDashboardPreferences'
import { ClientDashboardTiles } from '../../shared/types'
import { getHeaderStyles } from '../shared/getHeaderStyles'

const tileName = ClientDashboardTiles.loansTile

export enum loanType {
  marginLoans = 'Margin Loans',
  thirdPartyLoans = 'Third Party Loans'
}

const { marginLoans, thirdPartyLoans } = loanType

const classes = getHeaderStyles()

export const useLoanTypeTabs = () => {
  const { tilePreferences, setTilePreferences } =
    useClientDashboardTilePreferences(tileName)

  const isMarginLoans = useMemo(
    () => tilePreferences?.whatLoans === marginLoans,
    [tilePreferences]
  )

  const LoanTypeTabs = useCallback(() => {
    return (
      <Stack
        horizontal
        tokens={{ childrenGap: 20 }}
        css={[classes.tabs, { width: 360 }]}
      >
        <div
          css={[classes.tab, { fontSize: 16 }]}
          className={isMarginLoans ? 'selected' : undefined}
        >
          <Link onClick={() => setTilePreferences({ whatLoans: marginLoans })}>
            {marginLoans}
          </Link>
        </div>
        <div
          css={[classes.tab, { fontSize: 16 }]}
          className={isMarginLoans ? undefined : 'selected'}
        >
          <Link
            onClick={() => setTilePreferences({ whatLoans: thirdPartyLoans })}
          >
            {thirdPartyLoans}
          </Link>
        </div>
      </Stack>
    )
  }, [isMarginLoans, setTilePreferences])

  return { isMarginLoans, LoanTypeTabs }
}
