import { css, useTheme } from '@emotion/react'
import { IDropdownOption, TooltipHost } from '@fluentui/react'
import { wrap } from 'comlink'
import { format } from 'date-fns'
import { fromPairs } from 'lodash'
import { useAiPositionApiUtil } from 'modules/Advisory/modules/AI/modules/Dashboard/store/aiDashboardPositionsApi'
import { Dropdown } from 'modules/Advisory/modules/Rdot360/components/shared'
import DetailsNavigator from 'modules/Advisory/modules/Rdot360/components/shared/DetailsNavigator/DetailsNavigator'
import { Searchbox } from 'modules/Advisory/modules/Rdot360/components/shared/DetailTables/Searchbox'
import { Icon } from 'modules/Advisory/modules/Rdot360/features/Icons/Icon'
import { PrintDropdownMenu } from 'modules/Advisory/modules/Rdot360/shared/PrintDropdownMenu'
import {
  AssetClassLevel,
  useHoldingsApiUtil
} from 'modules/Advisory/modules/Rdot360/store/holdingsApi'
import { ICategoryPosition } from 'modules/Advisory/modules/Rdot360/store/holdingsApi/ICategoryPositionResponse'
import {
  useGetPositionByCategoryQueryForSelectedAccounts,
  useRdot360AccountContext
} from 'modules/Advisory/modules/Rdot360/store/rdot360Context'
import { useTasApiUtil } from 'modules/Advisory/modules/Rdot360/store/tasApi'
import { useCallback, useMemo } from 'react'
import { useStore } from 'react-redux'
import { downloadUrlAsFile } from 'shared/downloads'
import { isNotNullOrUndefined } from 'shared/guards'
import { useInvestmentsDetailsUIState } from '../../InvestmentsDetailsUIState'
import { useInvestmentsTableStore } from '../InvestmentsTable'
import { getInvestmentsTableColumnDefs } from '../InvestmentsTable/InvestmentsTableColumns'
import { investmentsTableColumnNames } from '../InvestmentsTable/shared'
import { openInvestmentsPrintTab } from '../PrintView/PrintView'
import type { InvestmentsExportWorker } from './export'

export const getClasses = () => ({
  investmentsMenu: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    columnGap: 20
  }),
  outerContainer: css({
    display: 'flex',
    flexDirection: 'column',
    rowGap: 10,
    flexGrow: 1
  }),
  innerContainer: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    columnGap: 20,
    growGap: 10,
    justifyContent: 'space-between'
  }),
  wrapContainer: css({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    columnGap: 20,
    rowGap: 10
  }),
  dropDownContainer: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  }),
  dropDown: css({
    marginTop: '8px',
    '.ms-Dropdown-title': {
      width: 175,
      height: 48,
      borderRadius: 8,
      borderColor: '#AFB1B6',
      paddingTop: 8
    },
    '.ms-Dropdown-caretDownWrapper': {
      top: 8
    }
  }),
  iconsOuter: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  }),
  iconsInner: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 10
  }),
  iconStyle: css({
    width: 24,
    height: 24,
    marginBottom: 5
  }),
  searchBox: css({
    marginLeft: 'auto'
  }),
  nonNFSAccountNote: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 10
  }),
  heading: css({
    fontSize: 14,
    fontWeight: 500
  }),
  sectorWrapper: css({
    display: 'flex',
    alignItems: 'center',
    marginBottom: 7,
    gap: 5
  }),
  noWrap: css({
    whiteSpace: 'nowrap'
  })
})

const empty: ICategoryPosition[] = []

const excludeUnclassifiedSectorsTooltip =
  'Non-equities and equity derivatives do not carry a sector assignment and will be classified as Unassigned. ' +
  'Also, there can be equities where the sector assignment is Unassigned. ' +
  'This selection may cause a change to the allocation amounts and percentages as well as the totals.'

export const InvestmentsMenu: React.FC = () => {
  const { searchText, setSearchText } = useInvestmentsDetailsUIState()
  const { selectedAccountIds } = useRdot360AccountContext()
  const { accountLookupByAccountIdOrKey } = useRdot360AccountContext()
  const {
    category,
    expanded,
    grouping,
    setInvestmentsViewByKey,
    viewByOptions,
    assetClassLevelOptions,
    assetClassLevelSelected,
    excludeUnclassifiedSectors,
    setAssetClassLevelSelected,
    setDefaultExpanded,
    setExpanded,
    setExcludeUnclassifiedSectors
  } = useInvestmentsTableStore()
  const { data, isFetching } =
    useGetPositionByCategoryQueryForSelectedAccounts(category)

  const isNonNFSAccounts = useMemo(() => {
    return selectedAccountIds.some((id) => {
      const account = accountLookupByAccountIdOrKey[id]
      return account?.CustodianCode !== 'NFS'
    })
  }, [accountLookupByAccountIdOrKey, selectedAccountIds])

  const columns = useMemo(() => getInvestmentsTableColumnDefs({}), [])

  const [firstGrouping] = grouping

  const exportToExcel = useCallback(async () => {
    const positions =
      data
        ?.flatMap((x) => x.invposacct)
        .flatMap((x) => x?.invposlist)
        .filter(isNotNullOrUndefined) || empty
    if (!positions?.length) {
      return
    }
    const columnDef = columns?.map((x) => ({
      id: x?.id || '',
      header: x?.id
    }))

    const mappedExportData = positions?.map((x, i) =>
      fromPairs<any>(
        columns?.map((column) => [column.id || '', column?.accessorFn(x, i)])
      )
    )

    const sanitizedExportData = mappedExportData.map((item) => ({
      ...item,
      'Day Gains/Losses': item['Day Gains/Losses'] ?? 0,
      'Unrealized Gain/Loss': item['Unrealized Gain/Loss'] ?? 0
    }))

    const worker = new Worker(new URL('./export.ts', import.meta.url))
    const { exportInvestments } = wrap<InvestmentsExportWorker>(worker)
    const result = await exportInvestments(
      sanitizedExportData,
      firstGrouping,
      columnDef
    )
    const viewByOption = viewByOptions.find(
      (option) => option.key === firstGrouping
    )
    const viewBy = viewByOption ? viewByOption.text : 'investment'
    const todaysDate = format(new Date(), 'MM-dd-yyyy')
    const filename = `Investments Viewed by ${viewBy} ${todaysDate}.xlsx`
    downloadUrlAsFile(result, filename)
  }, [columns, data, firstGrouping, viewByOptions])
  const onAssetClassLevelChange = useCallback(
    (e?: unknown, option?: IDropdownOption<any>) => {
      if (!option?.key) {
        return
      }
      setAssetClassLevelSelected(option.key as AssetClassLevel)
      setDefaultExpanded({})
      setExpanded({})
    },
    [setAssetClassLevelSelected, setDefaultExpanded, setExpanded]
  )
  const onGroupingChange = useCallback(
    (e?: unknown, option?: IDropdownOption<any>) => {
      if (!option?.key) {
        return
      }
      setInvestmentsViewByKey(option.key as string)
    },
    [setInvestmentsViewByKey]
  )
  const { invalidateTags: holdingsInvalidateTags } = useHoldingsApiUtil()
  const { invalidateTags: tasLotsInvalidateTags } = useTasApiUtil()
  const { invalidateTags: aiPositionsInvalidateTags } = useAiPositionApiUtil()
  const clearCache = useCallback(() => {
    holdingsInvalidateTags(['positionByCategory'])
    tasLotsInvalidateTags(['openTaxLotsByAccountAndSecurity'])
    aiPositionsInvalidateTags(['aiPositionDetails'])
  }, [aiPositionsInvalidateTags, holdingsInvalidateTags, tasLotsInvalidateTags])

  const classes = useMemo(() => getClasses(), [])

  const theme = useTheme()
  const store = useStore()

  const calcMaxDepth = useCallback(() => {
    const expandState = Object.entries(expanded || {})
    const allPaths = expandState.map((path) => path[0])
    const tablePaths = allPaths.filter((value) =>
      value.startsWith(firstGrouping)
    )
    let maxDepth = 0
    tablePaths.forEach((path) => {
      let currentDepth = (path.match(/:/g) || []).length
      if (currentDepth === 2) {
        // check if parent is expanded
        const index = path.indexOf('>')
        if (index !== -1) {
          const parentPath = path.substring(0, index)
          const found = tablePaths.find((value) => value === parentPath)
          // parent is closed, ignore this path
          if (!found) {
            currentDepth = 0
          }
        }
      }
      maxDepth = Math.max(maxDepth, currentDepth)
    })
    return maxDepth
  }, [expanded, firstGrouping])

  const print = useCallback(
    (
      masked = true,
      hideHousehold = true,
      includeAltInvestmentDetails = true
    ) => {
      const maxDepth = calcMaxDepth()
      openInvestmentsPrintTab(
        masked,
        hideHousehold,
        includeAltInvestmentDetails,
        maxDepth,
        store
      )
    },
    [calcMaxDepth, store]
  )

  const toggleExcludeUnclassifiedSectors = useCallback(() => {
    setExcludeUnclassifiedSectors(!excludeUnclassifiedSectors)
  }, [excludeUnclassifiedSectors, setExcludeUnclassifiedSectors])

  const isData = data && data?.length > 0
  const isDisabled = isFetching || !isData

  const NonNFSAccountsNote: React.FC = () => {
    const note =
      'Non-NFS share quantities are as of previous day and values are adjusted for current market price.'
    return <div css={classes.nonNFSAccountNote}>{note}</div>
  }

  const AssetClass: React.FC = () => {
    return (
      <div css={classes.dropDownContainer}>
        <div css={classes.heading}>Asset Class Level</div>
        <div css={classes.dropDown}>
          <Dropdown
            options={assetClassLevelOptions}
            selectedKey={assetClassLevelSelected}
            onChange={onAssetClassLevelChange}
          />
        </div>
      </div>
    )
  }

  const Icons: React.FC = () => {
    return (
      <>
        <div css={classes.iconStyle}>
          <Icon
            type="Refresh"
            width={24}
            height={24}
            onClick={clearCache}
            color={theme.colors.extraBlue2}
          />
        </div>
        <div css={classes.iconStyle}>
          <PrintDropdownMenu
            print={print}
            displayDisclaimer={true}
            displayAltInvestmentDetails={false} // DEBUG: Change to true once Print is updated to display Alt Investment Details
            isDisabled={isDisabled}
          />
        </div>
        <div css={classes.iconStyle}>
          <Icon
            type="Download"
            width={24}
            height={24}
            onClick={isDisabled ? undefined : exportToExcel}
            color={theme.colors.extraBlue2}
            isDisabled={isDisabled}
          />
        </div>
      </>
    )
  }

  const SectorClass: React.FC = () => {
    return (
      <div css={classes.sectorWrapper}>
        <input
          type="checkbox"
          style={{ cursor: 'pointer', margin: 0 }}
          checked={excludeUnclassifiedSectors}
          onChange={toggleExcludeUnclassifiedSectors}
        />
        <label css={classes.noWrap}>
          Exclude non-equities and equity derivatives
        </label>
        <TooltipHost
          content={excludeUnclassifiedSectorsTooltip}
          styles={{ root: { display: 'inline' } }}
        >
          <Icon
            type="Info"
            width={12}
            height={12}
            color={theme.colors.tertiaryBlue1}
          />
        </TooltipHost>
      </div>
    )
  }

  const ViewByDropDown: React.FC = () => {
    return (
      <div css={classes.dropDownContainer}>
        <div css={classes.heading}>View By</div>
        <div css={classes.dropDown}>
          <Dropdown
            options={viewByOptions}
            selectedKey={firstGrouping}
            onChange={onGroupingChange}
          />
        </div>
      </div>
    )
  }

  const isAssetClass = firstGrouping === investmentsTableColumnNames.assetClass
  const isSectorClass =
    firstGrouping === investmentsTableColumnNames.sectorClass

  return (
    <div css={classes.investmentsMenu}>
      <DetailsNavigator />
      <div css={classes.outerContainer}>
        {isNonNFSAccounts && <NonNFSAccountsNote />}
        <div css={classes.innerContainer}>
          <div css={classes.wrapContainer}>
            <ViewByDropDown />
            {isAssetClass && <AssetClass />}
            {isSectorClass && <SectorClass />}
            <div css={classes.dropDownContainer}>
              <Searchbox searchText={searchText} onChange={setSearchText} />
            </div>
          </div>
          <div css={classes.iconsOuter}>
            <div css={classes.iconsInner}>
              <Icons />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
