import { IModuleDefinition } from 'modules/types'
import { combineReducers } from 'redux'
import Activity, { rdot360ActivitydReducer } from './Activity'
import Balances, { rdot360BalanceReducer } from './Balances'
import Collaboration, { rdot360CollaborationReducer } from './Collaboration'
import Communications, { rdot360CommunicationsReducer } from './Communications'
import Dashboard, { rdot360DashboardReducer } from './Dashboard'
import Performance from './Dashboard/Performance'
import Documents from './Documents'
import GainAndLosses from './GainAndLosses'
import Income, { rdot360IncomeReducer } from './Income'
import Investments, { investmentsModuleReducer } from './Investments'
import Loans, { rootRdot360LoansReducer } from './Loans'
import { rdot360PerformanceReportsReducer } from './Performance/Reports'
import Revenue, { rdot360RevenueReducer } from './Revenue'

export default [
  Balances,
  Dashboard,
  Activity,
  Investments,
  Income,
  GainAndLosses,
  Revenue,
  Performance,
  Documents,
  Collaboration,
  Communications,
  Loans
] as IModuleDefinition[]

export const rdot360ModulesReducer = combineReducers({
  dashboard: rdot360DashboardReducer,
  activity: rdot360ActivitydReducer,
  balance: rdot360BalanceReducer,
  income: rdot360IncomeReducer,
  revenue: rdot360RevenueReducer,
  collaboration: rdot360CollaborationReducer,
  investments: investmentsModuleReducer,
  reports: rdot360PerformanceReportsReducer,
  communications: rdot360CommunicationsReducer,
  loans: rootRdot360LoansReducer
})
