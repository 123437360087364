import { useEffect, useState } from 'react'
import { ckEditor, getCKEditor } from 'shared/ckEditor/service'

export const useCKEditor = () => {
  const [, setIsFetching] = useState<boolean>(!!ckEditor)
  useEffect(() => {
    if (ckEditor) {
      return
    }

    setIsFetching(true)
    const init = async () => {
      await getCKEditor()
      setIsFetching(false)
    }

    init()
  }, [])

  return ckEditor
}
