import { Icon, TooltipHost } from '@fluentui/react'
import {
  IEmoneyAccountConnection,
  useGetEmoneyAccountConnections
} from './useGetEmoneyAccountConnections'

const getStatus = (
  emoneyAccountConnections: IEmoneyAccountConnection[],
  accountKey: string
) => {
  for (const connection of emoneyAccountConnections) {
    if (connection.accountKeys) {
      for (const key of connection.accountKeys) {
        if (key.trim() === accountKey) {
          return connection.status
        }
      }
    }
  }
  return ''
}

export const StaleDataIndicator: React.FC<{
  accountKey: string | undefined
}> = ({ accountKey }) => {
  const emoneyAccountConnections = useGetEmoneyAccountConnections()
  const status = getStatus(emoneyAccountConnections || [], accountKey || '')
  const isStatusOK = ['', 'UP-TO-DATE', 'PENDING'].includes(
    status.toUpperCase()
  )
  if (isStatusOK) {
    return null
  }
  return (
    <span
      css={{
        color: '#8B0000',
        paddingLeft: 5
      }}
    >
      <TooltipHost content={status}>
        <Icon iconName="info" styles={{ root: { cursor: 'pointer' } }} />
      </TooltipHost>
    </span>
  )
}
