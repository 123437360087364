import { skipToken } from '@reduxjs/toolkit/query'
import { useEffect, useMemo } from 'react'
import { getWellKnownFolderName } from '../functions'
import {
  useAddMessageAttachmentMutation,
  useDeleteMessageAttachmentMutation,
  useGetMessageAttachmentsQuery,
  useUpdateEmailMessageMutation
} from '../store/secureMessagesGraphApi'
import { useSecureMessagesSession } from './useSecureMessagesSession'
import { useSecureMessagesState } from './useSecureMessagesState'

export const useSecureMessagesEdit = () => {
  const { edits, updateEdits } = useSecureMessagesSession()
  const {
    selectedMessage: message,
    setCurrentView,
    setSelectedMessage
  } = useSecureMessagesState()

  const messageId = useMemo(() => message?.id, [message])
  const messageEdits = useMemo(() => {
    if (!edits || !messageId) {
      return
    }
    return edits[messageId]
  }, [edits, messageId])

  const initialBody = useMemo(
    () => messageEdits?.body ?? message?.body?.content,
    [message, messageEdits]
  )
  const initialSubject = useMemo(
    () => messageEdits?.subject ?? message?.subject,
    [message, messageEdits]
  )

  const { currentData: attachments, isLoading: isAttachmentsLoading } =
    useGetMessageAttachmentsQuery(
      messageId
        ? { mailFolderId: getWellKnownFolderName('drafts'), messageId }
        : skipToken
    )

  const [
    addMessageAttachments,
    { error: addAttachmentsError, isLoading: isAddAttachmentsLoading }
  ] = useAddMessageAttachmentMutation()

  const [
    deleteMessageAttachment,
    { error: deleteAttachmentError, isLoading: isDeleteAttachmentLoading }
  ] = useDeleteMessageAttachmentMutation()

  const [
    updateEmailMessage,
    {
      data: updatedMessage,
      error: updateMessageError,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess
    }
  ] = useUpdateEmailMessageMutation()

  const error = useMemo(
    () =>
      (addAttachmentsError as Error | undefined) ||
      (deleteAttachmentError as Error | undefined) ||
      (updateMessageError as Error | undefined),
    [addAttachmentsError, deleteAttachmentError, updateMessageError]
  )

  const isLoading = useMemo(
    () =>
      isAddAttachmentsLoading ||
      isAttachmentsLoading ||
      isDeleteAttachmentLoading ||
      isUpdateLoading,
    [
      isAddAttachmentsLoading,
      isAttachmentsLoading,
      isDeleteAttachmentLoading,
      isUpdateLoading
    ]
  )

  useEffect(() => {
    if (isUpdateSuccess) {
      if (updatedMessage) {
        setSelectedMessage(updatedMessage)
      }
      setCurrentView('read')

      if (messageId) {
        updateEdits({ [messageId]: { body: undefined, subject: undefined } })
      }
    }
  }, [
    isUpdateSuccess,
    messageId,
    setCurrentView,
    setSelectedMessage,
    updateEdits,
    updatedMessage
  ])

  return {
    addMessageAttachments,
    attachments,
    deleteMessageAttachment,
    error,
    initialBody,
    initialSubject,
    isLoading,
    isUpdateSuccess,
    message,
    messageId,
    updateEdits,
    updateEmailMessage
  }
}
