import { format } from 'date-fns'
import { SecureMessageFolder } from './types/secureMessagesTypes'

export const delay = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms))

export const formatDateTimeString = (value: string) => {
  return format(new Date(value), 'yyyy-MM-dd p')
}

export const getBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () =>
      resolve(reader.result?.toString().replace(/^.*,/, '') || '')
    reader.onerror = (error) => reject(error)
  })

export const getFileSize = (size: number) => {
  if (isNaN(size)) {
    return '--'
  }

  if (size < 1024) {
    return `${size} bytes`
  }

  if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(0)} KB`
  }

  if (size < 1024 * 1024 * 1024) {
    return `${(size / (1024 * 1024)).toFixed(0)} MB`
  }

  return `${(size / (1024 * 1024 * 1024)).toFixed(0)} GB`
}

export const getWellKnownFolderName = (folder: SecureMessageFolder) => {
  switch (folder) {
    case 'deleted':
      return 'deleteditems'
    case 'sent':
      return 'sentitems'
    default:
      return folder
  }
}
