import { IAccount } from 'api/account.types'
import { useRdot360Navigation } from 'modules/Advisory/modules/Rdot360/shared/useRdot360navigation'
import { useRdot360Context } from 'modules/Advisory/modules/Rdot360/store/rdot360Context'
import { useCallback, useMemo } from 'react'
import { AccountNumberCell } from '../shared/AccountNumberCell'

export const AccountNumberCellContainer: React.FC<{
  account: IAccount
  menu?: React.ReactElement | null
}> = ({ account, menu }) => {
  const { navigateToClientDashboard } = useRdot360Navigation()
  const { setHousehold } = useRdot360Context()

  const enableClick = useMemo(
    () => !!account.id && !!account.householdId,
    [account]
  )

  const handleAccountClicked = useCallback(
    (account?: IAccount) => {
      const { householdId, id } = account || {}
      if (!householdId || !id) {
        return
      }
      setHousehold({
        householdId,
        selectionType: 'account',
        selectionIds: [id]
      })
      navigateToClientDashboard()
    },
    [navigateToClientDashboard, setHousehold]
  )

  return (
    <AccountNumberCell
      account={account}
      menu={menu}
      onNumberClicked={enableClick ? handleAccountClicked : undefined}
    />
  )
}
