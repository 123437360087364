import {
  DatePicker,
  Dropdown,
  IDropdownOption,
  Label,
  Stack,
  TextField,
  Icon,
  Text,
  PrimaryButton,
  DateRangeType,
  DropdownMenuItemType,
  DirectionalHint
} from '@fluentui/react'
import { skipToken } from '@reduxjs/toolkit/query'
import { format, startOfMonth } from 'date-fns'
import { cloneDeepWith, has } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch
} from 'react-hook-form'
import { useSelector } from 'react-redux'
import { isNotNullOrUndefined } from 'shared/guards'
import {
  IHurdle,
  IHurdleMeasurement
} from '../../../../../../../../api/datahub'
import {
  IDynamicsCdmAdvisorRep,
  IDynamicsTeam
} from '../../../../../../../../api/dynamics'
import { parseDateISOStringInLocalTimezone } from '../../../../../../../../shared'
import { FormattedNumberField } from '../../../../../../../../shared/components/FormattedNumberField'
import { Separator } from '../../../../../../../../shared/components/Separator'
import {
  IHurdleRepCodeResponse,
  useGetRepCodesForIndividualQuery,
  useGetRepCodesForTeamQuery
} from '../../store/hurdlesDatahubApi'
import { getIsHurdleAdmin } from '../../store/selectors'
import { HurdleSelector } from '../EntitySearch/HurdleSelector'
import { RepEntitySelector } from '../EntitySearch/RepEntitySelector'
import { TeamEntitySelector } from '../EntitySearch/TeamEntitySelector'
import { IHurdleForm } from './HurdleEditPanel'
import { MeasurementAccordion } from './MeasurementAccordion'

const typeOptions: IDropdownOption[] = [
  { key: 'Team', text: 'Team' },
  { key: 'Individual', text: 'Individual' }
]

const completionTypeOptions: IDropdownOption[] = [
  { key: 'AllOrNothing', text: 'All or Nothing' },
  { key: 'OneAndDone', text: 'One and Done' }
]

const formatDate = (date: Date | undefined | null) =>
  date ? format(date, `MMM yyyy`) : ''

export const HurdleEditForm: React.FC<{ selectedHurdle: IHurdle }> = ({
  selectedHurdle
}) => {
  const register = useFormContext<IHurdleForm>()
  const { setValue, reset } = register
  const hurdle = useWatch<IHurdleForm>({ name: 'hurdle' }) as IHurdle
  const measurements = useFieldArray<IHurdleForm>({
    name: 'hurdle.measurements'
  })
  const watchedArray = useWatch<IHurdleForm>({
    name: 'hurdle.measurements'
  }) as IHurdleMeasurement[]

  const addMeasurement = useCallback(() => {
    measurements.append({
      intervalOfMeasurement: 'Annual',
      metrics: [{ metricType: 'T-12 From Hurdle', payouts: [] }]
    })
  }, [measurements])

  const onMeasurementDelete = useCallback(
    (index: number) => {
      measurements.remove(index)
    },
    [measurements]
  )

  const onMeasurementCopy = useCallback(
    (index: number) => {
      const measurementCopy = cloneDeepWith(watchedArray[index], (x) => {
        if (has(x, 'measurementId')) {
          x.measurementId = undefined
        }
        if (has(x, 'metricId')) {
          x.metricId = undefined
        }
        if (has(x, 'payoutId')) {
          x.payoutId = undefined
        }
      })
      measurements.insert(index + 1, measurementCopy)
    },
    [measurements, watchedArray]
  )

  const [expandAll, setExpandAll] = useState<boolean>(true)
  useEffect(() => {
    reset({ hurdle: selectedHurdle })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHurdle])

  const onEntityTypeChange = useCallback(() => {
    setValue('hurdle.entityName', undefined)
    setValue('hurdle.entityId', undefined)
    setValue('hurdle.advertisedT12Revenue', undefined)
  }, [setValue])

  const onTeamSelected = useCallback(
    (team?: IDynamicsTeam) => {
      if (!team) {
        return
      }
      setValue('hurdle.entityId', team.teamid, {
        shouldValidate: true
      })
      const advertisedT12Revenue =
        team.rcm_Team_Department_Team_Team?.[0]?.rcm_advertisedt12revenue
      const startDate = team?.rcm_Team_Department_Team_Team?.[0]?.rcm_startdate
      setValue('hurdle.advertisedT12Revenue', advertisedT12Revenue)
      setValue('hurdle.entityStartDate', startDate)
    },
    [setValue]
  )

  const onRepSelected = useCallback(
    (rep?: IDynamicsCdmAdvisorRep) => {
      if (!rep) {
        return
      }

      setValue('hurdle.entityId', rep?.rcm_repid, {
        shouldValidate: true
      })
    },
    [setValue]
  )

  const onDependentHurdleSelected = useCallback(
    (hurdle?: IHurdle) => {
      if (!hurdle) {
        return
      }

      setValue('hurdle.dependentHurdleId', hurdle.originalId, {
        shouldDirty: true
      })
      setValue('hurdle.dependentHurdle', hurdle)
    },
    [setValue]
  )
  const isHurdleAdmin = useSelector(getIsHurdleAdmin)
  const shouldDisable = useMemo(() => !isHurdleAdmin, [isHurdleAdmin])
  const skipTeamRepCall = useMemo(
    () => hurdle?.entityType !== 'Team' || !hurdle?.entityId,
    [hurdle?.entityId, hurdle?.entityType]
  )
  const skipIndividualRepCall = useMemo(
    () => hurdle?.entityType !== 'Individual' || !hurdle?.entityId,
    [hurdle?.entityId, hurdle?.entityType]
  )
  const { currentData: repsForTeam } = useGetRepCodesForTeamQuery(
    skipTeamRepCall ? skipToken : hurdle?.entityId || ''
  )
  const { currentData: repsForIndividual } = useGetRepCodesForIndividualQuery(
    skipIndividualRepCall ? skipToken : hurdle?.entityId || ''
  )
  const reps = useMemo(
    () => (hurdle?.entityType === 'Team' ? repsForTeam : repsForIndividual),
    [hurdle?.entityType, repsForIndividual, repsForTeam]
  )
  const selectedRepCodes = useMemo(
    () =>
      hurdle?.repCodes?.map((x) => x.repCode)?.filter(isNotNullOrUndefined) ||
      [],
    [hurdle?.repCodes]
  )
  const onRepCodeChange = useCallback(
    (ev: any, option?: IDropdownOption) => {
      if (!option?.key) {
        return
      }
      if (option?.selected) {
        setValue(
          'hurdle.repCodes',
          [
            ...(hurdle?.repCodes || []),
            { repCode: option?.key as string, includeOrExclude: 'Exclude' }
          ],
          { shouldDirty: true }
        )
      } else {
        setValue(
          'hurdle.repCodes',
          [
            ...(hurdle?.repCodes || []).filter((x) => x.repCode !== option?.key)
          ],
          { shouldDirty: true }
        )
      }
    },
    [hurdle?.repCodes, setValue]
  )
  return (
    <Stack tokens={{ childrenGap: 5 }}>
      <Stack>
        <Label required>Hurdle Name</Label>
        <Controller
          name="hurdle.name"
          control={register.control}
          rules={{ required: 'Enter Hurdle Name' }}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <TextField
                value={value || ''}
                errorMessage={error && error.message}
                onChange={(_e, name) => {
                  onChange(name)
                }}
                disabled={shouldDisable}
              />
            )
          }}
        />
      </Stack>
      <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
        <Stack grow={1} styles={{ root: { flexBasis: 0 } }}>
          <Label required>Team / Individual</Label>
          <Controller
            name="hurdle.entityType"
            control={register.control}
            rules={{ required: 'Select Team or Individual' }}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Dropdown
                  selectedKey={value || null}
                  errorMessage={error && error.message}
                  onChange={(_e, type) => {
                    onChange(type?.key)
                    onEntityTypeChange()
                  }}
                  placeholder="Select an Type"
                  options={typeOptions}
                  disabled={shouldDisable}
                />
              )
            }}
          />
        </Stack>
        {hurdle?.entityType === 'Individual' ? (
          <Stack grow={1} styles={{ root: { flexBasis: 0 } }}>
            <Stack>
              {hurdle?.entityName ? (
                <Label required>Selected Individual</Label>
              ) : (
                <Label required>Search for Individual</Label>
              )}
              <Controller
                name="hurdle.entityName"
                control={register.control}
                rules={{ required: 'Select Team or Individual' }}
                render={({
                  field: { value, onChange },
                  fieldState: { error }
                }) => {
                  return (
                    <Stack>
                      <RepEntitySelector
                        onSelectedEntityChanged={(rep) => {
                          onChange(rep?.rcm_name)
                          onRepSelected(rep)
                        }}
                        selectedText={value}
                        disabled={shouldDisable}
                      />
                      {error && <Text>{error.message}</Text>}
                    </Stack>
                  )
                }}
              />
            </Stack>
          </Stack>
        ) : (
          <Stack grow={1} styles={{ root: { flexBasis: 0 } }}>
            <Stack>
              {hurdle?.entityName ? (
                <Label required>Selected Team</Label>
              ) : (
                <Label required> Search for Team</Label>
              )}
              <Controller
                name="hurdle.entityName"
                control={register.control}
                rules={{ required: 'Select Team or Individual' }}
                render={({
                  field: { value, onChange },
                  fieldState: { error }
                }) => {
                  return (
                    <TeamEntitySelector
                      onSelectedEntityChanged={(team) => {
                        onChange(team?.name)
                        onTeamSelected(team)
                      }}
                      selectedText={value}
                      errorMessage={error && error.message}
                      disabled={shouldDisable}
                    />
                  )
                }}
              />
            </Stack>
          </Stack>
        )}
      </Stack>
      <Stack grow={1} styles={{ root: { flexBasis: 0 } }}>
        <Label required>Dependent Hurdle</Label>
        <Controller
          name="hurdle.dependentHurdle"
          control={register.control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Stack>
                <HurdleSelector
                  onSelectedEntityChanged={(hurdle) => {
                    onChange(hurdle?.name)
                    onDependentHurdleSelected(hurdle)
                  }}
                  selectedText={value?.name}
                  disabled={shouldDisable}
                />
                {error && <Text>{error.message}</Text>}
              </Stack>
            )
          }}
        />
      </Stack>
      <Stack grow={1} styles={{ root: { flexBasis: 0 } }}>
        <Label>Exclude Rep Codes</Label>
        <RepCodeSelector
          reps={reps}
          selectedReps={selectedRepCodes}
          onRepCodeChange={onRepCodeChange}
          disabled={!reps || shouldDisable}
        />
      </Stack>
      <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
        <Stack grow={1} styles={{ root: { flexBasis: 0 } }}>
          <Label required>Advertised T12</Label>
          <Controller
            name="hurdle.advertisedT12Revenue"
            control={register.control}
            rules={{ required: 'Enter T12 Production' }}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <FormattedNumberField
                  errorMessage={error && error.message}
                  value={value}
                  onChange={(x) => onChange(x != null ? x : null)}
                  prefix="$"
                  disabled={shouldDisable}
                />
              )
            }}
          />
        </Stack>
        <Stack grow={1} styles={{ root: { flexBasis: 0 } }}>
          <Label required>Hurdle Completion Type</Label>
          <Controller
            name="hurdle.completionType"
            control={register.control}
            rules={{ required: 'Select Completion Type' }}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Dropdown
                  selectedKey={value || null}
                  errorMessage={error && error.message}
                  onChange={(ev, option) => onChange(option?.key)}
                  placeholder="Select an Type"
                  options={completionTypeOptions}
                  disabled={shouldDisable}
                />
              )
            }}
          />
        </Stack>
      </Stack>
      <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
        <Stack grow={1} styles={{ root: { flexBasis: 0 } }}>
          <Label>
            {hurdle?.entityType === 'Individual' ? 'Individual' : 'Team'} Start
            Date
          </Label>
          <Controller
            name="hurdle.entityStartDate"
            control={register.control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <DatePicker
                  placeholder="Date"
                  value={
                    value ? parseDateISOStringInLocalTimezone(value) : undefined
                  }
                  onSelectDate={(date) =>
                    onChange(date ? startOfMonth(date).toISOString() : '')
                  }
                  calendarProps={{
                    dateRangeType: DateRangeType.Month,
                    isDayPickerVisible: false
                  }}
                  formatDate={formatDate}
                  styles={{ statusMessage: { margin: '0' } }}
                  textField={error && { errorMessage: error.message }}
                  disabled={shouldDisable}
                />
              )
            }}
          />
        </Stack>
        <Stack grow={1} styles={{ root: { flexBasis: 0 } }}>
          <Label>Accrual Start Date</Label>
          <Controller
            name="hurdle.accrualStartDate"
            control={register.control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <DatePicker
                  placeholder="Date"
                  value={
                    value ? parseDateISOStringInLocalTimezone(value) : undefined
                  }
                  onSelectDate={(date) =>
                    onChange(date ? startOfMonth(date).toISOString() : '')
                  }
                  calendarProps={{
                    dateRangeType: DateRangeType.Month,
                    isDayPickerVisible: false
                  }}
                  formatDate={formatDate}
                  styles={{ statusMessage: { margin: '0' } }}
                  textField={error && { errorMessage: error.message }}
                  disabled={shouldDisable}
                />
              )
            }}
          />
        </Stack>
        <Stack grow={1} styles={{ root: { flexBasis: 0 } }}>
          <Label>Term</Label>
          <Controller
            name="hurdle.term"
            control={register.control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <TextField
                  errorMessage={error && error.message}
                  value={value?.toString() || ''}
                  onChange={(ev, value) =>
                    onChange(value ? parseInt(value) : null)
                  }
                  type="number"
                  disabled={shouldDisable}
                />
              )
            }}
          />
        </Stack>
      </Stack>

      <Stack grow={1} styles={{ root: { flexBasis: 0 } }}>
        <Label>Notes</Label>
        <Controller
          name="hurdle.notes"
          control={register.control}
          rules={{ maxLength: 2000 }}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <TextField
                value={value || ''}
                errorMessage={error && error.message}
                onChange={(_e, note) => {
                  onChange(note)
                }}
                multiline={true}
                disabled={shouldDisable}
              />
            )
          }}
        />
      </Stack>
      <Separator />
      <Stack
        horizontal={true}
        horizontalAlign="space-between"
        verticalAlign="start"
      >
        <div
          onClick={() => setExpandAll(!expandAll)}
          style={{ cursor: 'pointer' }}
        >
          <Stack
            horizontal={true}
            verticalAlign="center"
            tokens={{ childrenGap: 5 }}
          >
            <Icon iconName={expandAll ? 'ChevronUp' : 'ChevronDown'} />
            <h4 style={{ margin: '5px' }}>Measurements</h4>
          </Stack>
        </div>
        <PrimaryButton
          onClick={addMeasurement}
          iconProps={{ iconName: 'Add' }}
          disabled={shouldDisable}
        >
          Measurement
        </PrimaryButton>
      </Stack>
      <Stack>
        {measurements?.fields?.map((measurement, index) => (
          <MeasurementAccordion
            onMeasurementDelete={
              measurements?.fields?.length > 1
                ? () => onMeasurementDelete(index)
                : undefined
            }
            onMeasurementCopy={() => onMeasurementCopy(index)}
            title={`Measurement ${index + 1}`}
            expand={expandAll}
            key={measurement.id}
            name={`hurdle.measurements.${index}`}
            disabled={shouldDisable}
          />
        ))}
      </Stack>
    </Stack>
  )
}

const RepCodeSelector: React.FC<{
  reps?: IHurdleRepCodeResponse
  selectedReps: string[]
  onRepCodeChange: (ev: any, option?: IDropdownOption) => void
  disabled?: boolean
}> = ({ reps, selectedReps, onRepCodeChange, disabled }) => {
  const individuals = useMemo(
    () =>
      reps?.individuals?.map((x) => ({
        key: x.repCode || '',
        text: `${x.repCode} - ${x.name}`
      })) || [],
    [reps]
  )
  const pools = useMemo(
    () =>
      reps?.pools?.map((x) => ({
        key: x.repCode || '',
        text: `${x.repCode} - ${x.name}`
      })) || [],
    [reps]
  )
  const options = useMemo(
    () =>
      individuals?.length || pools?.length
        ? [
            individuals.length
              ? {
                  key: 'Individuals',
                  text: 'Individuals',
                  itemType: DropdownMenuItemType.Header
                }
              : undefined,
            ...individuals,
            individuals.length && pools.length
              ? {
                  key: 'divider',
                  text: '-',
                  itemType: DropdownMenuItemType.Divider
                }
              : undefined,
            pools.length
              ? {
                  key: 'Pools',
                  text: 'Pools',
                  itemType: DropdownMenuItemType.Header
                }
              : undefined,
            ...pools
          ].filter(isNotNullOrUndefined)
        : [
            {
              key: 'none',
              text: 'No Rep Codes Found',
              itemType: DropdownMenuItemType.Header
            }
          ],
    [individuals, pools]
  )
  return (
    <Dropdown
      options={options}
      multiSelect
      calloutProps={{
        directionalHint: DirectionalHint.bottomCenter,
        directionalHintFixed: true
      }}
      selectedKeys={selectedReps}
      onChange={onRepCodeChange}
      disabled={disabled}
    />
  )
}
