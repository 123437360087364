import {
  DefaultButton,
  MessageBar,
  MessageBarType,
  Overlay,
  Panel,
  PanelType,
  PrimaryButton,
  Stack
} from '@fluentui/react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { IHurdle } from '../../../../../../../../api/datahub'
import { ErrorComponent } from '../../../../../../../../shared/components/Error'
import { LoadingComponent } from '../../../../../../../../shared/components/Loading'
import { HurdleEditForm } from './HurdleEditForm'
import { useLazyCheckForHurdleQuery } from './store/datahub'
import { getHurdleGroup } from './store/hurdleEditForm'
import { useHurdleEditPanel } from './store/hurdleEditPanel'
import { getHurdleFetchLoading } from './store/hurdleFetch'
export interface IHurdleForm {
  hurdle: IHurdle
}

export const HurdleEditPanel: React.FC = () => {
  const {
    close,
    error,
    submit,
    isOpen: isPanelOpen,
    isLoading: isPanelLoading,
    setError
  } = useHurdleEditPanel()
  const isFetchLoading = useSelector(getHurdleFetchLoading)
  const isLoading = useMemo(
    () => isPanelLoading || isFetchLoading,
    [isFetchLoading, isPanelLoading]
  )
  const methods = useForm<IHurdleForm>()
  const { reset, handleSubmit, formState } = methods
  const { dirtyFields, isDirty } = formState
  const onDismiss = useCallback(() => {
    close()
    reset()
  }, [close, reset])

  const [checkForHurdle] = useLazyCheckForHurdleQuery()
  const onSubmit = useCallback(
    async (data?: { hurdle?: IHurdle }) => {
      if (!data) {
        return
      }

      if (data.hurdle?.hurdleId) {
        try {
          await checkForHurdle(data.hurdle?.hurdleId).unwrap()
        } catch (e) {
          setError(new Error('Hurdle not found. Please refresh page.'))
          return
        }
      }

      const dirtyNames = Object.entries(dirtyFields?.hurdle || {})
        .filter(([, value]) => !!value)
        .map(([name]) => name)
      const patchFields = [
        'name',
        'entityStartDate',
        'accrualStartDate',
        'term',
        'notes',
        'repCodes',
        'dependentHurdleId',
        'dependentHurdle'
      ]
      const canPatch =
        dirtyNames.filter((name) => !patchFields.includes(name)).length < 1

      submit({
        ...data.hurdle,
        measurements:
          canPatch && data.hurdle?.hurdleId
            ? undefined
            : data.hurdle?.measurements,
        dependentHurdle: undefined
      })
    },
    [checkForHurdle, dirtyFields?.hurdle, setError, submit]
  )

  const [showValidationError, setShowValidationError] = useState(false)
  useEffect(() => {
    if (!isPanelOpen) {
      return
    }
    setShowValidationError(false)
  }, [isPanelOpen])
  const onError = useCallback(() => {
    setShowValidationError(true)
  }, [])

  const onRenderFooterContent = useCallback(() => {
    return (
      <Stack tokens={{ childrenGap: 10 }}>
        {error && (
          <MessageBar messageBarType={MessageBarType.error}>
            {error?.message}
          </MessageBar>
        )}
        {showValidationError && (
          <ErrorComponent errorMessage="The form failed to validate." />
        )}
        <Stack
          horizontal={true}
          tokens={{ childrenGap: 10 }}
          horizontalAlign="space-between"
        >
          <Stack
            horizontal={true}
            tokens={{ childrenGap: 10 }}
            verticalAlign="center"
          >
            <PrimaryButton type="submit" form="hurdleForm" disabled={!isDirty}>
              Save
            </PrimaryButton>

            <DefaultButton onClick={onDismiss}>Cancel</DefaultButton>
          </Stack>
        </Stack>
      </Stack>
    )
  }, [error, isDirty, onDismiss, showValidationError])

  const selectedHurdle = useSelector(getHurdleGroup)

  return (
    <FormProvider {...methods}>
      <form id="hurdleForm" onSubmit={handleSubmit(onSubmit, onError)}>
        <Panel
          headerText={`Create New Hurdle`}
          isOpen={isPanelOpen}
          onDismiss={onDismiss}
          isLightDismiss={false}
          isBlocking={true}
          closeButtonAriaLabel="Close"
          type={PanelType.custom}
          customWidth="700px"
          onRenderFooterContent={onRenderFooterContent}
          isFooterAtBottom={true}
          styles={{ content: { flexGrow: 1 } }}
        >
          {isLoading && (
            <Overlay styles={{ root: { zIndex: 1 } }}>
              <LoadingComponent />
            </Overlay>
          )}

          <HurdleEditForm selectedHurdle={selectedHurdle} />
        </Panel>
      </form>
    </FormProvider>
  )
}
