import { ConfirmModal } from './ConfirmModal'

export interface IAttachModalProps {
  isModalOpen: boolean
  onCancel: () => void
  onSend: () => void
}

export const AttachModal: React.FC<IAttachModalProps> = ({
  isModalOpen,
  onCancel,
  onSend
}) => {
  return (
    isModalOpen && (
      <ConfirmModal
        message="You are attaching one or more documents to be sent to the client."
        onDismiss={onCancel}
        onPrimaryClick={onSend}
        onSeconardyClick={onCancel}
        primaryText="OK"
        secondaryText="Cancel"
        width={650}
      />
    )
  )
}
