import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { TagDescription } from '@reduxjs/toolkit/query'
import axios from 'axios'
import pLimit from 'p-limit'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { IOdataResult } from 'shared/contracts/IOdataResult'
import { graph_v1 } from 'store/api/graph_v1'
import { AxiosBaseArgs } from 'store/api/shared'
import { delay, getBase64 } from '../functions'
import {
  IGraphAttachment,
  IGraphMailFolder,
  IGraphMessage,
  IGraphMessageUpdate,
  IGraphUploadSession
} from '../types'

interface IAddMessageAttachments {
  attachments: File[]
  messageId: string
}

interface IDeleteEmailMessages {
  mailFolderId: string
  messageIds: string[]
}

interface IDeleteMessageAttachment {
  attachmentId: string
  messageId: string
}

interface IGetEmailAttachments {
  mailFolderId: string
  messageId: string
}

interface IGetEmailMessages {
  mailFolderId: string
  search?: string
}

interface IMoveEmailMessages {
  destinationId: string
  mailFolderId: string
  messageIds: string[]
}

interface IReplyEmailMessage {
  comment: string
  messageId: string
}

interface IUpdateEmailMessage {
  messageId: string
  messageUpdate: IGraphMessageUpdate
}

type SecureMessagesTagType =
  | 'attachments'
  | 'mailFolders'
  | 'message'
  | 'messages'

const attachmentSizeThreshold: number = 3145728
const mailboxConcurrencyLimit: number = 4

const secureMessagesTags: SecureMessagesTagType[] = [
  'attachments',
  'mailFolders',
  'message',
  'messages'
]

const graphApiWithSecureMessagesTags = graph_v1.enhanceEndpoints({
  addTagTypes: secureMessagesTags
})

const secureMessagesGraphApi = graphApiWithSecureMessagesTags.injectEndpoints({
  endpoints: (builder) => ({
    addMessageAttachment: builder.mutation<void, IAddMessageAttachments>({
      queryFn: async (
        { attachments, messageId },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        try {
          for (let a = 0; a < attachments.length; a++) {
            const attachment = attachments[a]
            if (attachment.size < attachmentSizeThreshold) {
              const base64 = await getBase64(attachment)
              const result = (await baseQuery({
                url: `/me/messages/${messageId}/attachments`,
                method: 'POST',
                data: {
                  '@odata.type': '#microsoft.graph.fileAttachment',
                  name: attachment.name,
                  contentBytes: base64
                }
              })) as QueryReturnValue<unknown, Error>
              if (result.error) {
                throw result.error
              }
            } else {
              const uploadSession = (await baseQuery({
                url: `/me/messages/${messageId}/attachments/createUploadSession`,
                method: 'POST',
                data: {
                  AttachmentItem: {
                    attachmentType: 'file',
                    name: attachment.name,
                    size: attachment.size
                  }
                }
              })) as QueryReturnValue<IGraphUploadSession, Error>
              const uploadUrl = uploadSession?.data?.uploadUrl
              if (!uploadUrl) {
                throw new Error('Failed to create upload session')
              }

              const buffer = await attachment.arrayBuffer()
              const uploadResponse = await axios.put(uploadUrl, buffer, {
                headers: {
                  'Content-Range': `bytes 0-${attachment.size - 1}/${
                    attachment.size
                  }`,
                  'Content-Type': attachment.type
                }
              })
              if (uploadResponse.status !== 201) {
                throw new Error(
                  `Failed to upload attachment: ${attachment.name}`
                )
              }
            }
          }

          return { data: undefined }
        } catch (error) {
          return { error }
        }
      },
      invalidatesTags: ['attachments']
    }),
    deleteEmailMessages: builder.mutation<void, IDeleteEmailMessages>({
      queryFn: async (
        { mailFolderId, messageIds },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        const limit = pLimit(mailboxConcurrencyLimit)
        const requests = messageIds.map(
          (messageId): AxiosBaseArgs => ({
            url: `me/mailFolders/${mailFolderId}/messages/${messageId}`,
            method: 'DELETE'
          })
        )
        const results = await Promise.all(
          requests.map((x) =>
            limit(async () => {
              const result = (await baseQuery(x)) as QueryReturnValue<
                void,
                Error
              >
              if (result.error) {
                limit.clearQueue()
              }
              return result
            })
          )
        )

        const error = results.find((result) => result.error)
        if (error) {
          return { error }
        }

        return { data: undefined }
      },
      invalidatesTags: ['messages']
    }),
    deleteMessageAttachment: builder.mutation<void, IDeleteMessageAttachment>({
      query: ({ attachmentId, messageId }) => ({
        url: `/me/messages/${messageId}/attachments/${attachmentId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['attachments']
    }),
    getMailFolders: builder.query<IGraphMailFolder[] | undefined, void>({
      query: () => `/me/mailFolders?$top=100`,
      providesTags: ['mailFolders'],
      transformResponse: (response: IOdataResult<IGraphMailFolder>) =>
        response.value
    }),
    getMessage: builder.query<IGraphMessage, string>({
      query: (messageId) => `me/messages/${messageId}`,
      providesTags: ['message']
    }),
    getMessageAttachments: builder.query<
      IGraphAttachment[] | undefined,
      IGetEmailAttachments
    >({
      query: ({ mailFolderId, messageId }) =>
        `/me/mailFolders/${mailFolderId}/messages/${messageId}/attachments`,
      transformResponse: (response: IOdataResult<IGraphAttachment>) =>
        response.value,
      providesTags: ['attachments']
    }),
    getMessagesWithClient: builder.query<
      IGraphMessage[] | undefined,
      IGetEmailMessages
    >({
      query: ({ mailFolderId, search }) =>
        `/me/mailFolders/${mailFolderId}/messages?$top=500&$search="${
          search || ''
        }"`,
      providesTags: (_result, _error, { mailFolderId }) => [
        'messages',
        { type: 'messages', id: mailFolderId }
      ],
      transformResponse: (response: IOdataResult<IGraphMessage>) =>
        response.value
    }),
    getUnreadMessagesWithClient: builder.query<
      IGraphMessage[] | undefined,
      string
    >({
      query: (search) =>
        `/me/messages?$top=1000&$select=id,parentFolderId&$search="(${search}) AND (isRead:false)"`,
      providesTags: ['messages', { type: 'messages', id: 'unread' }],
      transformResponse: (response: IOdataResult<IGraphMessage>) =>
        response.value
    }),
    moveEmailMessages: builder.mutation<IGraphMessage[], IMoveEmailMessages>({
      queryFn: async (
        { destinationId, mailFolderId, messageIds },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        const limit = pLimit(mailboxConcurrencyLimit)
        const requests = messageIds.map(
          (messageId): AxiosBaseArgs => ({
            url: `me/mailFolders/${mailFolderId}/messages/${messageId}/move`,
            method: 'POST',
            data: { destinationId }
          })
        )

        const results = await Promise.all(
          requests.map((x) =>
            limit(async () => {
              const result = (await baseQuery(x)) as QueryReturnValue<
                IGraphMessage,
                Error
              >
              if (result.error) {
                limit.clearQueue()
              }
              return result
            })
          )
        )

        const error = results.find((result) => result.error)
        if (error) {
          return { error }
        }

        return {
          data: results
            .flatMap((result) => result.data)
            .filter((message) => message !== undefined)
        }
      },
      invalidatesTags: ['messages']
    }),
    replyEmailMessageAll: builder.mutation<void, IReplyEmailMessage>({
      query: ({ comment, messageId }) => ({
        url: `me/messages/${messageId}/replyAll`,
        method: 'POST',
        headers: {
          Prefer: `outlook.timezone="${
            Intl.DateTimeFormat().resolvedOptions().timeZone
          }"`
        },
        data: { comment }
      })
    }),
    sendDraftEmailMessage: builder.mutation<void, string>({
      queryFn: async (messageId, _api, _extraOptions, baseQuery) => {
        const response = (await baseQuery({
          url: `me/messages/${messageId}/send`,
          method: 'POST'
        })) as QueryReturnValue<void, Error>

        const error = response.error
        if (error) {
          return { error }
        }

        // Add delay to allow draft message to be sent
        await delay(1000)

        return { data: undefined }
      },
      invalidatesTags: ['messages']
    }),
    updateEmailMessage: builder.mutation<IGraphMessage, IUpdateEmailMessage>({
      query: ({ messageId, messageUpdate }) => ({
        url: `me/messages/${messageId}`,
        method: 'PATCH',
        data: { ...messageUpdate }
      }),
      invalidatesTags: ['messages']
    })
  })
})

export const {
  useAddMessageAttachmentMutation,
  useDeleteEmailMessagesMutation,
  useDeleteMessageAttachmentMutation,
  useGetMailFoldersQuery,
  useGetMessageQuery,
  useGetMessageAttachmentsQuery,
  useGetMessagesWithClientQuery,
  useGetUnreadMessagesWithClientQuery,
  useMoveEmailMessagesMutation,
  useReplyEmailMessageAllMutation,
  useSendDraftEmailMessageMutation,
  useUpdateEmailMessageMutation
} = secureMessagesGraphApi

export const useSecureMessagesGraphApiUtil = () => {
  const dispatch = useDispatch()

  const invalidateTags = useCallback(
    (tags: TagDescription<SecureMessagesTagType>[]) => {
      dispatch(secureMessagesGraphApi.util.invalidateTags(tags))
    },
    [dispatch]
  )

  return { invalidateTags }
}
