import { SortDirection } from '@tanstack/react-table'
import { HeaderContainer } from 'modules/Advisory/modules/Rdot360/components/shared/DetailTables/HeaderContainer'
import { IndeterminateCheckbox } from 'modules/Advisory/modules/Rdot360/shared/IndeterminateCheckbox'

export interface ICheckboxHeaderProps {
  checked: boolean
  indeterminate: boolean
  isSorted: false | SortDirection
  onChange: () => void
  title: string
}

export const CheckboxHeader: React.FC<ICheckboxHeaderProps> = ({
  checked,
  indeterminate,
  isSorted,
  onChange,
  title
}) => {
  return (
    <div style={{ display: 'flex', columnGap: '10px', alignItems: 'center' }}>
      <IndeterminateCheckbox
        style={{ cursor: 'pointer' }}
        type="checkbox"
        indeterminate={indeterminate}
        checked={checked}
        onClick={(e) => e.stopPropagation()}
        onChange={onChange}
      />
      <HeaderContainer title={title} isSorted={isSorted} />
    </div>
  )
}
