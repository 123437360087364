import { ConfirmModal } from './ConfirmModal'

export interface ISendModalProps {
  isModalOpen: boolean
  onCancel: () => void
  onSend: () => void
}

export const SendModal: React.FC<ISendModalProps> = ({
  isModalOpen,
  onCancel,
  onSend
}) => {
  return (
    isModalOpen && (
      <ConfirmModal
        message="Message is blank, send anyway?"
        onDismiss={onCancel}
        onPrimaryClick={onSend}
        onSeconardyClick={onCancel}
        primaryText="Send"
        secondaryText="Cancel"
        width={450}
      />
    )
  )
}
