import { rdot360Api } from 'store/api/rdot360'
import { apiConstants } from './apis'

interface IEmoneyAccountConnection {
  accountKeys?: []
  id?: string
  sourceId: string
  status: string
  statusDescription: string
  statusLevel: string
  lastSuccessfulUpdate: string
  lastUpdateAttempt: string
  name: string
  permissions: string
  ownerUserId: string
}

const { cacheTime } = apiConstants

const emoneyAccountConnectionsApi = rdot360Api.injectEndpoints({
  endpoints: (builder) => ({
    getEmoneyAccountConnections: builder.query<
      IEmoneyAccountConnection[] | undefined,
      { externalAccountKeys: string[] | undefined }
    >({
      query: ({ externalAccountKeys }) => ({
        url: 'assetag/api/emoney/account/connections',
        method: 'POST',
        data: externalAccountKeys
      }),
      keepUnusedDataFor: cacheTime
    })
  })
})

export const { useGetEmoneyAccountConnectionsQuery } =
  emoneyAccountConnectionsApi
