import { flexRender } from '@tanstack/react-table'
import { Row } from '@tanstack/table-core'
import { useMemo } from 'react'
import { IActivity } from '../../../../store/types'
import { activityDetailColumns } from '../../shared'
import { PrintDescription } from './PrintDescription'
import { PrintDescriptionMasked } from './PrintDescriptionMasked'
import { PrintSymbolCusipCell } from './PrintSymbolCusipCell'

export const PrintTableBody: React.FC<{
  rows: Row<IActivity>[]
  masked: boolean
}> = ({ rows, masked }) => {
  const lookUp = useMemo(
    () => ({
      [activityDetailColumns.symbolCusip]: PrintSymbolCusipCell,
      [activityDetailColumns.description]: masked
        ? PrintDescriptionMasked
        : PrintDescription
    }),
    [masked]
  )

  const printCellLookUp = (columnId?: string) => {
    return columnId ? lookUp[columnId] : undefined
  }

  return (
    <tbody>
      {rows.map((row) => {
        const visibleCells = row.getVisibleCells()
        return (
          <tr key={row.id}>
            {visibleCells.map((cell) => {
              const printCell = printCellLookUp(cell.column.columnDef.id)
              const context = cell.getContext()
              return (
                <td key={cell.id}>
                  {flexRender(printCell ?? cell.column.columnDef.cell, context)}
                </td>
              )
            })}
          </tr>
        )
      })}
    </tbody>
  )
}
