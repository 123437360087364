import { skipToken } from '@reduxjs/toolkit/query'
import { endOfDay, startOfDay } from 'date-fns'
import { useCallback, useMemo } from 'react'
import { delay, getWellKnownFolderName } from '../functions'
import {
  useDeleteEmailMessagesMutation,
  useGetMessagesWithClientQuery,
  useMoveEmailMessagesMutation,
  useSecureMessagesGraphApiUtil
} from '../store/secureMessagesGraphApi'
import { SecureMessageFolder } from '../types'
import { useSecureMessagesMailbox } from './useSecureMessagesMailbox'
import { useSecureMessagesState } from './useSecureMessagesState'

export const useSecureMessagesList = (folder: SecureMessageFolder) => {
  const { mailboxPrincipalNames, isMailboxesFetching, mailboxesError } =
    useSecureMessagesMailbox()

  const { currentView, endDate, startDate, rowSelection, setRowSelection } =
    useSecureMessagesState()

  const mailFolderId = useMemo(
    () => getWellKnownFolderName(currentView as SecureMessageFolder),
    [currentView]
  )

  const search = useMemo(() => {
    if (!mailboxPrincipalNames.length) {
      return
    }

    const params = [
      `(${mailboxPrincipalNames.map((x) => `participants:${x}`).join(' OR ')})`
    ]

    let dateField: string | undefined
    if (currentView === 'inbox' || currentView === 'deleted') {
      dateField = 'received'
    } else if (currentView === 'sent') {
      dateField = 'sent'
    }

    if (dateField) {
      if (startDate) {
        params.push(`${dateField} >= ${startOfDay(startDate).toISOString()}`)
      }

      if (endDate) {
        params.push(`${dateField} <= ${endOfDay(endDate).toISOString()}`)
      }
    }

    return params.join(' AND ')
  }, [currentView, endDate, mailboxPrincipalNames, startDate])

  const {
    currentData: clientMessages,
    isFetching: isMessagesFetching,
    error: messagesError
  } = useGetMessagesWithClientQuery(
    search
      ? {
          mailFolderId: getWellKnownFolderName(folder),
          search
        }
      : skipToken
  )

  const [
    deleteMessages,
    { error: deleteMessagesError, isLoading: isDeleteMessagesLoading }
  ] = useDeleteEmailMessagesMutation()

  const deleteSelectedMessages = useCallback(() => {
    const messageIds = Object.keys(rowSelection)
    if (!messageIds.length) {
      return
    }

    deleteMessages({ mailFolderId, messageIds })
    setRowSelection({})
  }, [deleteMessages, mailFolderId, rowSelection, setRowSelection])

  const [
    moveMessages,
    { error: moveMessagesError, isLoading: isMoveMessagesLoading }
  ] = useMoveEmailMessagesMutation()

  const moveSelectedMessages = useCallback(
    (destination: SecureMessageFolder) => {
      const messageIds = Object.keys(rowSelection)
      if (!messageIds.length) {
        return
      }
      const destinationId = getWellKnownFolderName(destination)

      moveMessages({ destinationId, mailFolderId, messageIds })
      setRowSelection({})
    },
    [mailFolderId, moveMessages, rowSelection, setRowSelection]
  )

  const { invalidateTags } = useSecureMessagesGraphApiUtil()

  const refetchMessages = useCallback(async () => {
    setRowSelection({})
    await delay(1000)
    invalidateTags(['attachments', 'messages'])
  }, [invalidateTags, setRowSelection])

  const error = useMemo(
    () =>
      (mailboxesError as Error | undefined) ||
      (messagesError as Error | undefined) ||
      (deleteMessagesError as Error | undefined) ||
      (moveMessagesError as Error | undefined),
    [deleteMessagesError, mailboxesError, messagesError, moveMessagesError]
  )

  const isLoading = useMemo(
    () =>
      isMailboxesFetching ||
      isMessagesFetching ||
      isDeleteMessagesLoading ||
      isMoveMessagesLoading,
    [
      isDeleteMessagesLoading,
      isMailboxesFetching,
      isMessagesFetching,
      isMoveMessagesLoading
    ]
  )

  return {
    clientMessages,
    error,
    isLoading,
    deleteSelectedMessages,
    moveSelectedMessages,
    refetchMessages
  }
}
