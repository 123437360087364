import { css } from '@emotion/react'
import { flexRender, Header, Row } from '@tanstack/react-table'
import { constants } from 'modules/Advisory/modules/Rdot360/shared/theme'
import { IBalanceDetailResponseValueItem } from 'modules/Advisory/modules/Rdot360/store/balancesApi/IBalanceDetailResponse'
import { FC } from 'react'
import { theme } from 'shared/theme'
import { SnackBar } from 'shared/uiSharedComponents/Snackbar'
import { getMLBorderStyles } from '../../../features/Loans/getBorderStyles'
import { textAlignCenter } from '../../../features/Loans/styles'
import { TableCell } from '../../../features/Loans/TableCellsGeneric'

const classes = {
  tableBase: css({
    tableLayout: 'fixed',
    width: '100%',
    boxSizing: 'border-box',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    marginBottom: 8,
    'th, td': {
      fontSize: 13,
      padding: 8
    }
  }),
  tableHeader: css({
    backgroundColor: '#dfe8ee',
    minWidth: 1400,
    position: 'sticky',
    top: constants.blueBarHeight + constants.headerHeight,
    th: {
      verticalAlign: 'top',
      paddingTop: 10,
      paddingBottom: 10,
      textAlign: 'right',
      '&.centered': [
        textAlignCenter,
        {
          backgroundColor: theme.colors.secondaryBlue2,
          paddingTop: 2,
          paddingBottom: 2
        }
      ]
    }
  }),
  tableContent: css({
    tbody: {
      border: '1px solid #E6E6E6',
      tr: {
        ':first-of-type': {
          backgroundColor: '#EBF6FC'
        },
        '&.headerRow': {
          borderTop: '1px solid #E6E6E6'
        }
      },
      td: {
        color: '#080808',
        verticalAlign: 'top',
        textAlign: 'right',
        ':first-of-type': {
          verticalAlign: 'middle' // 'Display' cell vertical alignment
        }
      }
    }
  }),
  tableFooter: css({
    backgroundColor: '#cde2f2',
    position: 'sticky',
    bottom: 0,
    td: {
      fontWeight: 'bold',
      textAlign: 'right',
      paddingTop: 10,
      paddingBottom: 10
    }
  })
}

interface IHeadingProp {
  headers: Header<IBalanceDetailResponseValueItem, unknown>[]
}

const THead: FC<IHeadingProp> = ({ headers }) => (
  <table css={[classes.tableBase, classes.tableHeader]}>
    <thead>
      <tr>
        <th css={{ width: 26 }} />
        <th colSpan={6} />
        <th css={getMLBorderStyles('colSpanHeader')} />
        <th
          colSpan={3}
          className="centered"
          css={getMLBorderStyles('colSpanHeader')}
        >
          Available to Withdraw
        </th>
        <th
          colSpan={2}
          className="centered"
          css={getMLBorderStyles('colSpanHeader')}
        >
          Available to Invest
        </th>
        <th colSpan={2} css={getMLBorderStyles('colSpanHeader')} />
      </tr>
      <tr>
        {headers?.map((header, i) => (
          <th
            key={i}
            css={[
              { width: header.column.columnDef.size },
              getMLBorderStyles(header.column.id)
            ]}
          >
            {flexRender(header.column.columnDef.header, header.getContext())}
          </th>
        ))}
      </tr>
    </thead>
  </table>
)

interface IBodyProp {
  rows: Row<IBalanceDetailResponseValueItem>[]
  showNoData?: boolean
}

const TBody: FC<IBodyProp> = ({ rows }) =>
  rows.map((row) => {
    const visibleCells = row.getVisibleCells()
    const isExpanded = row.getIsExpanded()
    const rowDepth = row.depth

    return rowDepth !== 0 ? null : (
      <table key={row.id} css={[classes.tableBase, classes.tableContent]}>
        <tbody>
          <tr>
            {visibleCells.map((cell) => (
              <TableCell
                key={cell.id}
                tableCell={cell}
                borderStyles={getMLBorderStyles(cell.column.id, '#E6E6E6')}
              />
            ))}
          </tr>

          {isExpanded &&
            row.subRows.map((subRow, i) => {
              const subVisibleCells = subRow.getVisibleCells()
              const isEven = i % 2 === 0
              const subRowStyles = css({
                backgroundColor: isEven ? '#FFFFFF' : '#FAFAFA'
              })

              return (
                <tr key={subRow.id} className="headerRow" css={subRowStyles}>
                  {subVisibleCells.map((cell) => (
                    <TableCell
                      key={cell.id}
                      tableCell={cell}
                      borderStyles={getMLBorderStyles(
                        cell.column.id,
                        '#E6E6E6'
                      )}
                    />
                  ))}
                </tr>
              )
            })}
        </tbody>
      </table>
    )
  })

export const MarginLoansTable: FC<IHeadingProp & IBodyProp> = ({
  headers,
  rows,
  showNoData
}) => (
  <>
    <THead headers={headers} />
    {showNoData ? (
      <div
        css={{
          paddingTop: 10,
          paddingBottom: 20
        }}
      >
        <SnackBar type="Info" message="No data available" />
      </div>
    ) : (
      <>
        <TBody rows={rows} />
      </>
    )}
  </>
)
