import { ActionButton, ProgressIndicator } from '@fluentui/react'
import { HorizontalScrollContainer } from 'shared/components/HorizontalScrollContainer'
import { ThirdPartyLoansTable } from './ThirdPartyLoansTable'
import { useReactTableProps } from './useReactTableProps'

export const ThirdPartyLoans: React.FC = () => {
  const {
    headers,
    rows,
    isFetching,
    isAllRowsExpanded,
    getToggleAllRowsExpandedHandler,
    showNoData
  } = useReactTableProps()

  return (
    <>
      <div>
        <ActionButton
          label="Expand All Rows"
          onClick={getToggleAllRowsExpandedHandler()}
          styles={{
            root: {
              textDecoration: 'underline'
            }
          }}
          disabled={isFetching || showNoData}
        >
          {!isAllRowsExpanded ? 'Expand' : 'Collapse'} All
        </ActionButton>
      </div>

      <HorizontalScrollContainer>
        <div css={{ height: 2 }}>
          <ProgressIndicator
            progressHidden={!isFetching}
            styles={{
              itemProgress: { margin: 0, padding: 0 }
            }}
          />
        </div>
        <ThirdPartyLoansTable
          headers={headers}
          rows={rows}
          showNoData={showNoData}
        />
        <div css={{ marginTop: 10, fontSize: 10 }}>
          Note: This report includes all third-party loans for the selected
          client and is not driven by the accounts selected within the Account
          Selector
        </div>
      </HorizontalScrollContainer>
    </>
  )
}
