import { FC } from 'react'
import { useRdotUser } from 'store/user/useRdotUser'
import DetailsNavigator from '../../components/shared/DetailsNavigator/DetailsNavigator'
import { getHeaderStyles } from '../shared/getHeaderStyles'
import { MarginLoans, MarginLoansToolBar } from './MarginLoans'
import { ThirdPartyLoans, ThirdPartyLoansToolBar } from './ThirdPartyLoans'
import { useLoanTypeTabs } from './useLoanTypeTabs'

const classes = getHeaderStyles()

const toolbarProps = {
  onPrint: () => null,
  onExport: undefined
}

export const LoansContainer: FC = () => {
  const { isMarginLoans, LoanTypeTabs } = useLoanTypeTabs()
  const { isClientDashboardLoansPilotUser } = useRdotUser()

  if (!isClientDashboardLoansPilotUser) {
    return null
  }

  return (
    <div css={classes.container}>
      <div css={classes.headerContainer}>
        <div css={classes.leftContainer}>
          <DetailsNavigator />
          <LoanTypeTabs />
        </div>
        <div css={classes.filterContainer}>
          {isMarginLoans ? (
            <MarginLoansToolBar {...toolbarProps} />
          ) : (
            <ThirdPartyLoansToolBar {...toolbarProps} />
          )}
        </div>
      </div>
      {isMarginLoans ? <MarginLoans /> : <ThirdPartyLoans />}
    </div>
  )
}
