import { FC, useMemo } from 'react'
import { ToggleDoubleSided } from '../../../components/shared/ToggleDoubleSided'
import TileHeading, { BaseHeaderProps } from '../../../components/TileHeading'
import { useClientDashboardTilePreferences } from '../../../hooks/useClientDashboardPreferences'
import { ClientDashboardTiles } from '../../../shared/types'
import { loanType } from '../../Loans/useLoanTypeTabs'
import { MarginLoans } from './MarginLoans'
import { ThirdPartyLoans } from './ThirdPartyLoans'

const tileName = ClientDashboardTiles.loansTile

const { marginLoans, thirdPartyLoans } = loanType

export const Loans: FC<BaseHeaderProps> = ({ onExpand, isCobSupported }) => {
  const { setTilePreferences, tilePreferences } =
    useClientDashboardTilePreferences(tileName)

  const isMarginLoans = useMemo(
    () => tilePreferences?.whatLoans === marginLoans,
    [tilePreferences]
  )

  return (
    <div css={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <TileHeading onExpand={onExpand} isCobSupported={isCobSupported}>
        Loans
      </TileHeading>
      <ToggleDoubleSided
        css={{ margin: '10px 0 20px' }}
        defaultChecked={!isMarginLoans}
        onText={marginLoans}
        offText={thirdPartyLoans}
        onChange={() => {
          setTilePreferences({
            whatLoans: isMarginLoans ? thirdPartyLoans : marginLoans
          })
        }}
      />
      {isMarginLoans ? <MarginLoans /> : <ThirdPartyLoans />}
    </div>
  )
}
