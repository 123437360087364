import { Text } from '@fluentui/react'

export interface ICheckboxCellProps {
  checked?: boolean
  onChange?: (event: unknown) => void
  value?: string
}

export const CheckboxCell: React.FC<ICheckboxCellProps> = ({
  checked,
  onChange,
  value
}) => {
  return (
    <div style={{ display: 'flex', columnGap: '10px', alignItems: 'center' }}>
      <input
        style={{ cursor: 'pointer' }}
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      <Text className="cell">{value}</Text>
    </div>
  )
}
