import { pilotFetaureActions } from 'modules/Fees/store/actions'
import { getpilotFeature } from 'modules/Fees/store/selectors'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export const usePilotFeatures = () => {
  const dispatch = useDispatch()
  const pilotFeatures = useSelector(getpilotFeature)

  useMemo(() => {
    if (!pilotFeatures) {
      dispatch(pilotFetaureActions.request())
    }
  }, [dispatch, pilotFeatures])

  const isInPilot = useCallback(
    (featureName: string) => {
      if (pilotFeatures && pilotFeatures.length > 0) {
        const feature = pilotFeatures.find((p) => p.featureName === featureName)
        if (feature && feature.isCalculated === 'Y') {
          return true
        }
      }
      return false
    },
    [pilotFeatures]
  )

  return { pilotFeatures, isInPilot }
}
