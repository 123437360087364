import { useTheme } from '@emotion/react'
import { memo, useCallback, useState } from 'react'
import { USD } from 'shared/components/Formatting'
import { TodaysChange } from '../../components/Balances/TodaysChange'
import { IndeterminateCheckbox } from '../../shared/IndeterminateCheckbox'
import { IBalanceTodaysChangeItem } from '../../store/balancesApi/IBalanceDetailResponse'
import { Icon } from '../Icons/Icon'
import { EditGroupNameDialog } from './EditGroupNameDialog'
import { accountSelectorStyles } from './styles'

export const AccountSelectorGroup: React.FC<{
  title?: string
  checked?: boolean
  indeterminate?: boolean
  onChange: () => void
  selectedSubrowsCount: number
  subrowsCount: number
  groupBalance?: number
  groupBalanceChange?: number
  onDeleteGroup?: () => void
  isExpanded?: boolean
  toggleExpanded: () => void
  todaysChange?: IBalanceTodaysChangeItem[]
  cagId?: string
}> = memo(
  ({
    title,
    checked,
    indeterminate,
    onChange,
    selectedSubrowsCount,
    subrowsCount,
    groupBalance,
    groupBalanceChange,
    onDeleteGroup,
    isExpanded,
    toggleExpanded,
    todaysChange,
    cagId
  }) => {
    const theme = useTheme()
    const [showEditDialog, setShowEditDialog] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const onIconClick = useCallback(async () => {
      setShowEditDialog(!showEditDialog)
    }, [showEditDialog])

    return (
      <>
        <div
          css={[accountSelectorStyles.flexRow, { columnGap: 5 }]}
          onMouseOver={() => setShowEdit(true)}
          onMouseLeave={() => setShowEdit(false)}
        >
          <div
            css={[
              accountSelectorStyles.flexColumn,
              {
                justifyContent: 'space-between'
              }
            ]}
          >
            <div css={{ display: 'flex', columnGap: 5 }}>
              <div
                css={[
                  accountSelectorStyles.groupTitle,
                  {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: '2'
                  }
                ]}
                title={title}
              >
                {title}
              </div>
              {!!cagId && showEdit && (
                <Icon
                  type={'Edit'}
                  height={17}
                  width={12}
                  onClick={onIconClick}
                  title="Edit Name"
                />
              )}
            </div>
            <div
              css={[
                accountSelectorStyles.flexRow,
                accountSelectorStyles.itemTitle
              ]}
            >
              <IndeterminateCheckbox
                type="checkbox"
                css={[accountSelectorStyles.checkbox]}
                checked={checked || false}
                indeterminate={indeterminate || false}
                onChange={onChange}
              />
              <div style={{ marginLeft: 5 }}>
                {selectedSubrowsCount} / {subrowsCount} selected
              </div>
            </div>
          </div>
          <div
            css={[
              accountSelectorStyles.flexColumn,
              {
                flexGrow: 1,
                '& > div': {
                  marginLeft: 'auto',
                  marginBottom: 3,
                  '&:last-of-type': { marginBottom: 0 }
                }
              }
            ]}
          >
            <div css={accountSelectorStyles.groupBalance}>
              {groupBalance != null ? (
                <USD
                  value={groupBalance}
                  fractionDigits={0}
                  currencySign="standard"
                />
              ) : (
                <>--</>
              )}
            </div>
            <div
              css={[
                accountSelectorStyles.itemBalanceChange,
                groupBalanceChange && groupBalanceChange > 0
                  ? accountSelectorStyles.positiveBalanceChange
                  : undefined,
                { display: 'flex' }
              ]}
            >
              <TodaysChange
                change={groupBalanceChange}
                changeDetails={todaysChange}
                accountNumber="Group"
              />
            </div>
            <div css={[accountSelectorStyles.flexRow, { columnGap: 5 }]}>
              {!!cagId && onDeleteGroup && (
                <Icon
                  type="Delete"
                  width={16}
                  height={16}
                  color={theme.colors.primaryDarkBlue}
                  onClick={onDeleteGroup}
                />
              )}
              <div
                css={[
                  accountSelectorStyles.chevron,
                  {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingLeft: 1
                  }
                ]}
                onClick={toggleExpanded}
              >
                <div css={{ width: 7, height: 7 }}>
                  <Icon type={isExpanded ? 'ChevronUp' : 'ChevronDown'} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {showEditDialog && (
          <EditGroupNameDialog
            hide={() => setShowEditDialog(false)}
            originalName={title || ''}
            selectedId={cagId || ''}
            hidden={!showEditDialog}
          />
        )}
      </>
    )
  }
)
