import { useCallback } from 'react'
import { useSessionStateContainer } from 'store/session'
import { ISecureMessageEdit, ISecureMessageRequest } from '../types'

export interface ISecureMessagesSession {
  edits?: Record<string, ISecureMessageEdit>
  messages?: Record<string, ISecureMessageRequest>
  replies?: Record<string, string>
}

export const useSecureMessagesSession = () => {
  const { updateContainer, value } =
    useSessionStateContainer<ISecureMessagesSession>('secureMessages')
  const { edits, messages, replies } = value

  const updateEdits = useCallback(
    (updates?: Record<string, ISecureMessageEdit>) =>
      updateContainer({ edits: { ...edits, ...updates } }),
    [edits, updateContainer]
  )

  const updateMessages = useCallback(
    (updates?: Record<string, ISecureMessageRequest>) =>
      updateContainer({ messages: { ...messages, ...updates } }),
    [messages, updateContainer]
  )

  const updateReplies = useCallback(
    (updates?: Record<string, string>) =>
      updateContainer({ replies: { ...replies, ...updates } }),
    [replies, updateContainer]
  )

  return {
    edits,
    messages,
    replies,
    updateEdits,
    updateMessages,
    updateReplies
  }
}
