import { SnackBar } from 'modules/Advisory/modules/Rdot360/components/shared/Snackbar'

export const ErrorMessage: React.FC<{ error?: Error }> = ({ error }) => {
  if (!error) {
    return null
  }

  return (
    <div style={{ marginBottom: '5px' }}>
      <SnackBar
        type="Failure"
        message={error.message || 'An unknown error occurred'}
      />
    </div>
  )
}
