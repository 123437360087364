import {
  CommandButton,
  DirectionalHint,
  IContextualMenuProps,
  PrimaryButton
} from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { IndeterminateProgressIndicator } from 'modules/Advisory/modules/Rdot360/components/shared'
import {
  downloadAttachment,
  openPreview
} from 'modules/Advisory/modules/Rdot360/hooks/useDownloadAttachment'
import { useCallback, useMemo } from 'react'
import { PaletteComponent } from 'shared/components/Palette'
import { buttonStyles } from 'shared/uiSharedComponents/Buttons'
import { BackToFolder } from '../../features/Header/BackToFolder'
import { SecureMessagesHeader } from '../../features/Header/SecureMessagesHeader'
import { DeleteModal } from '../../features/Modal/DeleteModal'
import { ReplyAllPanel } from '../../features/Reply/ReplyAllPanel'
import { SecureMessagesTableWrapper } from '../../features/Table/SecureMessagesTableWrapper'
import { ErrorMessage } from '../../shared/components'
import { noSubjectText } from '../../shared/constants'
import { formatDateTimeString, getFileSize } from '../../shared/functions'
import {
  useSecureMessagesState,
  useSecureMessagesView
} from '../../shared/hooks'
import { IGraphAttachment, IGraphFileAttachment } from '../../shared/types'
import { DeleteButton } from './DeleteButton'
import { EditIconButton } from './EditIconButton'
import { ReplyButton } from './ReplyButton'

const getAttachmentMenuProps = (
  attachment?: IGraphAttachment
): IContextualMenuProps => ({
  directionalHint: DirectionalHint.bottomRightEdge,
  items: [
    {
      key: 'download',
      text: 'Download',
      onClick: () => {
        const fileAttachment = attachment as IGraphFileAttachment
        if (fileAttachment) {
          downloadAttachment(
            fileAttachment.contentBytes,
            fileAttachment.contentType,
            fileAttachment.name
          )
        }
      }
    },
    {
      key: 'preview',
      text: 'Preview',
      onClick: () => {
        const fileAttachment = attachment as IGraphFileAttachment
        if (fileAttachment) {
          openPreview(fileAttachment.contentBytes, fileAttachment.contentType)
        }
      }
    }
  ],
  styles: {
    root: { minWidth: 100 }
  }
})

export const ReadMessageContainer: React.FC = () => {
  const {
    attachments,
    error,
    isLoading,
    deleteMessage,
    moveMessage,
    sendDraftMessage
  } = useSecureMessagesView()

  const { previousView, selectedMessage } = useSecureMessagesState()

  const [
    isDeleteModalOpen,
    { setTrue: showDeleteModal, setFalse: hideDeletenModal }
  ] = useBoolean(false)

  const cidSrcMap = useMemo(() => {
    const map = new Map<string, string>()
    if (attachments) {
      attachments.forEach((attachment) => {
        if (attachment['@odata.type'] === '#microsoft.graph.fileAttachment') {
          const fileAttachment = attachment as IGraphFileAttachment
          map.set(
            `cid:${fileAttachment.contentId}`,
            `data:${fileAttachment.contentType};base64, ${fileAttachment.contentBytes}`
          )
        }
      })
    }
    return map
  }, [attachments])

  const bodyHtml = useMemo(() => {
    if (!selectedMessage?.body?.content) {
      return ''
    }

    const html = selectedMessage.body.content
      .replace(
        new RegExp(Array.from(cidSrcMap.keys()).join('|'), 'gi'),
        (matched) => cidSrcMap.get(matched) || matched
      )
      .replace(new RegExp(/&lt;\S+@\S+\.\S+&gt;/, 'gi'), '')
    return html
  }, [cidSrcMap, selectedMessage])

  const displayDate = useMemo(() => {
    if (previousView === 'drafts' && selectedMessage?.lastModifiedDateTime) {
      return `Modified: ${formatDateTimeString(
        selectedMessage.lastModifiedDateTime
      )}`
    }

    if (previousView === 'sent' && selectedMessage?.sentDateTime) {
      return `Sent: ${formatDateTimeString(selectedMessage.sentDateTime)}`
    }

    if (selectedMessage?.receivedDateTime) {
      return `Received: ${formatDateTimeString(
        selectedMessage.receivedDateTime
      )}`
    }

    return null
  }, [previousView, selectedMessage])

  const filteredAttachments = useMemo(() => {
    if (!attachments) {
      return []
    }

    return attachments.filter((x) => !x.isInline)
  }, [attachments])

  const subject = useMemo(
    () => selectedMessage?.subject || noSubjectText,
    [selectedMessage]
  )

  const ccRecipients = useMemo(
    () =>
      selectedMessage?.ccRecipients?.map((x) => x.emailAddress.name).join(', '),
    [selectedMessage]
  )

  const toRecipients = useMemo(
    () =>
      selectedMessage?.toRecipients?.map((x) => x.emailAddress.name).join(', '),
    [selectedMessage]
  )

  const handleDelete = useCallback(() => {
    if (previousView === 'deleted' || previousView === 'drafts') {
      showDeleteModal()
    } else {
      moveMessage()
    }
  }, [moveMessage, previousView, showDeleteModal])

  const RightHeader = useCallback(() => {
    return (
      <>
        {!selectedMessage?.isDraft && <ReplyButton />}
        <DeleteButton onClick={handleDelete} />
        {!!selectedMessage?.isDraft && (
          <>
            <EditIconButton />
            <PrimaryButton
              css={buttonStyles.primary}
              disabled={isLoading}
              onClick={sendDraftMessage}
              text="Send"
              title="Send draft message"
            />
          </>
        )}
      </>
    )
  }, [handleDelete, isLoading, selectedMessage, sendDraftMessage])

  return (
    <>
      <SecureMessagesHeader left={<BackToFolder />} right={<RightHeader />} />
      <SecureMessagesTableWrapper>
        <ErrorMessage error={error} />
        {isLoading && <IndeterminateProgressIndicator />}
        <PaletteComponent>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
          >
            <div
              css={(theme) => ({
                fontSize: theme.size.xl,
                fontWeight: theme.fontWeights.demi
              })}
            >
              Subject: {subject}
            </div>
            <div
              css={(theme) => ({ fontSize: theme.size.lg })}
              style={{ display: 'flex', flexDirection: 'row', gap: '15px' }}
            >
              <div>
                From: {selectedMessage?.from?.emailAddress.name || '--'}
              </div>
              {displayDate && (
                <>
                  <div>|</div>
                  <div>{displayDate}</div>
                </>
              )}
            </div>
            <div css={(theme) => ({ fontSize: theme.size.lg })}>
              To: {toRecipients}
            </div>
            {ccRecipients && (
              <div css={(theme) => ({ fontSize: theme.size.lg })}>
                Cc: {ccRecipients}
              </div>
            )}
            {filteredAttachments.length > 0 && (
              <div
                style={{ display: 'flex', flexDirection: 'row', gap: '15px' }}
              >
                <div>{filteredAttachments.length} attachment(s):</div>
                {filteredAttachments.map((attachment) => (
                  <CommandButton
                    key={attachment.id}
                    iconProps={{ iconName: 'Attach' }}
                    menuProps={getAttachmentMenuProps(attachment)}
                    styles={{ root: { height: 'auto' } }}
                    text={`${attachment.name} (${getFileSize(
                      attachment.size
                    )})`}
                  />
                ))}
              </div>
            )}
          </div>
          <hr style={{ margin: '20px 0px', width: '98%' }} />
          <div dangerouslySetInnerHTML={{ __html: bodyHtml }} />
        </PaletteComponent>
      </SecureMessagesTableWrapper>
      <DeleteModal
        isModalOpen={isDeleteModalOpen}
        onCancel={hideDeletenModal}
        onDelete={deleteMessage}
      />
      <ReplyAllPanel message={selectedMessage} />
    </>
  )
}
