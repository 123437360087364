import { ConfirmModal } from './ConfirmModal'

export interface IDeleteModalProps {
  isModalOpen: boolean
  onCancel: () => void
  onDelete: () => void
}

export const DeleteModal: React.FC<IDeleteModalProps> = ({
  isModalOpen,
  onCancel,
  onDelete
}) => {
  return (
    isModalOpen && (
      <ConfirmModal
        message="Do you want to permanently delete the selected item(s)?"
        onDismiss={onCancel}
        onPrimaryClick={onDelete}
        onSeconardyClick={onCancel}
        primaryText="Delete"
        secondaryText="Cancel"
        width={630}
      />
    )
  )
}
