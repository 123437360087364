import { type CKEditorConfig } from 'ckeditor4-react'

export const ckEditorConfig: CKEditorConfig = {
  height: '285px',
  removeButtons: [],
  resize_enabled: false,
  toolbar: [
    [
      'Font',
      'FontSize',
      'Bold',
      'Italic',
      'Underline',
      'Strike',
      'BGColor',
      'TextColor',
      'BulletedList',
      'NumberedList',
      'Outdent',
      'Indent',
      'Undo',
      'Redo'
    ]
  ]
}

export const noSubjectText = '(No subject)'
