import { Dialog, IDialogContentStyles, Link } from '@fluentui/react'
import { buttonStyles } from 'modules/Advisory/modules/Rdot360/components/shared/Buttons'
import { Icon } from 'modules/Advisory/modules/Rdot360/features/Icons/Icon'
import { useCallback, useMemo, useRef, useState } from 'react'
import { getFileSize } from '../../shared/functions'
import { useSecureMessagesState } from '../../shared/hooks'

const dialogContentStyles: Partial<IDialogContentStyles> = {
  title: {
    fontSize: '18px',
    fontWeight: 700,
    color: '#13272B',
    padding: '18px 30px'
  },
  header: {
    borderBottom: '1px solid rgba(0,0,0,0.1)'
  },
  content: { width: '550px' }
}

const allowedFileTypes = [
  '.pdf',
  '.xls',
  '.xlsx',
  '.csv',
  '.doc',
  '.docx',
  '.png',
  '.jpg',
  '.tif',
  '.pages',
  '.txt',
  '.log',
  '.ppt',
  '.pptx',
  '.numbers',
  '.heic'
]

const fileSizeLimit = 104857600
const isValidFileSize = (size: number) => size <= fileSizeLimit

export interface IAttachFileDialogProps {
  onChange: (attachments: File[]) => void
}

export const AttachFileDialog: React.FC<IAttachFileDialogProps> = ({
  onChange
}) => {
  const { showAttach, setShowAttach } = useSecureMessagesState()

  const inputFilesRef = useRef<HTMLInputElement>(null)
  const [fileList, setFileList] = useState<FileList | null>(null)
  const files = useMemo(() => (fileList ? [...fileList] : []), [fileList])
  const validFileSizes = useMemo(
    () => files.every((f) => isValidFileSize(f.size)),
    [files]
  )

  const handleDoneClick = useCallback(async () => {
    onChange(files)
    setShowAttach(false)
    setFileList(null)
  }, [files, onChange, setShowAttach])

  return (
    <Dialog
      hidden={!showAttach}
      modalProps={{
        isBlocking: true
      }}
      dialogContentProps={{
        title: 'Attach File',
        styles: dialogContentStyles
      }}
      minWidth={'550px'}
      onDismiss={() => {
        setFileList(null)
        setShowAttach(false)
      }}
    >
      <div css={{ padding: '17px 0' }}>
        <div
          css={(theme) => ({
            backgroundColor: '#F6FBFF',
            border: `${theme.colors.tertiaryGray4} 1px dashed`,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '35px 55px',
            gridRowGap: 15,
            marginBottom: '10px'
          })}
        >
          <Icon type="FileUpload" height={40} width={40} />
          <div>
            <Link onClick={() => inputFilesRef.current?.click()}>Browse</Link>{' '}
            to select files
          </div>
          <div>
            Only {allowedFileTypes.join(', ')} files with max size of{' '}
            {getFileSize(fileSizeLimit)}.
          </div>
        </div>
        <div>
          <input
            accept={allowedFileTypes.join(',')}
            css={{ display: 'none' }}
            multiple={true}
            onChange={(e) => setFileList(e.target.files)}
            ref={inputFilesRef}
            type="file"
          />
          {files.map((f) => (
            <div
              key={f.name}
              css={(theme) => ({
                borderBottom: `${theme.colors.tertiaryGray4} 1px solid`,
                display: 'flex',
                justifyContent: 'space-between',
                paddingBottom: '10px',
                marginBottom: '10px'
              })}
            >
              <div
                style={{
                  maxWidth: '300px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap'
                }}
                title={f.name}
              >
                {f.name}
              </div>
              <div style={{ display: 'flex', gap: '5px' }}>
                {isValidFileSize(f.size) ? (
                  getFileSize(f.size)
                ) : (
                  <>
                    <Icon
                      type="Warning"
                      height={17}
                      width={17}
                      color="#E55541"
                    />
                    <div style={{ color: '#E55541' }}>
                      File exceeds size limit.
                    </div>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <button
          css={[buttonStyles.primary, { width: '100px' }]}
          disabled={!files.length || !validFileSizes}
          onClick={handleDoneClick}
        >
          Done
        </button>
      </div>
    </Dialog>
  )
}
