import { useTheme } from '@emotion/react'
import { TooltipHost } from '@fluentui/react'
import { FC } from 'react'
import { Searchbox } from '../../../components/shared/DetailTables/Searchbox'
import { Icon } from '../../../features/Icons/Icon'
import { PrintDropdownMenu } from '../../../shared/PrintDropdownMenu'
import { useReactTableProps } from './useReactTableProps'

const isExportToExcelAvailable = false
const isPrintAvailable = false

export const MarginLoansToolBar: FC<{
  onPrint: (masked?: boolean, hideHousehold?: boolean) => void
  onExport?: () => Promise<void>
}> = ({ onPrint, onExport }) => {
  const theme = useTheme()
  const { isFetching, searchText, setSearchText, showNoData, invalidateCache } =
    useReactTableProps()
  const onSearchInputChange = (value?: string) => {
    setSearchText(value || '')
  }

  return (
    <div
      css={{
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        columnGap: 10
      }}
    >
      <Searchbox searchText={searchText} onChange={onSearchInputChange} />

      <TooltipHost content="Refresh" id="#invalidateCache">
        <Icon
          type="Refresh"
          width={24}
          height={24}
          onClick={invalidateCache}
          color={theme.colors.extraBlue2}
          isDisabled={isFetching}
        />
      </TooltipHost>

      {isPrintAvailable && (
        <TooltipHost content="Print" id="#printDropdownMenu">
          <PrintDropdownMenu
            print={onPrint}
            displayDisclaimer={false}
            isDisabled={isFetching || showNoData}
          />
        </TooltipHost>
      )}

      {isExportToExcelAvailable && (
        <TooltipHost content="Export to Excel" id="#exportToExcel">
          <Icon
            type="Download"
            width={24}
            height={24}
            onClick={isFetching || showNoData ? undefined : onExport}
            color={theme.colors.extraBlue2}
            isDisabled={isFetching || showNoData}
          />
        </TooltipHost>
      )}
    </div>
  )
}
