import {
  CommandButton,
  DirectionalHint,
  PrimaryButton,
  Stack,
  TextField
} from '@fluentui/react'
import { IndeterminateProgressIndicator } from 'modules/Advisory/modules/Rdot360/components/shared'
import { buttonStyles } from 'modules/Advisory/modules/Rdot360/components/shared/Buttons'
import { openPreview } from 'modules/Advisory/modules/Rdot360/hooks/useDownloadAttachment'
import { useCallback, useMemo, useState } from 'react'
import { useDebounce } from 'react-use'
import { CKEditorComponent } from 'shared/ckEditor'
import { PaletteComponent } from 'shared/components/Palette'
import { AttachFileDialog, AttachIconButton } from '../../features/Attachments'
import { BackToFolder } from '../../features/Header/BackToFolder'
import { SecureMessagesHeader } from '../../features/Header/SecureMessagesHeader'
import { SecureMessagesTableWrapper } from '../../features/Table/SecureMessagesTableWrapper'
import { ErrorMessage } from '../../shared/components'
import { ckEditorConfig } from '../../shared/constants'
import { getFileSize } from '../../shared/functions'
import { useSecureMessagesEdit } from '../../shared/hooks'
import { IGraphFileAttachment } from '../../shared/types'

export const EditMessageContainer: React.FC = () => {
  const {
    addMessageAttachments,
    attachments,
    deleteMessageAttachment,
    error,
    initialBody,
    initialSubject,
    isLoading,
    message,
    messageId,
    updateEdits,
    updateEmailMessage
  } = useSecureMessagesEdit()

  const [body, setBody] = useState<string | undefined>(initialBody)
  const bodyChanged = useMemo(
    () => body !== message?.body?.content,
    [body, message]
  )

  const [subject, setSubject] = useState<string | undefined>(initialSubject)
  const subjectChanged = useMemo(
    () => subject !== message?.subject,
    [subject, message]
  )

  useDebounce(
    () => messageId && updateEdits({ [messageId]: { body, subject } }),
    1000,
    [body, subject]
  )

  const canSave = useMemo(
    () => !isLoading && !!subject?.length && (bodyChanged || subjectChanged),
    [bodyChanged, isLoading, subject?.length, subjectChanged]
  )

  const ccRecipients = useMemo(
    () => message?.ccRecipients?.map((x) => x.emailAddress.name).join(', '),
    [message]
  )

  const toRecipients = useMemo(
    () => message?.toRecipients?.map((x) => x.emailAddress.name).join(', '),
    [message]
  )

  const fileAttachments = useMemo(() => {
    if (!attachments) {
      return []
    }

    return attachments.filter(
      (x) =>
        !x.isInline && x['@odata.type'] === '#microsoft.graph.fileAttachment'
    ) as IGraphFileAttachment[]
  }, [attachments])

  const handleSaveClick = useCallback(() => {
    if (!messageId) {
      return
    }
    updateEmailMessage({
      messageId,
      messageUpdate: {
        body: {
          content: body ?? '',
          contentType: 'html'
        },
        subject
      }
    })
  }, [body, messageId, subject, updateEmailMessage])

  const RightHeader = useCallback(() => {
    return (
      <>
        <AttachIconButton />
        <PrimaryButton
          css={buttonStyles.primary}
          disabled={!canSave}
          onClick={handleSaveClick}
          text="Save"
          title="Save message"
        />
      </>
    )
  }, [canSave, handleSaveClick])

  return (
    <>
      <SecureMessagesHeader left={<BackToFolder />} right={<RightHeader />} />
      <SecureMessagesTableWrapper>
        <ErrorMessage error={error} />
        {isLoading && <IndeterminateProgressIndicator />}
        <PaletteComponent>
          <div
            css={(theme) => ({
              fontSize: theme.size.lg,
              fontWeight: theme.fontWeights.demi,
              marginBottom: '20px'
            })}
          >
            Edit Message
          </div>
          <Stack tokens={{ childrenGap: 20 }}>
            <Stack.Item>
              <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Stack.Item basis={'35%'} grow={0}>
                  <TextField
                    label="To"
                    value={toRecipients}
                    disabled
                    readOnly
                  />
                </Stack.Item>
                <Stack.Item basis={'65%'} grow={0}>
                  <TextField
                    label="Cc"
                    value={ccRecipients}
                    disabled
                    readOnly
                  />
                </Stack.Item>
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <TextField
                label="Subject"
                onChange={(_, newValue) => setSubject(newValue)}
                value={subject}
                required
              />
            </Stack.Item>
            <Stack.Item>
              {fileAttachments.length > 0 && (
                <div
                  css={(theme) => ({
                    border: `${theme.colors.tertiaryGray4} 1px solid`,
                    borderBottom: '0',
                    display: 'flex',
                    gap: '15px',
                    padding: '15px'
                  })}
                >
                  <div>{fileAttachments.length} attachment(s):</div>
                  {fileAttachments.map((x, i) => (
                    <CommandButton
                      key={i}
                      iconProps={{ iconName: 'Attach' }}
                      menuProps={{
                        directionalHint: DirectionalHint.bottomRightEdge,
                        items: [
                          {
                            key: 'delete',
                            text: 'Delete',
                            onClick: () =>
                              messageId &&
                              deleteMessageAttachment({
                                attachmentId: x.id,
                                messageId
                              })
                          },
                          {
                            key: 'preview',
                            text: 'Preview',
                            onClick: () =>
                              openPreview(x.contentBytes, x.contentType)
                          }
                        ],
                        styles: {
                          root: { minWidth: 100 }
                        }
                      }}
                      styles={{ root: { height: 'auto' } }}
                      text={`${x.name} (${getFileSize(x.size)})`}
                    />
                  ))}
                </div>
              )}
              <CKEditorComponent
                initData={body}
                config={ckEditorConfig}
                onChange={(payload: any) => setBody(payload.editor.getData())}
              />
            </Stack.Item>
          </Stack>
        </PaletteComponent>
      </SecureMessagesTableWrapper>
      <AttachFileDialog
        onChange={(x) =>
          messageId && addMessageAttachments({ attachments: x, messageId })
        }
      />
    </>
  )
}
