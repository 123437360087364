import { skipToken } from '@reduxjs/toolkit/query'
import { useCallback, useEffect, useMemo } from 'react'
import { useEffectOnce } from 'react-use'
import { getWellKnownFolderName } from '../functions'
import {
  useDeleteEmailMessagesMutation,
  useGetMessageAttachmentsQuery,
  useMoveEmailMessagesMutation,
  useSecureMessagesGraphApiUtil,
  useSendDraftEmailMessageMutation,
  useUpdateEmailMessageMutation
} from '../store'
import { SecureMessageFolder } from '../types'
import { useSecureMessagesState } from './useSecureMessagesState'

export const useSecureMessagesView = () => {
  const { previousView, selectedMessage, setCurrentView } =
    useSecureMessagesState()

  const { invalidateTags } = useSecureMessagesGraphApiUtil()

  const mailFolderId = useMemo(
    () => getWellKnownFolderName(previousView as SecureMessageFolder),
    [previousView]
  )

  const { currentData: attachments, isLoading: isAttachmentsLoading } =
    useGetMessageAttachmentsQuery(
      previousView && selectedMessage?.id
        ? {
            mailFolderId: getWellKnownFolderName(
              previousView as SecureMessageFolder
            ),
            messageId: selectedMessage.id
          }
        : skipToken
    )

  const [
    deleteEmailMessages,
    {
      error: deleteMessageError,
      isLoading: isDeleteMessageLoading,
      isSuccess: isDeleteMessageSuccess
    }
  ] = useDeleteEmailMessagesMutation()

  const deleteMessage = useCallback(() => {
    if (!selectedMessage?.id) {
      return
    }

    deleteEmailMessages({
      mailFolderId,
      messageIds: [selectedMessage.id]
    })
  }, [deleteEmailMessages, mailFolderId, selectedMessage])

  const [
    moveEmailMessages,
    {
      error: moveMessageError,
      isLoading: isMoveMessageLoading,
      isSuccess: isMoveMessageSuccess
    }
  ] = useMoveEmailMessagesMutation()

  const moveMessage = useCallback(() => {
    if (!selectedMessage?.id) {
      return
    }

    moveEmailMessages({
      destinationId: getWellKnownFolderName('deleted'),
      mailFolderId,
      messageIds: [selectedMessage.id]
    })
  }, [mailFolderId, moveEmailMessages, selectedMessage])

  const [
    sendDraftEmailMessage,
    {
      error: sendDraftMessageError,
      isLoading: isSendDrafMessageLoading,
      isSuccess: isSendDraftMessageSuccess
    }
  ] = useSendDraftEmailMessageMutation()

  const sendDraftMessage = useCallback(() => {
    if (!selectedMessage?.id || !selectedMessage.isDraft) {
      return
    }

    sendDraftEmailMessage(selectedMessage.id)
  }, [selectedMessage, sendDraftEmailMessage])

  const [
    updateEmailMessage,
    { error: updateMessageError, isLoading: isUpdateMessageLoading }
  ] = useUpdateEmailMessageMutation()

  const error = useMemo(
    () =>
      (deleteMessageError as Error | undefined) ||
      (moveMessageError as Error | undefined) ||
      (sendDraftMessageError as Error | undefined) ||
      (updateMessageError as Error | undefined),
    [
      deleteMessageError,
      moveMessageError,
      sendDraftMessageError,
      updateMessageError
    ]
  )

  const isLoading = useMemo(
    () =>
      isAttachmentsLoading ||
      isDeleteMessageLoading ||
      isMoveMessageLoading ||
      isSendDrafMessageLoading ||
      isUpdateMessageLoading,
    [
      isAttachmentsLoading,
      isDeleteMessageLoading,
      isMoveMessageLoading,
      isSendDrafMessageLoading,
      isUpdateMessageLoading
    ]
  )

  const isSuccess = useMemo(
    () =>
      isDeleteMessageSuccess ||
      isMoveMessageSuccess ||
      isSendDraftMessageSuccess,
    [isDeleteMessageSuccess, isMoveMessageSuccess, isSendDraftMessageSuccess]
  )

  useEffect(() => {
    if (isSuccess && previousView) {
      invalidateTags(['messages'])
      setCurrentView(previousView)
    }
  }, [invalidateTags, isSuccess, previousView, setCurrentView])

  useEffectOnce(() => {
    if (!selectedMessage?.id || selectedMessage.isRead) {
      return
    }

    updateEmailMessage({
      messageId: selectedMessage.id,
      messageUpdate: {
        isRead: true
      }
    })
  })

  return {
    attachments,
    error,
    isLoading,
    isSuccess,
    deleteMessage,
    moveMessage,
    sendDraftMessage
  }
}
