import { combineReducers } from 'redux'
import {
  hurdleSearchFetchReducer,
  hurdleSearchFetchSagas
} from './hurdleSearchFetch'
import {
  payoutUserSearchFetchReducer,
  payoutUserSearchFetchSagas
} from './payoutUserSearchFetch'
import { repSearchFetchReducer, repSearchFetchSagas } from './repSearchFetch'
import { teamSearchFetchReducer, teamSearchFetchSagas } from './teamSearchFetch'

export const entitySearchReducer = combineReducers({
  payoutUserSearchFetch: payoutUserSearchFetchReducer,
  repSearchFetch: repSearchFetchReducer,
  teamSearchFetch: teamSearchFetchReducer,
  hurdleSearchFetch: hurdleSearchFetchReducer
})

export const entitySearchSagas = [
  ...payoutUserSearchFetchSagas,
  ...repSearchFetchSagas,
  ...teamSearchFetchSagas,
  ...hurdleSearchFetchSagas
]
