import { IModuleDefinition } from 'modules/types'
import { combineReducers } from 'redux'
import { RdotUserRoleEnum } from 'store/user/rdotUser'
import { CommunicationsContainer } from './CommunicationsContainer'
import { secureMessagesReducer } from './modules/SecureMessages'

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/communications/*',
    private: true,
    component: CommunicationsContainer
  },
  name: 'Communications Center',
  roles: [RdotUserRoleEnum.Advisory_Feature_CommunicationsCenter]
}

export const rdot360CommunicationsReducer = combineReducers({
  secureMessages: secureMessagesReducer
})

export default moduleDefinition
