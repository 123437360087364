import { css, useTheme } from '@emotion/react'
import { USDCell } from 'features/Lists/core/components/USDCell'
import { getCroppedAccountList } from 'modules/Advisory/modules/Rdot360/shared/utilities'
import { useMemo } from 'react'
import { FormattedDate } from 'react-intl'
import {
  useRdot360AccountContext,
  useRdot360Context,
  useRdot360HouseholdContext
} from '../../../../store/rdot360Context'

const getClasses = () => ({
  headerLeft: css({
    textAlign: 'left',
    width: '70%'
  }),
  headerRight: css({
    textAlign: 'right',
    width: '30%'
  }),
  headerColumn: css({
    div: css({
      padding: '5px 0'
    }),
    label: css({
      color: '#676767'
    })
  })
})

interface IPrintHeaderClientProps {
  masked: boolean
  hideHousehold: boolean
  total: number
  daysChange: number
  searchText: string
}

export const PrintHeaderClient: React.FC<IPrintHeaderClientProps> = ({
  masked,
  hideHousehold,
  total,
  daysChange,
  searchText
}) => {
  const context = useRdot360HouseholdContext()
  const { selectedAccounts } = useRdot360AccountContext()
  const accountNumbers = selectedAccounts.map(
    (account) => account.CustodyAccount || ''
  )
  const accounts = getCroppedAccountList(accountNumbers, masked, 270, 4)
  const theme = useTheme()
  const classes = useMemo(() => getClasses(), [])
  let textColor = theme.colors.primaryBlack
  if (daysChange > 0) {
    textColor = theme.colors.extraGreen1
  } else if (daysChange < 0) {
    textColor = theme.colors.primaryRed
  }
  const { asOfDate } = useRdot360Context()
  return (
    <>
      <div css={[classes.headerColumn, classes.headerLeft]}>
        {!hideHousehold && (
          <div>
            <strong>{context.household?.householdName}</strong>
          </div>
        )}
        <div>
          <label>Selected Account(s):</label> {accounts}
        </div>
      </div>
      <div css={[classes.headerColumn, classes.headerRight]}>
        <div>
          <label>Report As Of:</label>{' '}
          <FormattedDate
            value={asOfDate}
            day="numeric"
            month="numeric"
            year="numeric"
          />
        </div>
        <div>
          <label>Total:</label>{' '}
          <USDCell currencySign="standard" value={total || 0} />
          {' | '}
          <label>Day&apos;s Change:</label>{' '}
          <span style={{ color: textColor }}>
            <USDCell currencySign="standard" value={daysChange || 0} />
          </span>
        </div>
        {searchText && (
          <div>
            <label>Filtered by:</label> {searchText}
          </div>
        )}
      </div>
    </>
  )
}
