import { Theme, css, useTheme } from '@emotion/react'
import { CDMv2Account } from 'api/nickname.types'
import { format } from 'date-fns'
import { useRelatedPartyPanel } from 'features/RelatedPartyPanel/store'
import { orderBy } from 'lodash'
import { memo, useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { parseDateISOStringInLocalTimezone } from 'shared'
import { TransferType, usePaymentHub } from 'shared/hooks/usePaymentHub'
import { useMasking } from 'store/shared/hooks/useMaskedText'
import { navigationActions } from 'store/ui/actions'
import { Icon } from '../../../features/Icons/Icon'
import { IRdot360Account, isMultiMargin } from '../../../store/rdot360Context'
import { AccountStencil } from './AccountStencil'

const getClasses = (theme: Theme) => ({
  container: css({
    padding: 10,
    fontSize: '12px',
    minWidth: '350px',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '5px'
  }),
  headerContainer: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 20,
    maxWidth: 600,
    '&>div': {
      whiteSpace: 'wrap'
    }
  }),
  headerLeft: css({
    minWidth: 200
  }),
  headerRight: css({
    minWidth: 300
  }),
  label: css({
    fontWeight: 'bold'
  }),
  note: css({
    fontWeight: '400',
    fontSize: '10px'
  }),
  partitionLine: css({
    border: 'none',
    borderTop: `1px solid ${theme.colors.extraGray1}`,
    height: '1px',
    width: '100%'
  }),
  linkText: (theme: Theme) =>
    css({
      color: theme.colors.primaryDarkBlue,
      fontWeight: theme.fontWeights.medium,
      fontSize: theme.size.sm
    })
})

export const AccountTooltip: React.FC<{
  nicknameAccount?: CDMv2Account
  searchAccount?: IRdot360Account
}> = memo(({ nicknameAccount, searchAccount }) => {
  const theme = useTheme()
  const classes = useMemo(() => getClasses(theme), [theme])
  const dispatch = useDispatch()
  const [isVisible, setisVisible] = useState(false)
  const {
    addressMappings,
    establishDate,
    partyID,
    accountID,
    accountNumber,
    stakeholders
  } = nicknameAccount || {}
  const {
    accounttype,
    modelType,
    modelName,
    marginAgreement,
    managerFirmName,
    multipleMargin,
    CustodianCode,
    CustodyAccount,
    CustodianName,
    preferredNickname
  } = searchAccount || {}

  const { navigateToTransfers, showCreate, showChecks, showEFT, showJNL } =
    usePaymentHub()
  const TransfersLink = useCallback(
    ({ text, type }: { text: string; type: TransferType }) => (
      <div
        style={{
          display: 'flex',
          cursor: 'pointer',
          gridColumnGap: 5,
          alignItems: 'center'
        }}
        onClick={() =>
          searchAccount && navigateToTransfers(searchAccount, type)
        }
      >
        <Icon
          type="SimulateArrow"
          height={12}
          width={12}
          color={theme.colors.primaryDarkBlue}
        />
        <a css={[classes.linkText, { textDecoration: 'underline' }]}>{text}</a>
      </div>
    ),
    [classes, navigateToTransfers, searchAccount, theme]
  )

  const { mask } = useMasking()
  const nicknameWithMask = mask(preferredNickname, 'nickname')

  const { open: openRelatedParties } = useRelatedPartyPanel()
  const onRelatedPartiesClicked = useCallback(() => {
    if (!accountID || !accountNumber) {
      return
    }
    openRelatedParties({
      idType: 'accountId',
      id: accountID,
      displayName: accountNumber
    })
  }, [accountID, accountNumber, openRelatedParties])

  const onWealthscapeClicked = useCallback(() => {
    dispatch(
      navigationActions.launchWealthscape(
        `account/profile?AcctNum=${CustodyAccount}`
      )
    )
  }, [CustodyAccount, dispatch])

  const primaryParty = useMemo(
    () => stakeholders?.find((x) => x.partyID === partyID)?.party,
    [stakeholders, partyID]
  )

  const stakeHolderList = useMemo(
    () =>
      orderBy(stakeholders, (x) =>
        [
          x.accountRelationship,
          x.stakeholderType,
          x.stakeholderRelationship
        ].findIndex((x) => x)
      ),
    [stakeholders]
  )

  return (
    <div css={classes.container}>
      <div css={classes.headerContainer}>
        <div css={classes.headerLeft}>
          <div>{nicknameWithMask}</div>
          <div>{CustodyAccount || accountNumber}</div>
          <div
            css={[
              CustodianName === 'nfs' && {
                textTransform: 'uppercase'
              }
            ]}
          >
            {CustodianName}
          </div>
        </div>
        {accounttype === 'Managed' && (
          <div css={classes.headerRight}>
            <div css={classes.label}>Advisory Program</div>
            <div>{modelType || '--'}</div>
            <div css={classes.label}>Manager Firm</div>
            <div>{managerFirmName || '--'}</div>
            <div css={classes.label}>Managed Strategy</div>
            <div>{modelName || '--'}</div>
          </div>
        )}
      </div>
      <hr css={classes.partitionLine} />

      {establishDate && (
        <div>
          <div css={classes.label}>Establishment Date</div>
          <div>
            {format(
              parseDateISOStringInLocalTimezone(establishDate),
              'yyyy-MM-dd'
            )}
          </div>
        </div>
      )}

      {!!addressMappings?.[0] && (
        <div>
          <div css={classes.label}>Account Stencil</div>
          <AccountStencil account={nicknameAccount} />
        </div>
      )}

      {primaryParty?.partyName && (
        <div
          style={{
            display: 'flex',
            gap: '50px'
          }}
        >
          <div>
            <div css={classes.label}>Primary Stakeholder</div>
            <div
              title={primaryParty.partyName}
              style={{
                maxWidth: '200px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {primaryParty.partyName}
            </div>
          </div>
          {primaryParty.irsNo && (
            <div>
              <div css={classes.label}>SSN / TIN</div>
              <div style={{ display: 'flex', gap: '5px' }}>
                <div>
                  {isVisible
                    ? primaryParty.irsNo
                    : primaryParty.irsNo.replace(/\d/g, '*')}
                </div>
                <Icon
                  type={isVisible ? 'NoVisibility' : 'Visibility'}
                  height={14}
                  width={14}
                  onClick={() => setisVisible(!isVisible)}
                />
              </div>
            </div>
          )}
        </div>
      )}

      {!!stakeHolderList.length && (
        <>
          <hr css={classes.partitionLine} />
          <div>
            <table
              css={{
                borderCollapse: 'collapse',
                border: 'none'
              }}
            >
              <thead>
                <tr>
                  <th css={{ textAlign: 'left', padding: '0 3px' }}>
                    Stakeholder
                  </th>
                  <th css={{ textAlign: 'left', padding: '0 3px 0 10px' }}>
                    Relationship Description
                  </th>
                </tr>
              </thead>
              <tbody>
                {stakeHolderList.map((stakeholder) => {
                  const {
                    id,
                    accountRelationship,
                    stakeholderRelationship,
                    stakeholderType,
                    party
                  } = stakeholder
                  const type =
                    accountRelationship ||
                    stakeholderType ||
                    stakeholderRelationship
                  const partyName = party?.partyName

                  if (!type || !partyName) {
                    return null
                  }

                  return (
                    <tr key={id}>
                      <td
                        css={{
                          padding: '2px 3px'
                        }}
                      >
                        {partyName}
                      </td>
                      <td css={{ padding: '2px 3px 2px 10px' }}>
                        <div style={{ display: 'flex', columnGap: 5 }}>
                          <div
                            title={type.name}
                            style={{
                              maxWidth: '250px',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}
                          >
                            {type.name}
                          </div>
                          <div>({type.code})</div>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <hr css={classes.partitionLine} />
        </>
      )}

      {showCreate && (
        <>
          <div css={classes.label}>Transfers</div>
          <div style={{ display: 'flex', gap: '50px' }}>
            <div>
              <TransfersLink text="Create a new Wire" type="wire" />
              {showEFT && <TransfersLink text="Create a new EFT" type="eft" />}
            </div>
            <div>
              {showJNL && (
                <TransfersLink text="Create a new Journal" type="journal" />
              )}
              {showChecks && (
                <TransfersLink text="Create a new Check" type="check" />
              )}
            </div>
          </div>
          <hr css={classes.partitionLine} />
        </>
      )}

      {!!accountNumber && (
        <>
          <div
            css={{
              display: 'flex',
              cursor: 'pointer',
              gridColumnGap: 5,
              alignItems: 'center'
            }}
            title="View Related Parties"
            onClick={onRelatedPartiesClicked}
          >
            <Icon
              type="InterestedParties"
              height={12}
              width={12}
              color={theme.colors.primaryDarkBlue}
            />
            <a css={[classes.linkText, { textDecoration: 'underline' }]}>
              Related Parties
            </a>
          </div>
        </>
      )}

      {CustodianCode === 'NFS' && (
        <div
          css={{
            display: 'flex',
            cursor: 'pointer',
            gridColumnGap: 5,
            alignItems: 'center'
          }}
          title="Click to launch Wealthscape Account Summary"
          onClick={onWealthscapeClicked}
        >
          <Icon
            type="SimulateArrow"
            height={12}
            width={12}
            color={theme.colors.primaryDarkBlue}
          />
          <a css={[classes.linkText, { textDecoration: 'underline' }]}>
            Wealthscape Account Summary
          </a>
        </div>
      )}

      {marginAgreement === 'Yes' && !multipleMargin && (
        <div css={{ marginTop: 6 }}>
          <div css={classes.note}>
            <sup>M</sup> This account has a margin agreement
          </div>
        </div>
      )}

      {isMultiMargin(searchAccount) && (
        <div css={{ marginTop: 6 }}>
          <div css={classes.note}>
            <sup style={{ color: '#8B0000' }}>M</sup> This account is part of a
            multi margin relationship
          </div>
        </div>
      )}
      {CustodianCode !== 'NFS' && (
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            fontSize: 10
          }}
        >
          Non-NFS share quantities are as of previous day and values are
          adjusted for current market price.
        </div>
      )}
    </div>
  )
})
