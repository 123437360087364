import { datahubApi } from 'store/api/datahub'
import { ISecureMessageMailbox, ISecureMessageRequest } from '../types'

interface ICreateClientMessageRequest {
  attachments: File[]
  request: ISecureMessageRequest
  userObjectId: string
}

const datahubWithSecureMessagesTags = datahubApi.enhanceEndpoints({
  addTagTypes: ['secureMessagesMailbox']
})

const secureMessagesApi = datahubWithSecureMessagesTags.injectEndpoints({
  endpoints: (builder) => ({
    createClientMessage: builder.mutation<void, ICreateClientMessageRequest>({
      query: ({ attachments, request, userObjectId }) => {
        const formData = new FormData()
        formData.append('message', JSON.stringify(request))

        if (attachments) {
          for (let i = 0; i < attachments.length; i++) {
            formData.append('attachments', attachments[i])
          }
        }

        return {
          url: `SecureMessages/Clients/${userObjectId}/Create`,
          method: 'POST',
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          data: formData
        }
      }
    }),
    getClientMailbox: builder.query<ISecureMessageMailbox | undefined, string>({
      query: (oid) => ({
        url: `SecureMessages/Clients/${oid}/Mailbox`
      }),
      providesTags: ['secureMessagesMailbox']
    })
  })
})

export const { useCreateClientMessageMutation, useGetClientMailboxQuery } =
  secureMessagesApi
