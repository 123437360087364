import { createColumnHelper } from '@tanstack/react-table'
import { HeaderContainer } from 'modules/Advisory/modules/Rdot360/components/shared/DetailTables/HeaderContainer'
import { AttachmentCell } from '../../features/Table/AttachmentCell'
import { CheckboxCell } from '../../features/Table/CheckboxCell'
import { CheckboxHeader } from '../../features/Table/CheckboxHeader'
import { DateCell } from '../../features/Table/DateCell'
import { SubjectCell } from '../../features/Table/SubjectCell'
import { IGraphMessage } from '../../shared/types'

const columnHelper = createColumnHelper<IGraphMessage>()

export const getInboxColumns = (
  onSubjectClick: (message: IGraphMessage) => void
) => [
  columnHelper.accessor((row) => row.from, {
    id: 'From',
    cell: ({ row, getValue }) => {
      const recipient = getValue()

      return (
        <CheckboxCell
          checked={row.getIsSelected()}
          onChange={row.getToggleSelectedHandler()}
          value={recipient?.emailAddress.name}
        />
      )
    },
    header: ({ table, column }) => {
      const isAllSelected = table.getIsAllRowsSelected()
      const isSomeSelected = table.getIsSomeRowsSelected()

      return (
        <CheckboxHeader
          checked={isAllSelected}
          indeterminate={isSomeSelected}
          isSorted={column.getIsSorted()}
          onChange={() => table.toggleAllRowsSelected(!isAllSelected)}
          title="From"
        />
      )
    },
    size: 200
  }),
  columnHelper.accessor((row) => row.subject, {
    id: 'Subject',
    header: (props) => (
      <HeaderContainer title="Subject" isSorted={props.column.getIsSorted()} />
    ),
    cell: (props) => (
      <SubjectCell
        isDraft={props.row.original.isDraft}
        onClick={() => onSubjectClick(props.row.original)}
        value={props.getValue()}
      />
    ),
    size: 300
  }),
  columnHelper.accessor((row) => row.hasAttachments, {
    id: 'Attachment',
    header: (props) => (
      <HeaderContainer
        title="Attachment"
        isSorted={props.column.getIsSorted()}
      />
    ),
    cell: (props) => <AttachmentCell value={props.getValue()} />,
    size: 100
  }),
  columnHelper.accessor((row) => row.receivedDateTime, {
    id: 'Received',
    header: (props) => (
      <HeaderContainer title="Received" isSorted={props.column.getIsSorted()} />
    ),
    cell: (props) => <DateCell value={props.getValue()} />,
    size: 100
  })
]
