import {
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  useReactTable,
  VisibilityState
} from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import { useDebounce } from 'shared/hooks/useDebounce'
import { IBalanceDetailResponseValueItem } from '../../../store/balancesApi/IBalanceDetailResponse'
import { useRdot360BalancesContext } from '../../../store/rdot360Context/useRdot360BalancesContext'
import { getColumnDefinitions } from './ColumnDefinitions'
import { useMarginLoansUiState } from './store'
// import { globalFilterFn } from './globalFilterFn'

const columns = getColumnDefinitions()

export const useReactTableProps = () => {
  const {
    searchText,
    setSearchText,
    expanded,
    setExpanded,
    sorting,
    setSorting
  } = useMarginLoansUiState()

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})
  const debouncedSearchText = useDebounce(searchText, 100)
  const { balancesResponse, isFetching, isUninitialized, invalidateCache } =
    useRdot360BalancesContext()

  const tableData: IBalanceDetailResponseValueItem[] = useMemo(() => {
    const { marginbalances = [] } = balancesResponse || {}
    return marginbalances
  }, [balancesResponse])

  const getSubRows = (row: IBalanceDetailResponseValueItem) => {
    return row.multimargindetails || []
  }

  const tableOptions = useMemo(
    () => ({
      data: tableData,
      columns,
      state: {
        sorting,
        expanded,
        globalFilter: debouncedSearchText,
        columnVisibility
      },
      autoResetExpanded: false,
      groupedColumnMode: undefined,
      getExpandedRowModel: getExpandedRowModel(),
      getGroupedRowModel: getGroupedRowModel(),
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getSubRows,
      onExpandedChange: setExpanded,
      onGlobalFilterChange: setSearchText,
      onSortingChange: setSorting,
      onColumnVisibilityChange: setColumnVisibility,
      globalFilterFn: () => false // To be implemented
    }),
    [
      tableData,
      sorting,
      expanded,
      debouncedSearchText,
      columnVisibility,
      setExpanded,
      setSearchText,
      setSorting
    ]
  )

  const table = useReactTable(tableOptions)
  const { rows } = table.getRowModel()
  const { rows: preExpandedRows } = table.getPreExpandedRowModel()
  const headers = table.getFlatHeaders()
  const { getToggleAllRowsExpandedHandler } = table
  const isAllRowsExpanded = table.getIsAllRowsExpanded()
  const showNoData = useMemo(
    () => !isUninitialized && !isFetching && !tableData.length,
    [isFetching, isUninitialized, tableData.length]
  )

  return {
    rows,
    preExpandedRows,
    headers,
    getToggleAllRowsExpandedHandler,
    isFetching,
    isAllRowsExpanded,
    showNoData,
    searchText,
    setSearchText,
    invalidateCache
  }
}
