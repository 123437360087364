import { IconButton } from '@fluentui/react'
import { useSecureMessagesState } from '../../shared/hooks'
import { styles } from '../../shared/styles'

export const ReplyButton: React.FC = () => {
  const { setShowReply } = useSecureMessagesState()

  return (
    <div style={styles.buttonContainer}>
      <IconButton
        iconProps={{
          iconName: 'Reply',
          style: styles.icon
        }}
        onClick={() => setShowReply(true)}
        title="Reply to message"
      />
    </div>
  )
}
