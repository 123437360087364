import { createColumnHelper } from '@tanstack/react-table'
import { USDCell } from 'modules/Advisory/modules/Rdot360/features/WidgetTable/WidgetTable'
import { IBalanceDetailResponseValueItem } from 'modules/Advisory/modules/Rdot360/store/balancesApi/IBalanceDetailResponse'
import { parseDateISOStringInLocalTimezone } from 'shared'
import { theme } from 'shared/theme'
import { DateTime } from '../../../../features/Loans/formatting'
import {
  textAlignLeft,
  textAlignRight
} from '../../../../features/Loans/styles'
import {
  AccountCellExtended,
  USDCellTotalAmount
} from '../../../../features/Loans/TableCellsGeneric'

const columnHelper = createColumnHelper<IBalanceDetailResponseValueItem>()

export const getColumnDefinition = () => [
  columnHelper.accessor(({ key }) => key, {
    id: 'accountKey',
    cell: AccountCellExtended,
    header: () => <div css={textAlignLeft}>Account</div>,
    footer: () => <>Total</>,
    size: 60
  }),
  columnHelper.accessor(({ totalaccountvalue }) => totalaccountvalue, {
    id: 'loanBalance',
    cell: ({ getValue }) => <USDCell value={getValue<number>()} />,
    header: () => (
      <div css={textAlignRight}>
        Loan
        <br />
        Balance
      </div>
    ),
    footer: (props) => (
      <div css={textAlignRight}>
        <USDCellTotalAmount {...props} fractionDigits={0} inColor />
      </div>
    ),
    size: 60
  }),
  columnHelper.accessor(({ nextpayment }) => nextpayment, {
    id: 'nextInterestPayment',
    cell: ({ getValue, row }) => {
      const dateString = row.original.nextpaymentdate
      const ISODate = dateString
        ? parseDateISOStringInLocalTimezone(dateString)
        : undefined

      return (
        <>
          <USDCell value={getValue<number>()} />
          <div css={[textAlignRight, { color: theme.colors.tertiaryGray1 }]}>
            <DateTime value={ISODate} />
          </div>
        </>
      )
    },
    header: () => (
      <div css={textAlignRight}>
        Next Interest
        <br />
        Payment
      </div>
    ),
    size: 60
  }),
  columnHelper.accessor(({ availablecredit }) => availablecredit, {
    id: 'availableToBorrow',
    cell: ({ getValue }) => <USDCell value={getValue<number>()} />,
    header: () => (
      <div css={textAlignRight}>
        Available
        <br />
        to Borrow
      </div>
    ),
    footer: (props) => (
      <div css={textAlignRight}>
        <USDCellTotalAmount {...props} fractionDigits={0} />
      </div>
    ),
    size: 60
  })
]
