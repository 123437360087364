import { CellContext } from '@tanstack/react-table'
import { IActivity } from 'modules/Advisory/modules/Rdot360/store/types'
import { PrintDescriptionCell } from './PrintDescriptionCell'

// Regular expression to match whole word that starts with RJM, RJL, RT8, or RS9
// followed by an optional hyphen then 6 more digits
const regex = /\b(RJM|RJL|RT8|RS9)-?\d{6}\b/g

const maskAccountNumbers = (description: string) => {
  // Replace matched words with X followed by the last 4 digits
  return description.replace(regex, (match) => {
    return 'X' + match.slice(-4)
  })
}

export const PrintDescriptionMasked: React.FC<
  CellContext<IActivity, unknown>
> = (props) => {
  const {
    original: { trndesc: description }
  } = props.row
  const descriptionMasked = maskAccountNumbers(description || '')
  return <PrintDescriptionCell description={descriptionMasked} />
}
